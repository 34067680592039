import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../MobileComponents/Header";
import "react-phone-number-input/style.css";
import Arrow from "../../../assets/icons/left-Arrow.png";
// import SupportTicketTopic from "../../../MobileComponents/SupportTicketTopic";
import moment from "moment";

function MobileSupportTicket({list,setSubject,setComment,SubmitFun,isCreateTicketClicked, setIsCreateTicketClicked}) {
  const navigate = useNavigate();

  // const [isCreateTicketClicked, setIsCreateTicketClicked] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleCreateTicketClick = () => {
    setIsCreateTicketClicked(false);
    setIsFormSubmitted(true);
  };

  const submitBtn = () => {
    setIsCreateTicketClicked(true);
    setIsFormSubmitted(false);
  };

  return (
    <div className="login_container supportTicket_mt">
      <Header
      position={"fixed"}
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        leftButtonClick={() => navigate(-1)}
        title={"Support Tickets"}
      />
      <main className="support-wrapper">
        <div className="container login_container">
          <div className="row">
            <div className="col-lg-12">
              {isCreateTicketClicked ? (
                <div className="login-left pe-lg-5 me-xl-5">
                  <div className="login-item" onClick={handleCreateTicketClick}>
                    <button className="createTicketBtn">Create Ticket</button>
                  </div>

                  <div>
                    <p> Support Tickets </p>
                  </div>

                  {
                    list?.map((item,index)=>(
                      <div className="ticketCard_container"
                      onClick={() => {
                        navigate("/support-ticket-chat", {
                          state: item,
                          // status: item?.status,
                        });
                      }}
                      >
                        <div className="ticketCardCol1">
                          <h4 className="ticketDate_Text">{moment(item?.updated_at).format("DD/MM/YYYY")} </h4>
                          <h4 className="featureTicket_Text">{item?.raw_subject}</h4>
                          {/* <div className="newBtn">
                            <p>new</p>
                          </div> */}
                           <div
                            style={{
                              width: 80,
                              height: 30,
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#4DFA6D",
                              marginTop: 10,
                              backgroundColor:
                                item.status === "new"
                                  ? "#4DFA6D"
                                  : "#CDCBCC",
                              textAlign: "center",
                            }}>
                              <p style={{ marginTop: 10 }}>{item?.status}</p>
                            </div>
                        </div>
                        <div className="ticketCardCol2">
                          <div className="viewBtn">
                            <p>View</p>
                          </div>
                        </div>
                      </div>
                    ))
                  }

                </div>
              ) : (
                <>
                  <div>
                    <div className="search-item topic_container">
                      <p className="topicHeading">Topic</p>

                      <select name="state"
                      onChange={(e) => setSubject(e.target.value)}

                      >
                        <option value="" defaultChecked>
                          Please Pick
                        </option>
                        <option value="Feature Request">Feature Request</option>
                        <option value="Technical Question">
                          Technical Question
                        </option>
                        <option value="System Bug">System Bug</option>
                        <option value="User Interface Bug">
                          User Interface Bug
                        </option>
                        <option value="Database / Background Check Questions">
                          Database / Background Check Questions
                        </option>
                        <option value="Feedback & Testimonials">
                          Feedback & Testimonials
                        </option>
                        <option value="General Support">General Support</option>
                        <option value="Billing Question">
                          Billing Question
                        </option>
                        <option value="Change or Update My Subscription">
                          Change or Update My Subscription
                        </option>
                        <option value="Partnership Request">
                          Partnership Request
                        </option>
                        <option value="Contact CEO">Contact CEO</option>
                        <option value="Privacy Question">
                          Privacy Question
                        </option>
                        <option value="Opt-Out">Opt-Out</option>
                        <option value="Account Reinstatement">
                          Account Reinstatement
                        </option>
                        <option value="Press">Press</option>
                      </select>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <textarea
                        name="comment"
                        rows="8"
                        placeholder="Message"
                        className="textareaMsg"
                        onChange={(e) => setComment(e.target.value)}

                      ></textarea>
                    </div>
                    <div
                      className="search-item search-btn ticketTopicSubmit"
                      onClick={()=>{
                        SubmitFun();
                        // submitBtn();
                      }}
                    >
                      <div>
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default MobileSupportTicket;
