/** @format */

import logo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";
import Header from "../../MobileComponents/Header";
import Invisible from "../../assets/icons/invisible.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import RecentSearches from "../../MobileComponents/RecentSearches";
import Footer from "../../MobileComponents/Footer";
import { useState, useEffect } from "react";
import PhoneSearch from "./PhoneSearch";
import EmailSearch from "./EmailSearch";
import LogoName from "../../assets/icons/logoName.png";
import BurgerMenu from "../../assets/icons/burderMenu.png";
import swal from "sweetalert";
import { logOut } from "../../redux/action";
import { useDispatch } from "react-redux";
import { axiosPost } from "../../utils/axiosRequest";
import { searchDetails, metaBody } from "../../redux/action";

function MobileDashboard() {
  const [status, setStatue] = useState("user");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = localStorage.id;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [age, setAge] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");

  const childToParent = (value) => {
    setStatue(value);
  };

  useEffect(() => {
    // alert(status)
    window.scrollTo(0, 0);
  }, [status]);

  const clearWrittenText = (fieldName) => {
    switch (fieldName) {
      case "firstName":
        setFirstName("");
        break;
      case "middleName":
        setMiddleName("");
        break;
      case "lastName":
        setLastName("");
        break;
      case "age":
        setAge("");
        break;
      case "city":
        setCity("");
        break;
      default:
        break;
    }
  };

  const filterBtnClick = () => {
    setShowFilters(!showFilters);
  };

  const handleLogout = () => {
    swal({
      title: "Do you want to log out?",
      content: {
        element: "div",
        attributes: {
          className: "custom-swal-content",
          // Inject your custom HTML here, including a cross button
          innerHTML: `
            <div>
              <p>Are you sure you want to log out?</p>
              <button id="logout-yes">Yes</button>
              <button id="logout-no">No</button>
            </div>
          `,
        },
      },
      buttons: false,
      closeOnClickOutside: false,
    }).then((result) => {
      if (result) {
        if (result === "logout-yes") {
          // Perform logout action here
        }
      }
    });
  };

  function logingout() {
    dispatch(logOut());
    navigate("/");
  }

  const searchResult = () => {
    navigate("/MobileResultPage");
  };

  function check(event, obj) {
    event.preventDefault();
    if (!data) {
      swal({
        title: "Friend Verifier",
        text: "In order to perform a search you must be logged in. Please login or create an account!",
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
      }).then(() => {
        navigate("/login-signup");
      });
      return;
    }
    if (
      (obj === "fname" && lastName === "") ||
      (obj === "fname" && lastName === null)
    ) {
      swal("First and last name fields are required.");
      setIsLoading(false);
    } else if (
      (obj === "fname" && firstName === "") ||
      (obj === "fname" && firstName === null)
    ) {
      swal("First and last name fields are required.");
      setIsLoading(false);
    } else if (obj === "phone" && phone === "") {
      swal("Please enter a phone number.");
      setIsLoading(false);
    } else if (obj === "email" && email === "") {
      swal(
        "Please enter a correctly formatted email address (name@domain.com)."
      );
      setIsLoading(false);
    } else if (obj === "add" && state === "") {
      swal(
        "The street name, city and state are required fields. Please try again"
      );
      setIsLoading(false);
    } else if (obj === "add" && street === "") {
      swal(
        "The street name, city and state are required fields. Please try again"
      );
      setIsLoading(false);
    } else if (obj === "add" && city === "") {
      swal(
        "The street name, city and state are required fields. Please try again"
      );
      setIsLoading(false);
    } else {
      setIsLoading(true);
      var fields = [];
      if (firstName) {
        fields.push({ firstName: firstName });
        // localStorage.setItem('firstName', firstName)
      }
      if (lastName) {
        fields.push({ lastName: lastName });
        // localStorage.setItem('lastName', lastName)
      }
      if (city) {
        fields.push({ city: city });
        // localStorage.setItem('city', city)
      }
      if (state) {
        fields.push({ state: state });
        // localStorage.setItem('state', state)
      }
      if (phone) {
        fields.push({ phone: phone });
        // localStorage.setItem('phone', phone)
      }
      if (email) {
        fields.push({ email: email });
        // localStorage.setItem('email', email)
      }
      if (middleName) {
        fields.push({ midName: middleName });
        // localStorage.setItem('email', email)
      }
      if (age) {
        fields.push({ age: age });
      }
      if (street) {
        fields.push({ street: street });
        // localStorage.setItem('street', street)
      }

      var obj = {
        fields: fields,
      };

      localStorage.setItem("obj", JSON.stringify(obj));
      const url = `/search/openSearch?pageNo=1&pageSize=10`;

      axiosPost(url, obj, "", (callBack) => {
        console.log("Searching");
        const name = firstName + " " + lastName;
        console.log(name);

        if (callBack?.status === 200) {
          dispatch(searchDetails({ payload: callBack.data.response }));
          dispatch(metaBody({ payload: obj }));
          setIsLoading(false);

          localStorage.setItem("metaBody", JSON.stringify(obj));
          //   return;

          // navigate("/result-page");
          navigate("/result-page", {
            state: {
              //   data: callBack.data,
              //   inputState: state,
              //   inputName: name,
              metaBody: obj,
              //   research: true,
              //   firstName,
              //   lastName,
              //   state,
              //   email,
              //   phone,
              //   street,
              //   city,
            },
          });
        } else {
          setIsLoading(false);
          alert(callBack.message);
        }
      });
    }
  }

  return (
    <div className="login_container dashboard_container dashboard_mt">
      <Header
        position={"fixed"}
        height={"75px"}
        padding={""}
        leftMenu="menu"
        leftSrc={BurgerMenu}
        titleSrc={LogoName}
        titleClassName="small-logo"
      />
      {status && status === "user" ? (
        <main className="home-wrapper">
          <div className="container home_container">
            <div className="row">
              <div className="col-lg-12">
                <div className="login-left pe-lg-5 me-xl-5">
                  <form onSubmit={(event) => check(event, "fname")}>
                    <div className="login-content">
                      <div className="HomeInput-item">
                        <div className="home_input_box pointer">
                          <input
                            type="text"
                            placeholder="First Name"
                            name="fName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            id="focus"
                            required
                          />
                          <div
                            className=" home_input_icon"
                            onClick={() => clearWrittenText("firstName")}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="home_icon_style"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="HomeInput-item"
                        style={{ display: showFilters ? "block" : "none" }}
                      >
                        <div className="home_input_box pointer">
                          <input
                            type="text"
                            placeholder="Middle Name"
                            name="mName"
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                            id="focus"
                          />
                          <div
                            className=" home_input_icon"
                            onClick={() => clearWrittenText("middleName")}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="home_icon_style"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="HomeInput-item">
                        <div className="home_input_box pointer">
                          <input
                            type="text"
                            placeholder="Last Name"
                            name="lName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            id="focus"
                            required
                          />
                          <div
                            className=" home_input_icon"
                            onClick={() => clearWrittenText("lastName")}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="home_icon_style"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="HomeInput-item"
                        style={{ display: showFilters ? "block" : "none" }}
                      >
                        <div className="home_input_box pointer">
                          <input
                            type="number"
                            placeholder="Age"
                            name="age"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            id="focus"
                          />
                          <div
                            className=" home_input_icon"
                            onClick={() => clearWrittenText("age")}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="home_icon_style"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="HomeInput-item">
                        <div className="home_input_box">
                          <input
                            type="text"
                            placeholder="City"
                            name="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            id="focus"
                          />
                          <div
                            className=" home_input_icon"
                            onClick={() => clearWrittenText("city")}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="home_icon_style"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="home_input_box pointer ">
                        <div className="home_state_dropDown">
                          <select
                            //  className={`Home_selectBox ${state ? 'selected-option' : ''}`}
                            className="Home_selectBox select-box"
                            name="state"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            style={{
                              backgroundColor: "white",
                              color: state ? "black" : "#757575",
                            }}
                          >
                            <option
                              value=""
                              defaultChecked
                              className="dropdownIcon_style"
                            >
                              All States
                            </option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                      </div>

                      <div className="login-item home_form_search">
                        <div
                          className="home_search"
                          // onClick={searchResult}
                        >
                          <button className="home_search_btn" type="submit">
                            Search
                          </button>
                        </div>
                        <div
                          className=" home_filter_icon"
                          onClick={() => filterBtnClick()}
                        >
                          <FontAwesomeIcon
                            icon={faSliders}
                            className="home_filterIcon_style"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : status === "phone" ? (
        <PhoneSearch check={check} setPhone={setPhone} phone={phone} />
      ) : status === "mail" ? (
        <EmailSearch check={check} email={email} setEmail={setEmail} />
      ) : status === "logout" ? (
        <>
          <div id="alerts">
            <div className="alert">
              <h4>Do want to log out?</h4>
              <div className="buttons">
                <button
                  className="login-btn loginactive Link"
                  onClick={() => {
                    setStatue("user");
                    setStatue("user");
                  }}
                >
                  <h5 style={{ margin: "0px" }}>No</h5>
                </button>
                <button
                  className="login-btn loginactive Link"
                  onClick={logingout}
                >
                  <h5 style={{ margin: "0px" }}>Yes</h5>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <Footer activeD={childToParent} status={status} setStatue={setStatue} />
    </div>
  );
}

export default MobileDashboard;
