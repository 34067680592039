/** @format */

import axios from "axios";
import { MainUrl } from "./mainUrl";

const axiosAPI = axios.create({
  baseURL: MainUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // Authorization: `Bearer ${localStorage.tokken}`,
  },
});

export async function axiosPost(url, obj, token, callBack) {
  try {
    const header = {
      headers: {
        Authorization: `Bearer ${
          token
            ? token
            : localStorage.getItem("@loginDetails")
            ? JSON.parse(localStorage.getItem("@loginDetails")).authToken
            : ""
        }`,
      },
    };
    console.log("🚀 ~ file: axiosRequest.js:26 ~ axiosPost ~ header:", header);
    const response = await axiosAPI.post(url, obj, header);
    const data = response.data;
    callBack(data);
  } catch (error) {
    if (error.response) {
      callBack(error?.response?.data);
    } else {
      callBack(error);
    }
  }
}

export async function axiosGet(url, callBack) {
  const header = {
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem("@loginDetails")
          ? JSON.parse(localStorage.getItem("@loginDetails")).authToken
          : ""
      }`,
    },
  };
  console.log(
    "🚀 ~ file: axiosRequest.js:17 ~ axiosPost ~ localStorage:",
    header
  );
  try {
    const response = await axiosAPI.get(url, header);

    callBack(response);
  } catch (error) {
    if (error.response) {
      console.log(
        "🚀 ~ file: axiosRequest.js ~ line 34 ~ axiosGet ~ err.response",
        error.response.data
      );
      callBack(error?.response?.data);
    } else {
      callBack(error);
    }
  }
}

export async function axiosGetCall(url, callBack) {
  const header = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("@loginDetails")).authToken
      }`,
    },
  };
  console.log("🚀 ~ file: axiosRequest.js:83 ~ axiosGetCall ~ header:", header);
  try {
    const response = await axiosAPI.get(url, header);

    callBack(response);
  } catch (error) {
    if (error.response) {
      console.log(
        "🚀 ~ file: axiosRequest.js ~ line 55 ~ axiosGetCall ~ err.response",
        error.response.data
      );
      callBack(error?.response?.data);
    } else {
      callBack(error);
    }
  }
}

export async function axiosPut(url, obj, callBack) {
  try {
    const response = await axiosAPI.put(url, obj);
    const data = response.data;
    callBack(data);
  } catch (error) {
    if (error.response) {
      console.log(
        "🚀 ~ file: axiosReuest.js ~ line 76 ~ axiosPUTCall ~ err.response",
        error.response.data
      );
      callBack(error?.response?.data);
    } else {
      callBack(error);
    }
  }
}

export async function axiosDelete(url, callBack) {
  try {
    const header = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("@loginDetails")).authToken
        }`,
      },
    };
    const response = await axiosAPI.delete(url, header);
    const data = response.data;
    console.log("🚀 ~ axiosDelete ~ data:", data);
    callBack(data);
  } catch (error) {
    if (error.response) {
      console.log(
        "🚀 ~ file: axiosReuest.js ~ line 66 ~ axiosPUTCall ~ err.response",
        error.response.data
      );
      callBack(error?.response?.data);
    } else {
      callBack(error);
    }
  }
}
