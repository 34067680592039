import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { LoggedFooter } from "../../Components/Footer";
import LoggedHeader from "../../Components/Header";
import Loading from "../../Components/Loading";
import { axiosGet } from "../../utils/axiosRequest";

function Success() {

    const[isLoading, setIsLoading] = useState(false);

    const params = new URLSearchParams( window.location.search);
    const orderId = params.get("orderId");
    const paymentId = params.get("paymentId");
    const token = params.get("token");
    const PayerID = params.get("PayerID");
    
    useEffect(() => {
    const url = `/orders/successPayment?orderId=${orderId}&paymentId=${paymentId}&token=${token}&PayerID=${PayerID}`
        axiosGet( url, (callBack) => {
            setIsLoading(true)
            if(callBack?.status === 200){
                swal("Transaction Successful");
                setIsLoading(false)
                setTimeout(change, 5000);
                function change(){
                    window.location.href= 'https://friends-verifier.netlify.app/dashboard'
                }
            }else{
                swal("Something Went Wrong");
                setIsLoading(false);
                window.location.href= 'https://friends-verifier.netlify.app/orders/failedPayment'
                console.log(callBack);
            }
        })
    });


    return(

        <div>

            { isLoading ? <Loading/> : false }
            <LoggedHeader/>

            <main className="login-wrapper">
                <div className="container">
                    <div className="login-left moible-wrap">
                        <br />
                        <br />
                        <br />
                        <p>Congrates!!🎉🎉</p>
                        <br />
                        <h2>Payment Successful 😉</h2>
                        <br />
                        <br />
                        <b><a href="https://friends-verifier.netlify.app/dashboard">Dashboard</a></b>
                    </div>
                </div>
            </main>

            <LoggedFooter/>
        </div>
    )
    
}

export default Success;
