/** @format */

import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronRight,
  faEnvelope,
  faLock,
  faPhone,
  faShareNodes,
  faToggleOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Header from "../../../MobileComponents/Header";
import Arrow from "../../../assets/icons/left-Arrow.png";

const Account = () => {
  const navigate = useNavigate();

  function emailAccountFuc() {
    navigate("/updateEmail");
  }
  function passwordAccountFuc() {
    navigate("/updatePassword");
  }
  function mobileAccountFuc() {
    navigate("/updatePhone");
  }

  return (
    <div className="login_container">
      <Header
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        leftButtonClick={() => navigate(-1)}
        title={"Account"}
      />

      <div className="account_container" onClick={emailAccountFuc}>
        <div className="menuList_firstCol">
          <FontAwesomeIcon icon={faEnvelope} className="account_icon" />
        </div>
        <div className="menuList_secondCol accountList_ml">
          <h4> Email Address </h4>
          <p>Update Your email address</p>
        </div>
        <div className="menuList_thirdCol">
          <FontAwesomeIcon
            icon={faChevronRight}
            className="account_arrowIcon"
          />
        </div>
      </div>

      <div className="account_container" onClick={passwordAccountFuc}>
        <div className="menuList_firstCol">
          <FontAwesomeIcon icon={faLock} className="account_icon" />
        </div>
        <div
          className="menuList_secondCol accountList_ml"
          style={{ marginLeft: "10px" }}
        >
          <h4> Password </h4>
          <p>Update Your Password</p>
        </div>
        <div className="menuList_thirdCol">
          <FontAwesomeIcon
            icon={faChevronRight}
            className="account_arrowIcon"
          />
        </div>
      </div>

      <div className="account_container" onClick={mobileAccountFuc}>
        <div className="menuList_firstCol">
          <FontAwesomeIcon icon={faPhone} className="account_icon" />
        </div>
        <div
          className="menuList_secondCol accountList_ml"
          style={{ marginLeft: "10px" }}
        >
          <h4> Mobile Number </h4>
          <p>Update Your mobile number</p>
        </div>
        <div className="menuList_thirdCol">
          <FontAwesomeIcon
            icon={faChevronRight}
            className="account_arrowIcon"
          />
        </div>
      </div>
    </div>
  );
};

export default Account;
