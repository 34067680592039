/** @format */

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import DashboardWrapper from "../../Components/DashboardWrapper";
import { LoggedFooter } from "../../Components/Footer";
import LoggedHeader from "../../Components/Header";
import SearchArea from "../../Components/SearchArea";
import search from "../../images/search.png";
import { loginDetails } from "../../redux/action";
import { SaveStorageItems } from "../../utils/AsyncStorage";
import { axiosGet, axiosGetCall } from "../../utils/axiosRequest";
import { useNavigate } from "react-router-dom";
import MobileDashboard from "../../MobilePages/home/MobileDashboard";

function Dashboard() {
  const [history, setHistory] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  {
    /* mobile screen */
  }
  const [mobDashboard, setMobDashboard] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobDashboard(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobDashboard(window.innerWidth <= 575);
  };

  // useEffect(() => {
  //   setMobDashboard()
  //   }, [])
  //   useEffect(() => {
  //     window.addEventListener("resize", handleResize);
  //   }, [window.innerWidth]);

  //   const handleResize = () => {
  //     console.log("Window resized");
  //     setMobDashboard(window.innerWidth <= 575);
  //   };

  useEffect(() => {
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };

    const history = "/search/getHistory";
    console.log(
      "🚀 ~ file: Dashboard.jsx:61 ~ useEffect ~ localStorage:",
      JSON.parse(localStorage["@loginDetails"]).authToken
    );
    // if (localStorage.tokken != null && localStorage.tokken != "null") {
    axiosGetCall(history, (callBack) => {
      console.log(
        "🚀 ~ file: Dashboard.jsx:29 ~ axiosGetCall ~ callBack:",
        JSON.stringify(callBack?.data?.data[0])
      );
      if (callBack?.status === 200) {
        const slicedArray = callBack?.data?.data?.slice(0, 10);
        setHistory(slicedArray);
        // setHistory(callBack?.data?.data);
      } else {
        // swal(callBack.message);
      }
    });
    const user = `/user/${localStorage.id}`;

    // axiosGetCall(user, header, (callBack) => {
    //   if (callBack?.status === 200) {
    //     //console.log("UserDetails");
    //     //console.log(callBack);
    //     SaveStorageItems(
    //       "loginDetails",
    //       JSON.stringify(callBack.data.data),
    //       0,
    //       () => {
    //         dispatch(loginDetails({ payload: callBack?.data.data }));
    //       }
    //     );
    //   } else {
    //     alert(callBack.message);
    //   }
    // });
    // }
    localStorage.setItem("metaBody", null);
    localStorage.setItem("potentialSearch", null);
    localStorage.setItem("SearchingData", null);
  }, []);

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  };

  const options = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const getStateName = (state) => {
    state = state.toUpperCase();
    const data = options.find((items) => {
      return items.abbreviation == state;
    });
    return data?.name;
  };

  const RecentSearch = () => (
    <>
      <div className="rsrarch-heading">
        <div className="recentSearchHeading">Recent Searches</div>
      </div>
      <div style={{ textAlign: "center" }} className="mobileResent_searchBox">
        {history.length > 0 ? (
          history.map((item, index) => (
            <div
              className="recent-serchbox"
              style={{ textAlign: "left" }}
              key={item._id}
              onClick={() => {
                navigate("/record-page", { state: item?.search });
              }}
            >
              <div className="recent-srcnt">
                <h5>{`${item?.search?.requestObj?.searchType}`}</h5>
                <div style={{ color: "#757a84" }}>
                  {(item?.search?.requestObj?.search?.firstName ||
                    item?.search?.requestObj?.search?.lastName) && (
                    <>
                      {item?.search?.requestObj?.search?.firstName}{" "}
                      {item?.search?.requestObj?.search?.midName}{" "}
                      {item?.search?.requestObj?.search?.lastName}
                    </>
                  )}
                  {item?.search?.requestObj?.search?.state && (
                    <div style={{ marginTop: 5 }}>
                      State{": "}
                      {getStateName(item?.search?.requestObj?.search?.state)}
                    </div>
                  )}
                  {item?.search?.requestObj?.search?.age && (
                    <div style={{ marginTop: 5 }}>
                      Age: {item?.search?.requestObj?.search?.age}
                    </div>
                  )}
                  {item?.search?.requestObj?.search?.email && (
                    <>{item?.search?.requestObj?.search?.email}</>
                  )}
                  {item?.search?.requestObj?.search?.phone && (
                    <>
                      {formatPhoneNumber(
                        item?.search?.requestObj?.search?.phone
                      )}
                    </>
                  )}
                </div>
              </div>
              <img src={search} alt="" />
            </div>
          ))
        ) : (
          <>
            {/* <h2> Subscribe With Platinum Plan And Enjoy Searches </h2> */}
            <h2>No recent searches available </h2>
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      {mobDashboard ? (
        <>
          <MobileDashboard />
          <div
            className="col-md-8"
            style={{ marginRight: 10, marginLeft: 10, marginBottom: 120 }}
          >
            <RecentSearch />
          </div>
        </>
      ) : (
        <div>
          <LoggedHeader />

          <div className="dashboard-wrapper">
            <div className="container">
              <SearchArea />

              <div className="row">
                <div className="col-md-3">
                  <DashboardWrapper />
                </div>
                <div className="col-md-9">
                  <RecentSearch />
                  {/* <div className="rsrarch-heading">
                    <h2>Recent Searches</h2>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {history.length > 0 ? (
                      history.map((item, index) => (
                        <div
                          className="recent-serchbox"
                          style={{ textAlign: "left" }}
                          key={item._id}
                          onClick={() => {
                            navigate("/record-page", { state: item?.search });
                            localStorage.setItem("metaBody", null);
                          }}
                        >
                          <div className="recent-srcnt">
                            <h4>{`${item?.search?.requestObj?.searchType}`}</h4>
                            <h4>
                              {(item?.search?.requestObj?.search?.firstName ||
                                item?.search?.requestObj?.search?.lastName) && (
                                <>
                                  {item?.search?.requestObj?.search?.firstName}{" "}
                                  {item?.search?.requestObj?.search?.midName}{" "}
                                  {item?.search?.requestObj?.search?.lastName}
                                </>
                              )}
                              {item?.search?.requestObj?.search?.state && (
                                <p style={{ marginTop: 5 }}>
                                  State{": "}
                                  {getStateName(
                                    item?.search?.requestObj?.search?.state
                                  )}
                                </p>
                              )}
                              {item?.search?.requestObj?.search?.age && (
                                <p style={{ marginTop: 5 }}>
                                  Age: {item?.search?.requestObj?.search?.age}
                                </p>
                              )}
                              {item?.search?.requestObj?.search?.email && (
                                <>{item?.search?.requestObj?.search?.email}</>
                              )}
                              {item?.search?.requestObj?.search?.phone && (
                                <>
                                  {formatPhoneNumber(
                                    item?.search?.requestObj?.search?.phone
                                  )}
                                </>
                              )}
                            </h4>
                          </div>
                          <img src={search} alt="" />
                        </div>
                      ))
                    ) : (
                      <>
                        <h2>No recent searches available </h2>
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <LoggedFooter />
        </div>
      )}
    </>
  );
}

export default Dashboard;
