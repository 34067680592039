/** @format */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoggedFooter } from "../Components/Footer";
import LoggedHeader from "../Components/Header";
import swal from "sweetalert";
import { axiosDelete } from "../utils/axiosRequest";
import { logOut } from "../redux/action";
import { useDispatch } from "react-redux";

function ContatUs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [view, setView] = useState(false);

  useEffect(() => {
    const Id = localStorage.getItem("@loginDetails");
    if (Id === null) {
      navigate("/login-signup");
    }
  });
  const data = JSON.parse(localStorage.loginDetails);
  const userData = {
    name: data?.fullName,
    email: data?.email,
    plan: data?.plan,
  };

  function Warning() {
    return (
      <div id="alerts">
        <div className="alert">
          <h4>Are you Sure?</h4>
          <p>
            Deleting your account you will losse all of your search history and
            settings
          </p>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView(false)}
            >
              <h5 style={{ margin: "0px" }}>Cancel</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={DeleteAccount}
            >
              <h5 style={{ margin: "0px" }}>Delete</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }

  const DeleteAccount = () => {
    axiosDelete(`/user/${data._id}`, (callBack) => {
      console.log(callBack);
      if (callBack?.status === 200) {
        swal(callBack.message);
        navigate("/");
        dispatch(logOut());
      } else {
        swal(callBack.message);
      }
    });
  };

  return (
    <div>
      {/* {isLoading ? <Loading/> : false} */}

      <LoggedHeader />
      {view ? <Warning /> : false}

      <div
        className="contact-area"
        id="contact"
        style={{
          padding: " 20px 0 45px",
          height: "100%",
          minHeight: "70vh",
          background: "rgb(244 245 247)",
        }}
      >
        <div className="container">
          <div className="user-dashboard">
            <div
              style={{
                margin: "10px",
                fontSize: "larger",
              }}
            >
              <label htmlFor="id">Profile</label>
              <div id="id">
                <h4>{data?.roles[0]}</h4>
              </div>
            </div>

            <h5
              style={{
                margin: "10px",
                marginTop: "30px",
                fontSize: "larger",
              }}
            >
              Profile Details
            </h5>
            <div
              className="user-details"
              style={{
                display: "flex",
                boxShadow: "0px 0px 5px .5px grey",
                borderRadius: "12px",
                padding: "10px",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
            >
              <div className="userModel">
                <label htmlFor="name"> Name </label>
                <div id="name">
                  <h3>{data?.fullName}</h3>
                </div>
              </div>
              <div className="userModel">
                <label htmlFor="phone"> Phone </label>
                <div id="phone">
                  <h4>{data?.phone}</h4>
                </div>
              </div>
              <div className="userModel">
                <label htmlFor="email"> Email </label>
                <div id="email">
                  <h4>{data?.email}</h4>
                </div>
              </div>
              {/* <div className="userModel">
                <label htmlFor="id"> User Id </label>
                <div id="id">
                  <h4>{data?._id}</h4>
                </div>
              </div> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: "5vh",
                width: "50vw",
              }}
            >
              <button
                onClick={() => navigate("/dashboard")}
                className="login-btn loginactive Link"
              >
                Search
              </button>
              <button
                onClick={() => navigate("/subscriptionPage")}
                className="login-btn loginactive Link"
              >
                Update Subscription
              </button>
              <button
                onClick={() =>
                  navigate("/loggedHome", { state: { state: true } })
                }
                className="login-btn loginactive Link"
              >
                Contact Support
              </button>
              <button
                onClick={() => setView(true)}
                className="login-btn loginactive Link"
              >
                Delete Account
              </button>
              <button
                onClick={() => navigate("/support-ticket")}
                // onClick={() =>
                //   axiosDelete(`/user/${data._id}`, (callBack) => {
                //     console.log(callBack);
                //     if (callBack?.status === 200) {
                //       swal(callBack.message);
                //       navigate("/");
                //       dispatch(logOut());
                //     } else {
                //       swal(callBack.message);
                //     }
                //   })
                // }
                className="login-btn loginactive Link"
              >
                Support Ticket
              </button>
            </div>
          </div>
        </div>
      </div>

      <LoggedFooter />
    </div>
  );
}

export default ContatUs;
