/** @format */

import { SigninFooter } from "../../../Components/Footer";
import { SigninHeader } from "../../../Components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { axiosGet, axiosPost } from "../../../utils/axiosRequest";
import swal from "sweetalert";
import Loading from "../../../Components/Loading";
import VerifyForgetOtpMobile from "../../../MobilePages/Auth/VerifyForgetOtp";

function VerifyForgetOtp() {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(60);
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileForgotpswd, setMobileForgotpswd] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    // const id = localStorage.getItem("@loginDetails");
    // // console.log("🚀 ~ file: DashboardWrapper.jsx:20 ~ useEffect ~ localStorage:", (localStorage?.loginDetails?.isSuperUser))
    // if (id === null) {
    //   navigate("/login-signup");
    // }

    var timer = setTimeout(update, 1000);

    function update() {
      for (var i = count; i > 0; i--) {
        // document.getElementById("time").style.color = "red";
        setCount(count - 1);
        setTimeout(1000);
      }
    }

    if (count === 0) {
      setCount("");
      document.getElementById("time").style.color = "blue";
    }
  });

  // useEffect(() => {
  //   if (window.innerWidth <= 575) {
  //     const bool = window.addEventListener("resize", handleResize);
  //     if (!bool) return setMobileForgotpswd(window.innerWidth <= 575);
  //   } else {
  //     window.addEventListener("resize", handleResize);
  //   }
  // }, [window.innerWidth]);

  // const handleResize = () => {
  //   setMobileForgotpswd(window.innerWidth <= 575);
  // };

  function resendOtp() {
    var obj = {
      device: "web",
      deviceInfo: {},
      mode: "email",
      purpose: "forgotPassword",
      email: location.state.email,
    };

    // const url = `/user/resendCode?email=${location.state.email}&type=email&purpose=forgotPassword`;
    const url = "/user/resend-otp";
    axiosPost(url, obj, "", (callBack) => {
      console.log(
        "🚀 ~ file: VerifyForgetOtp.jsx:70 ~ axiosPost ~ callBack:",
        callBack
      );
      if (callBack?.status === 200) {
        //console.log(callBack.data);
        swal(callBack.data);
        setCount(60);
      } else {
        swal(callBack.data.message);
      }
    });
  }

  function passwordChanger(e) {
    const id = localStorage.id;
    //console.log(id);
    e.preventDefault();
    var url = "user/verify-forgot-password";
    // const obj = {
    //   code: otp,
    //   userId: id,
    //   email: location.state.email,
    // };
    var obj = {
      device: "web",
      deviceInfo: {},
      email: location.state.email,
      code: otp,
    };
    setIsLoading(true);
    axiosPost(url, obj, "", (callBack) => {
      console.log(
        "🚀 ~ file: VerifyForgetOtp.jsx:102 ~ axiosPost ~ callBack:",
        JSON.stringify(callBack)
      );
      //console.log(callBack);
      if (callBack?.status === 200) {
        setIsLoading(false);
        swal(callBack.message);
        navigate("/updateforgetpassword", {
          state: { callBack, email: location.state.email },
        });
      } else {
        setIsLoading(false);
        swal(callBack.message);
      }
    });
  }

  function Sec() {
    if (count > 0) {
      return " seconds";
    } else {
      return (
        <span onClick={resendOtp} style={{ cursor: "pointer" }}>
          Resend
        </span>
      );
    }
  }

  return (
    <div>
      {isLoading ? <Loading /> : false}
      {mobileForgotpswd ? (
        <>
          <VerifyForgetOtpMobile
            count={count}
            resendOtp={resendOtp}
            verify={passwordChanger}
            setOtp={setOtp}
            otp={otp}
          />
        </>
      ) : (
        <>
          <SigninHeader />
          <main className="login-wrapper">
            <div className="container">
              <div className="login-left moible-wrap">
                <h2>Email Verification</h2>
                <p
                  style={{
                    width: "350px",
                    fontSize: "18px",
                    padding: "30px 0px",
                  }}
                >
                  Please check your email and enter the Security code below
                </p>
                <form onSubmit={passwordChanger}>
                  <div className="mobile-cntnt">
                    <div className="login-item">
                      <label htmlFor="lg1">Enter OTP code below</label>
                      <input
                        type="number"
                        id="lg1"
                        placeholder="OTP"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 6);
                        }}
                        name="otp"
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      />
                      <p
                        style={{
                          fontSize: "15px",
                          padding: "10px 18px",
                          textAlign: "center",
                        }}
                      >
                        Didn't receive the OTP email? You can request a new one
                        in{" "}
                        <span id="time" style={{ color: "red" }}>
                          {count}
                          <Sec />
                        </span>
                      </p>
                    </div>

                    <div className="login-item mt-5">
                      <button type="submit"> Verify </button>
                    </div>

                    <div className="login-links">
                      <p>
                        Already have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Login here
                        </Link>
                      </p>
                      <p>
                        Don’t have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Signup here
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>

          <SigninFooter />
        </>
      )}
    </div>
  );
}

export default VerifyForgetOtp;
