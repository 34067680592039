/** @format */

import swal from "sweetalert";
import { SigninFooter } from "../../../Components/Footer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SigninHeader } from "../../../Components/Header";
import { loginDetails } from "../../../redux/action";
import { axiosGet, axiosPost } from "../../../utils/axiosRequest";
import Loading from "../../../Components/Loading";
import ForgotPassword from "../../../MobilePages/Auth/ForgotPassword";

function VerifyForgetEmail() {
  const [mail, setMail] = useState("");
  const email = mail.toLowerCase();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* mobile screen */

  const [mobileForgotpswd, setMobileForgotpswd] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileForgotpswd(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileForgotpswd(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  function verify(e) {
    e.preventDefault();
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // const url = `/user/forgetPassword?email=${email}`;
    const url = `/user/forgot-password`;
    if (email === "") {
      swal("Fill Email First");
    } else if (email.match(validRegex) === null) {
      swal("Enter a valid Email");
    } else {
      setIsLoading(true);
      var obj = {
        device: "web",
        deviceInfo: {},
        email: email,
      };
      axiosPost(url, obj, "", (callBack) => {
        console.log(
          "🚀 ~ file: VerifyForgetEmail.jsx:55 ~ axiosGet ~ callBack:",
          JSON.stringify(callBack?.message)
        );
        setIsLoading(false);

        if (callBack?.status === 200) {
          swal(callBack?.message);
          navigate("/verify-forget-otp", { state: { email: email } });
          return;

          //console.log(callBack);

          localStorage.setItem("userId", callBack.data.userId);
          // localStorage.setItem('url','/createNewPass')
          const url = `/user/${callBack.data.data.userId}`;
          axiosGet(url, (callBack) => {
            if (callBack?.status === 200) {
              //console.log(callBack);
              dispatch(loginDetails({ payload: callBack?.data.data }));
              localStorage.setItem("id", callBack.data.data._id);
              setIsLoading(false);
              navigate("/verify-forget-otp", { state: { email: email } });
            } else {
              setIsLoading(false);
              swal(callBack.message);
            }
          });

          // } else {
          //     if(callBack.message === "OTP Already sent, Please verify!!"){
          //     swal("OTP Already sent, Please verify!!")
          //     localStorage.setItem('userId', callBack.data.userId)
          //     // localStorage.setItem('url','/createNewPass')
          //     const url = `/user/${callBack.data.userId}`;
          //     //console.log("url");
          //         axiosGet(url, (callBack) => {
          //             if (callBack?.status === 200) {
          //                 //console.log(callBack);
          //                 dispatch(loginDetails({ payload: callBack?.data.data }));
          //                 localStorage.setItem('id', (callBack.data.data._id));
          //                 setIsLoading(false)
          //                 navigate('/updateforgetpassword')

          //             }else{
          //                 setIsLoading(false);
          //                 swal(callBack.message);
          //             }
          //         })
        } else {
          setIsLoading(false);
          if (callBack.message == "OTP already send!") {
            swal({
              title: callBack.message,
              // text: callBack.message,
              showCancelButton: true,
              closeOnConfirm: false,
              animation: "slide-from-top",
            }).then(() => {
              navigate("/verify-forget-otp", { state: { email: email } });
              // navigate("/login-signup");
            });
            return;
          }
          swal(callBack.message);
          // OTP already send!
        }
      });
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileForgotpswd ? (
        <ForgotPassword verify={verify} setMail={setMail} />
      ) : (
        <div>
          <SigninHeader />

          <main className="login-wrapper">
            <div className="container">
              <div className="login-left moible-wrap">
                <h2>Verify Your Email</h2>
                <p>We do this for security and preventing bots</p>
                <form onSubmit={verify}>
                  <div className="login-content mobile-cntnt">
                    <div className="login-item">
                      <label htmlFor="lg1">Enter Email Address</label>
                      <input
                        type="email"
                        pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                        id="lg1"
                        placeholder="Email"
                        name="Email"
                        onChange={(e) => setMail(e.target.value)}
                        required
                      />
                    </div>

                    <div className="login-item">
                      <button type="submit">Verify</button>
                    </div>
                  </div>
                </form>
                <div className="login-links">
                  <p>
                    Already have an account?{" "}
                    <Link className="Link" to="/login-signup">
                      Login here
                    </Link>
                  </p>
                  <p>
                    Don’t have an account?{" "}
                    <Link className="Link" to="/login-signup">
                      Signup here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </main>

          <SigninFooter />
        </div>
      )}
    </>
  );
}

export default VerifyForgetEmail;
