import appStore from "../images/app-store-3.png";
import googlePlay from "../images/google-play-3.png";
import runProf from "../images/run-prof.png";

function FreeSearch() {
  return (
    <div className="run-area" id="freeSearch">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 order-2 order-md-1">
            <div className="run-cnt">
              <h2>
                Run <strong>one free</strong> background check just for signing
                up!
              </h2>
              <p>
                Run one free background on yourself, your neighbor, your best
                friend, or that first date coming up. The first background check
                is on us, with absolutely no obligation to subscribe!{" "}
              </p>

              <ul>
                                <li><a target="blank" href="https://apps.apple.com/app/friend-verifier-search/id1669432431"><img src={appStore} alt="" /></a></li>
                                <li><a target="blank" href="https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US"><img src={googlePlay} alt="" /></a></li>
                            </ul>
            </div>
          </div>

          <div className="col-md-5 order-1 order-md-2">
            <div className="run-item">
              <img src={runProf} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeSearch;
