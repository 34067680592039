import { useState } from "react";
import logo from "../../../../src/assets/icons/logo.png"
import EmailIcon from "../../../assets/icons/email.png";
import Password from "../../../assets/icons/password.png";
import Invisible from "../../../assets/icons/invisible.png";
import Visible from "../../../assets/icons/eye.png";
import { useNavigate } from "react-router-dom";
import Header from "../../../MobileComponents/Header";
import Arrow from "../../../assets/icons/left-Arrow.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faChevronRight, faEnvelope, faLock, faPhone, faShareNodes, faToggleOff, faUser } from "@fortawesome/free-solid-svg-icons";

 function EmailAccount({EmailChanger,setEmail,setOldEmail}){
    const navigate = useNavigate();

    return(
        <div className="login_container">
            <Header height={""} padding={""} leftMenu="back" leftSrc={Arrow}   title={"Email"}  leftButtonClick={() => navigate(-1)} />
            <main className="mailAccount-wrapper" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="login-left pe-lg-5 me-xl-5">
                             <div> 
                               <p className="updatePassword_text"> Update Email Address </p>
                             </div>
                            <form onSubmit={EmailChanger}>
                                <div className="login-content">
                                     <div className="login-item">
                                        <div className="password">
                                         <div className="mailAccount_icon_position">
                                          <FontAwesomeIcon icon={faEnvelope}  className=" account_input_icon"/>
                                         </div>
                                            <input  autoComplete="new-password" name="password" type="email" 
                                            placeholder=" Current Email" required 
                                            onChange={(e) => setOldEmail(e.target.value)}

                                            />
                                            
                                        </div>
                                    </div>
                                    <div className="login-item">
                                        <div className="password">
                                         <div className="mailAccount_icon_position">
                                          <FontAwesomeIcon icon={faEnvelope}  className="account_input_icon"/>

                                         </div>
                                            <input autoComplete="new-password" name="password" type="email" 
                                            placeholder=" New Email" required 
                                            onChange={(e) => setEmail(e.target.value)}

                                            />
                                            
                                        </div>
                                    </div>

                                    <div className="login-item">
                                        <button type="submit">Update New Email</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        </div>
    )
 }

 export default EmailAccount;