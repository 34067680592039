function Loading(){

    return(

        <div id="loadings">
          <h3 style={{marginTop:'191px'}}>Please wait ....</h3>
        </div>
        
    )
}

export default Loading;