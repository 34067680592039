/** @format */

import user from "../images/user.png";
import phone from "../images/phone-2.png";
import email from "../images/email-2.png";
import home from "../images/home-2.png";
import contact from "../images/contact.png";
import store from "../images/app-store-2.png";
import play from "../images/google-play-2.png";
import { useEffect, useState } from "react";
import { axiosGet } from "../utils/axiosRequest";

function Pricing() {
  useEffect(() => {
    GetPlansList(0);
    GetPlansList(1);
    // GetPlansList(2);
  }, []);

  const [PayAsYouGo, setPayAsYouGo] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [sub, setSub] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  useEffect(() => {
    GetPlansList(selectedTab);
  }, [selectedTab]);

  const GetPlansList = (SelectedPlan = 0) => {
    var url;
    if (SelectedPlan == 1) {
      url = "/subscriptionPlans/list?planType=Monthly";
    } else if (SelectedPlan == 2) {
      url = "/subscriptionPlans/list?planType=Yearly";
    } else if (SelectedPlan == 0) {
      url = "/subscriptionPlans/list?planType=OneTime";
    }
    axiosGet(url, (callBack) => {
      if (callBack?.status == 200) {
        if (SelectedPlan == 0) {
          setPayAsYouGo(callBack?.data?.data);
        } else {
          setSub(callBack?.data?.data);
          // setSub(callBack?.data?.data);
        }
      }
    });
  };
  const colors = ["#BAD8E0", "#FAD143", "#030303"];

  return (
    <div className="price-area" id="pricing">
      <div className="containers">
        <div className="price-upper text-center">
          <h2>Pricing</h2>
          <p>
            Friend or foe, you need to know. Your safety and the safety of your
            family is our motivation, to keep prices so low.
          </p>
          <h6>(That rhymes, and we weren't even trying.) </h6>
        </div>

        <div className="price-main" style={{ marginTop: 40 }}>
          <div className="tabs" style={{ marginBottom: 30 }}>
            <div className="tab-upper">
              <p>Billed:</p>
              <ul className="tab-links">
                <li className={selectedTab == 1 && "active"}>
                  <a onClick={() => setSelectedTab(1)}>Monthly</a>
                </li>
                <li className={selectedTab == 2 && "active"}>
                  <a onClick={() => setSelectedTab(2)}>Yearly</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            {PayAsYouGo?.map((list, index) => (
              <div className="col-md-4 col-lg-3">
                <div className={`price-box ${index == 1 && "price-box2"}`}>
                  {/* <h4>{list?.planCategory}</h4> */}
                  {list?.planType == "OneTime" && <h4>{"Pay As You Go"}</h4>}
                  <h2>
                    $
                    {list?.discountedPrice
                      ? list?.discountedPrice
                      : list?.price}
                    /<span>{" each"}</span>
                    {/* {list?.totalSearch} {list?.planName} */}
                  </h2>
                  <p style={{ color: "#6D7783" }}>
                    Run a background check commitment free at your convenience.
                    <div style={{ color: "#777777" }}>
                      Regular Price: ${list.price} -{" "}
                      <strong style={{ color: "red" }}>
                        {list.discountPercentage}% off!
                      </strong>
                    </div>
                  </p>

                  <ul>
                    <li>
                      <a>
                        <img src={user} alt="" /> People Search
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={phone} alt="" /> Reverse Phone
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={email} alt="" />
                        Reverse Email
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={home} alt="" /> Address Search
                      </a>
                    </li>
                    {/* <li>
                      <a>
                        <img src={contact} alt="" /> Contact Search
                      </a>
                    </li> */}
                  </ul>

                  <div className="price-btn">
                    <a>
                      Not for businesses purposes or <br /> any use cases that
                      fall under the FCRA
                    </a>
                  </div>
                </div>
              </div>
            ))}
            {sub?.map((list, index) => (
              <div className="col-md-4 col-lg-3">
                <div
                  className={`price-box ${
                    (index == 0 || index == 2) && "price-box2"
                  }`}
                >
                  {list?.planCategory && (
                    <div
                      style={{
                        width: 120,
                        height: 40,
                        backgroundColor:
                          list?.planCategory == "Basic"
                            ? colors[0]
                            : list?.planCategory == "Gold"
                            ? colors[1]
                            : list?.planCategory == "Platinum"
                            ? colors[2]
                            : "gray",
                        //   position: "absolute",
                        borderRadius: 30,
                        //   bottom: 100,
                        left: 10,
                        top: 0,
                        position: "relative",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          color:
                            list?.planCategory == "Platinum"
                              ? "white"
                              : "black",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",

                          textAlign: "center",
                          top: 8,
                          position: "relative",
                          // fontSize: 14,
                        }}
                      >
                        {list?.planCategory}
                      </p>
                    </div>
                  )}
                  <h2>
                    $
                    {list?.discountedPrice
                      ? list?.discountedPrice
                      : list?.price}
                    /<span>{" per month"}</span>
                    {/* {list?.totalSearch} {list?.planName} */}
                  </h2>
                  <p>
                    {/* <strong>{list?.totalSearch}</strong> {list?.planName} */}
                    <p style={{ color: "#6D7783" }}>
                      Run{" "}
                      <strong>
                        {list?.totalSearch} {list?.planName}
                      </strong>{" "}
                      {/* per month. */}
                      {list.discountPercentage && (
                        <div style={{ color: "#777777" }}>
                          {list?.descriptionWeb} -{" "}
                          <strong style={{ color: "red" }}>
                            {list.discountPercentage}% off!
                          </strong>
                        </div>
                      )}
                    </p>
                  </p>

                  <ul>
                    <li>
                      <a>
                        <img src={user} alt="" /> People Search
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={phone} alt="" /> Reverse Phone
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={email} alt="" />
                        Reverse Email
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={home} alt="" /> Address Search
                      </a>
                    </li>
                    {/* <li>
                      <a>
                        <img src={contact} alt="" /> Contact Search
                      </a>
                    </li> */}
                  </ul>

                  <div className="price-btn">
                    <a>
                      Not for businesses purposes or <br /> any use cases that
                      fall under the FCRA
                    </a>
                  </div>
                </div>
              </div>
            ))}

            {/* {yearlyPlans?.map((list, index) => (
              <div className="col-md-6 col-lg-4">
                <div className={`price-box ${index == 1 && "price-box2"}`}>
                  <h4>{list?.planCategory}</h4>
                  <h2>
                    $
                    {list?.discountedPrice
                      ? list?.discountedPrice
                      : list?.price}
                    /<span>{list?.planType}</span>
                  </h2>
                  <p>
                    <strong>{list?.totalSearch}</strong> {list?.planName}
                  </p>

                  <ul>
                    <li>
                      <a>
                        <img src={user} alt="" /> People Search
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={phone} alt="" /> Reverse Phone
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={email} alt="" />
                        Reverse Email
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={home} alt="" /> Address Search
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={contact} alt="" /> Contact Search
                      </a>
                    </li>
                  </ul>

                  <div className="price-btn">
                    <a>
                      Not for businesses purposes or <br /> any use cases that
                      fall under the FCRA
                    </a>
                  </div>
                </div>
              </div>
            ))} */}

            {/* <div className="col-md-6 col-lg-4">
              <div className="price-box price-box2">
                <h4>Premium</h4>
                <h2>
                  $9.99/<span>month</span>
                </h2>
                <p>
                  <strong>100</strong> Background Checks Every Month
                </p>

                <ul>
                  <li>
                    <a>
                      <img src={user} alt="" /> People Search
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={phone} alt="" /> Reverse Phone
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={email} alt="" />
                      Reverse Email
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={home} alt="" /> Address Search
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={contact} alt="" /> Contact Search
                    </a>
                  </li>
                </ul>

                <div className="price-btn">
                  <a>
                    Not for businesses purposes or <br /> any use cases that
                    fall under the FCRA
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="price-box">
                <h4>Platinum</h4>
                <h2>
                  $39.99/<span>month</span>
                </h2>
                <p>
                  <strong>Unlimited</strong> Background Checks
                </p>

                <ul>
                  <li>
                    <a>
                      <img src={user} alt="" /> People Search
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={phone} alt="" /> Reverse Phone
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={email} alt="" />
                      Reverse Email
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={home} alt="" /> Address Search
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={contact} alt="" /> Contact Search
                    </a>
                  </li>
                </ul>

                <div className="price-btn">
                  <a>
                    Not for businesses purposes or <br /> any use cases that
                    fall under the FCRA
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
