import React from "react";
import appStore from "../images/app-store-2.png";
import googlePlay from "../images/google-play-2.png";
import iphone from "../images/iphone.png";
import kprc from "../images/kprc.png";
import kron4 from "../images/kron4.png";
import z100 from "../images/z100.png";
import elvisDuran from "../images/elvis-duran.png";
import dailyNews from "../images/daily-news.png";
import ktla5 from "../images/ktla5.png";
import mashable from "../images/mashable.png";
import appStore2 from "../images/app-store-2.png";
import googlePlay2 from "../images/google-play-2.png";

function About() {
  return (
    <div>
      <div className="app-area" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="app-cnt">
                <h4>
                  {" "}
                  The app that nearly <strong> 14 million </strong> people
                  trusted is back!{" "}
                </h4>
                <p>
                  {" "}
                  Run <strong> background checks </strong> from anywhere!{" "}
                </p>
              </div>
              <div className="rising-upper">

              <ul>
              <li>
              <a target="blank" href="https://apps.apple.com/app/friend-verifier-search/id1669432431">
                  {" "}
                  <img src={appStore2} alt="" />{" "}
                </a>
              </li>

              <li>
              <a target="blank" href="https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US">
                  {" "}
                  <img src={googlePlay2} alt="" />
                </a>
              </li>
            </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="app-item">
                <img src={iphone} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="brand-area"  id="about">
        <div className="container">
          <div className="brand-main">
            <div className="brand-item">
              <img src={kprc} alt="" />
            </div>

            <div className="brand-item">
              <img src={kron4} alt="" />
            </div>

            <div className="brand-item">
              <img src={z100} alt="" />
            </div>

            <div className="brand-item">
              <img src={elvisDuran} alt="" />
            </div>

            <div className="brand-item">
              <img src={dailyNews} alt="" />
            </div>

            <div className="brand-item">
              <img src={ktla5} alt="" />
            </div>

            <div className="brand-item">
              <img src={mashable} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="rising-area">
        <div className="container">
          <div className="rising-upper">
            <h2> Rising like a Phoenix, Friend Verifier is Back </h2>
            <p>
              {" "}
              Initially launched in 2012, we had one mission, give you the tools
              to control your safety, and the mission continues.{" "}
            </p>

            {/* <ul>
              <li>
                <a>
                  {" "}
                  <img src={appStore2} alt="" />{" "}
                </a>
              </li>

              <li>
                <a>
                  {" "}
                  <img src={googlePlay2} alt="" />
                </a>
              </li>
            </ul> */}
          </div>

          <div className="rising-item">
            <iframe
              src="https://www.youtube.com/embed/2RGa9FSbfeo"
              title="Friend Verifier | We&#39;re Back!"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
