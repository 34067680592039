import logo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";
import Header from "../../MobileComponents/Header";
import Invisible from "../../assets/icons/invisible.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function PhoneSearch({check, setPhone,phone}) {
  const navigate = useNavigate();
  // const [phone, setPhone] = useState("");

  const clearWrittenText = () => {
    setPhone("");
  }

  return (
    <main className="phoneSearch-wrapper">
      <div className="container home_container ">
        <div className="row">
          <div className="col-lg-12">
            <div className="login-left pe-lg-5 me-xl-5">
              <form onSubmit={(event) => check(event, "phone")}>
                <div className="login-content">
                  <div className="HomeInput-item">
                    <div className="home_input_box">
                      <input
                        placeholder=" Phone Number"
                        name="age"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        id="focus"
                        required
                      />
                      <div className="home_Eyeicon_position home_input_icon" onClick={()=>clearWrittenText()}>
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="home_icon_style"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="login-item home_PhoneSearchBtn">
                    <div className="phoneSearch_search">
                      <button className="home_search_btn" type="submit">
                        Search
                      </button>
                    </div>
                  </div>

                  {/* <div className="phonePage_RecentSearch">
                    <RecentSearches />
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>

    //   <Footer />

    // </div>
  );
}

export default PhoneSearch;