import {
  LOGIN_DETAILS,
  LOG_OUT,
  SEARCH_DETAILS,
  META_BODY,
} from "./actionType";

export const loginDetails = ({ payload }) => ({
  type: LOGIN_DETAILS,
  payload: payload,
});
export const metaBody = ({ payload }) => ({
  type: META_BODY,
  payload: payload,
});

export const searchDetails = ({ payload }) => ({
  type: SEARCH_DETAILS,
  payload: payload,
});

export const logOut = () => ({
  type: LOG_OUT,
});
