/** @format */

import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Dashboard from "./Pages/Home/Dashboard";
import LoggedHome from "./Pages/Home/LoggedHome";
import LoginSignup from "./Pages/Login_Signup/LoginSignup";
import RecordDisplay from "./Pages/SearchPages/RecordDisplay";
import ResultPage from "./Pages/SearchPages/ResultPage";
import SubscriptionPage from "./Pages/Subscription/SubscriptionPage";
import SpecialOffers from "./Pages/Subscription/SpecialOffers";
import EmailOtpVerification from "./Pages/Login_Signup/Signup/EmailOtp";
import UpdateEmail from "./Pages/Login_Signup/Update/UpdateEmail";
import UpdatePassword from "./Pages/Login_Signup/Update/UpdatePassword";
import UpdatePhone from "./Pages/Login_Signup/Update/UpdatePhone";
import PhoneVerification from "./Pages/Login_Signup/Signup/PhoneVerification";
import PhoneVerificationOtp from "./Pages/Login_Signup/Signup/PhoneVerificationOtp";
import Invite from "./Pages/Invite/Invite";
import Success from "./Pages/status/success";
import Failure from "./Pages/status/failure";
import DoNotSell from "./Pages/Home/About/do-not-sell";
import Dos from "./Pages/Home/About/dos";
import Privacy from "./Pages/Home/About/privacy";
import Terms from "./Pages/Home/About/terms";
import TermServiceNew from "./Pages/Home/About/TermServiceNew";
import ContatUs from "./Pages/ContactUs";
import SupportTicket from "./Pages/SupportTicket";
import SupportTicketChat from "./Pages/SupportTicketChat";
import { useEffect, useState } from "react";
import UpdateForgetPassword from "./Pages/Login_Signup/Login/UpdateForgetPassword";
import VerifyForgetEmail from "./Pages/Login_Signup/Login/VerifyForgetEmail";
import VerifyForgetOtp from "./Pages/Login_Signup/Login/VerifyForgetOtp";
import Delete from "../src/Pages/delete";

import MobileHome from "./MobilePages/MobileHome";
import SignUp from "./MobilePages/Auth/SignUp";
import SignUpOTP from "./MobilePages/Auth/SignUpOTP";
import ForgotPassword from "./MobilePages/Auth/ForgotPassword";
import ResetPassword from "./MobilePages/Auth/ResetPassword";

import TermsBottomBar from "./MobileComponents/TermsBottomBar";
import MobileDashboard from "./MobilePages/home/MobileDashboard";
import RecentSearches from "./MobileComponents/RecentSearches";
import SMSVerification from "./MobilePages/Auth/SmsVerification";
import PhoneSearch from "./MobilePages/home/PhoneSearch";
import EmailSearch from "./MobilePages/home/EmailSearch";
import Account from "./MobilePages/menuPages/account/Account";
import EmailAccount from "./MobilePages/menuPages/account/EmailAccount";
import PasswordAccount from "./MobilePages/menuPages/account/PasswordAccount";
import MobileUpdateAccount from "./MobilePages/menuPages/account/MobileUpdateAccount";
import Support from "./MobilePages/menuPages/supportAndLegal.jsx/Support";
import MobileSubscriptions from "./MobilePages/subscriptions/MobileSubscriptions";
import DosAndDont from "./MobilePages/menuPages/DosAndDont";
import FAQ from "./MobilePages/menuPages/FAQ";
import TermsAndCondition from "./MobilePages/menuPages/TermsAndCondition";
import PrivacyPolicy from "./MobilePages/menuPages/PrivacyPolicy";
import MobileSupportTicket from "./MobilePages/menuPages/supportAndLegal.jsx/MobileSupportTicket";
import GetStarted from "./MobilePages/GetStarted";
import MobileResultPage from "./MobileComponents/MobileResultPage";
import MobileRecordPage from "./MobileComponents/MobileRecordPage";
import ReportRemoveStatus from "./Pages/ReportRemoveStatus";
import AlreadySubmittedStatus from "./Pages/AlreadySubmittedStatus";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   setIsMobile()
  // }, [])

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  // }, [window.innerWidth]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleResize = () => {
  //   setIsMobile(window.innerWidth <= 575);
  // };

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setIsMobile(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 575);
  };

  const handleScroll = () => {
    var scroller = document.getElementById("scroller");
    var header = document.getElementsByClassName("header-area");
    if (window.scrollY >= 50) {
      for (let i = 0; i < header.length; i++) {
        header[i].classList.add("headerfixed");
      }
    } else {
      for (let i = 0; i < header.length; i++) {
        header[i].classList.remove("headerfixed");
      }
    }
    if (window.scrollY > 500) {
      scroller.style.display = "block";
      scroller.classList.remove("fade-out");
    } else if (window.scrollY > 400) {
      scroller.classList.add("fade-out");
    } else {
      scroller.style.display = "none";
    }
  };

  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={isMobile ? <MobileHome /> : <Home />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/loggedHome" element={<LoggedHome />} />
        <Route path="/login-signup" element={<LoginSignup />} />
        <Route path="/otpVerification" element={<EmailOtpVerification />} />
        <Route path="/verify-forget-email" element={<VerifyForgetEmail />} />
        <Route path="/verify-forget-otp" element={<VerifyForgetOtp />} />
        <Route path="/report-remove" element={<ReportRemoveStatus />} />
        <Route
          path="/updateforgetpassword"
          element={<UpdateForgetPassword />}
        />
        <Route path="/phoneVerification" element={<PhoneVerification />} />
        <Route
          path="/phoneVerificationOtp"
          element={<PhoneVerificationOtp />}
        />
        <Route path="/updateEmail" element={<UpdateEmail />} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="/updatePhone" element={<UpdatePhone />} />
        <Route path="/subscriptionPage" element={<SubscriptionPage />} />
        <Route path="/SpecialOffers" element={<SpecialOffers />} />
        <Route path="/record-page" element={<RecordDisplay />} />
        <Route path="/result-page" element={<ResultPage />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/orders/successPayment" element={<Success />} />
        <Route path="/orders/failedPayment" element={<Failure />} />
        <Route path="/do-not-sell" element={<DoNotSell />} />
        <Route path="/dos" element={<Dos />} />
        <Route path="/privacy" element={<Terms />} />
        <Route path="/termsandservice" element={<TermServiceNew />} />
        <Route path="/termsandconditions" element={<Privacy />} />
        <Route path="/signup/toc" element={<Terms />} />
        <Route path="/contactUs" element={<ContatUs />} />
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route path="/support-ticket-chat" element={<SupportTicketChat />} />
        <Route path="/already-submitted" element={<AlreadySubmittedStatus />} />

        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/SignUpOTP" element={<SignUpOTP />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/MobileDashboard" element={<MobileDashboard />} />
        <Route path="/RecentSearches" element={<RecentSearches />} />
        <Route path="/SMSVerification" element={<SMSVerification />} />
        <Route path="/PhoneSearch" element={<PhoneSearch />} />
        <Route path="/EmailSearch" element={<EmailSearch />} />
        <Route path="/Account" element={<Account />} />
        <Route path="/EmailAccount" element={<EmailAccount />} />
        <Route path="/PasswordAccount" element={<PasswordAccount />} />
        <Route path="/MobileUpdateAccount" element={<MobileUpdateAccount />} />
        <Route path="/MobileSubscriptions" element={<MobileSubscriptions />} />
        <Route path="/DosAndDont" element={<DosAndDont />} />
        <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
        <Route path="/TermsBottomBar" element={<TermsBottomBar />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/MobileSupportTicket" element={<MobileSupportTicket />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/GetStarted" element={<GetStarted />} />
        <Route path="/MobileResultPage" element={<MobileResultPage />} />
        <Route path="/MobileRecordPage" element={<MobileRecordPage />} />
        <Route path="/delete" element={<Delete />} />

        <Route path="/Support" element={<Support />} />
      </Routes>

      <p
        id="scroller"
        className="scrollto-top fade-in-out"
        onClick={() => window.scrollTo(0, 0)}
      >
        {" "}
        ^{" "}
      </p>
    </div>
  );
}

export default App;
