import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../MobileComponents/Header";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";
import Arrow from "../../../assets/icons/left-Arrow.png";
import USFlag from "../../../assets/icons/unitedStatesFlag.png"


function MobileUpdateAccount({PhoneChanger,setPhone}) {

  const navigate = useNavigate();
  const [value, setValue] = useState()

  return (
    <div className="login_container">
      <Header height={""} padding={""} leftMenu="back" leftSrc={Arrow}   title={"Mobile Number"}   leftButtonClick={() => navigate(-1)}/>
      <main className="login-wrapper">
        <div className="container login_container">
        <form onSubmit={PhoneChanger}>
          <div className="row">
            <div className="col-lg-12">
              <div className="login-left pe-lg-5 me-xl-5">
                  <div className="login-content">
                  <div> 
                    <p className="updatePassword_text"> Update Phone Number </p>
                  </div>

                  </div>
                  <div className="HomeInput-item">
                  <div className="rightSideBorder login_icon_position" >
                    <img
                      src={USFlag}
                      alt="Email Icon"
                      className="usFlagIcon_position"
                    />
                  </div>
                    <div className="phoneVerification_input_box">
                      <input 
                         type="number"
                         placeholder="  (000)   000   000"
                         name="phone"
                         id="focus"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>


                  {/* <div>
                   <PhoneInput
                    country="US"
                    value={value}
                    onChange={setValue}
                    keyboardType="numeric"
                    defaultCountry="US"
                    defaultCode="US"
                    layout="first"
                    textInputProps={{ placeholderTextColor: "#000" }}
                    placeholder="  (000)   000   000"
                    codeTextStyle={{ color: "#092058", fontFamily: "SemiBold"}}
                    containerStyle={{
                      borderRadius: 12,
                      height: '59px',
                      marginTop: '70px',
                      backgroundColor: "#FBFBFB",
                      borderWidth: 1,
                      borderColor: "#BBB9CB",
                      alignSelf: "center",
                      width: '59px',
                    }}
                    disableArrowIcon
                    autoFocus
                    textContainerStyle={{
                      backgroundColor: "#FBFBFB",
                      marginLeft: -10,
                      borderLeftWidth: 1,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      borderLeftColor: "#BBB9CB",
                    }}
                    textInputStyle={{
                      color: "#000",
                      height: '59px',
                      fontSize: '16px',
                      fontFamily: "SemiBold",
                    }}
                    flagStyle={{
                        // borderRight: "1px solid #BBB9CB",
                        display:'none'
                      }}
                      inputComponent={(props) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={USFlag}
                            alt="US Flag"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <input {...props} />
                        </div>
                      )}
                  />
                  </div> */}
           

                  <div className="login-item  updateMobileNo_Btn">
                        <button className="sms_search_btn" type="submit"> Update Phone Number </button>
                   </div>
              </div>
            </div>
          </div>
        </form>
         </div>
       </main>   
    </div>
  );
} 

export default MobileUpdateAccount;
