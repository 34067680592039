import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faMobileScreen, faSliders } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import search from "../images/search.png";
import Header from "./Header";
import Arrow from "../assets/icons/left-Arrow.png";
import RFIcon from "../images/RFIcon.png";

function MobileResultPage({ Email, isPhone, searchData, setView, setDetails, state, ItemForPhone }) {
  const navigate = useNavigate();

  const recordFunc = () => {
    navigate("/record-page");
  };

  return (
    <div className="login_container result_mt">

      <Header
        position={"fixed"}
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        leftButtonClick={() => navigate(-1)}
        title={"Results"}
      />
      <main className="support-wrapper">
        <div className="container login_container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-left pe-lg-5 me-xl-5">
                <div className="login-item refine_div">
                  <p className="refineSearch_text">
                    {!Email && !isPhone && <>{searchData?.length} Results</>}{" "}
                    {/* 100 Result found for <b>Shadab</b> in <b>All States</b>
                    <br /> <p className="refine_text"> Refine Search </p> */}
                  </p>
                </div>








                {Email || isPhone ? (
                  <>
                    {searchData?.length > 0 ? (
                      <>
                        <div
                          onClick={() => {
                            // setView(true);
                            // setDetails(item);
                          }}
                          style={{ backgroundColor: "#F5F5F5" }}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyItems: "center",
                            }}
                          >
                            <div>
                              <img
                                src={RFIcon}
                                alt=""
                                style={{ height: 20, width: 25 }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: 10,
                                marginLeft: 10,
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 16,
                                }}
                              >
                                Record Found
                              </p>
                            </div>
                          </div>
                        </div>
                        <br />
                        {searchData?.length > 0 && (
                          <ItemForPhone item={searchData[0]} />
                        )}
                      </>
                    ) : (
                      <div className="result-title">
                        <h2>0 Results</h2>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {searchData?.map((item, index) => (
                      <div
                        className="resultCard_container"
                        onClick={() => {
                          setView(true);
                          // setDetails(item);
                          if (state?.research) {
                            item.metaBody = { research: true };
                          } else {
                            item = Object.assign(item, state);
                          }
                          setDetails(item);
                        }}
                      >
                        {
                          item?.viewed?.date &&
                          <div style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            background: "#415192",
                            padding: "5px 10px",
                            fontSize: '12px',
                            fontWeight: 500,
                            color: "white"
                          }}>
                            Viewed on : {new Date(item?.viewed?.date).toDateString()}
                          </div>
                        }
                        <div style={{ display: "flex", width: "100%" }}>
                          <div className="resultCardCol1">
                            {item?.viewed?.date && <br />}
                            <h4 className="resultname">
                              {item?._source.FIRST_NAME} {item?._source?.MIDDLE_NAME}{" "}
                              {item?._source.LAST_NAME}
                            </h4>
                            <div className="resultAddress">
                              <p>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  className="fontAwesomeIcon_img"
                                  style={{ color: "#c4c7d2", marginRight: "2px" }}
                                />
                                {/* STONY bROOK, NY */}
                                {item?._source.CITY}
                                {item?._source.STATE && item?._source.STATE != ""
                                  ? ", " + item?._source.STATE
                                  : "Not Found"}
                              </p>
                            </div>
                          </div>
                          <div className="resultCardCol2">
                            <h4 className="resultAge">
                              {item?.age ? <>{item?.age} <br /> <span>Years Old</span> </> : <p></p>}
                            </h4>
                          </div>
                        </div>
                        <div className="vewreportBtn"
                        // onClick={recordFunc}
                        >
                          <div style={{
                            display: "flex",
                            gap: "1rem"
                          }}>
                            <span>
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="fontAwesomeIcon_img"
                                style={{ color: "#000", width: "15px", marginRight: "5px" }}
                              />
                              {item?.emails?.length} Email Addresses</span>
                            <span>
                              <FontAwesomeIcon
                                icon={faMobileScreen}
                                className="fontAwesomeIcon_img"
                                style={{ color: "#000", width: "10px", marginRight: "5px" }}
                              />
                              {item?.phones?.length} Phone Numbers</span>
                          </div>
                          <hr />
                          <p>View Report</p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default MobileResultPage;
