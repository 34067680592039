import swal from "sweetalert";

function Contact(props) {
  function submit() {
    swal("Your Response Submitted Successfully");
  }

  return (
    <div className="contact-area" id="contact">
      <div className="container">
        <div ref={props.refProp} className="contact-upper">
          <h2>Contact Us</h2>
        </div>
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="contact-item">
                <select name="" id="">
                  <option value="sayHello">Say Hello</option>
                  <option value="mediaRequest">Media Request</option>
                  <option value="investorRelations">Investor Relations</option>
                  <option value="customSupport">Custom Support</option>
                  <option value="dataRemovalRequest">
                    Data Removal Request
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-item">
                <input
                  type="text"
                  placeholder="First Name"
                  name="fName"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-item">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-item">
                <input
                  className="Phone"
                  type="number"
                  minLength="10"
                  maxLength="10"
                  placeholder="Phone Number"
                  name="phone"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-item">
                <input
                  type="email"
                  pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                  placeholder="Email"
                  name="email"
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-item">
                <textarea
                  name="comment"
                  cols="30"
                  rows="8"
                  placeholder="Comments"
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-item">
                <button type="submit">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
