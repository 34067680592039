import { useState,useEffect } from "react";
import logo from "../../assets/icons/logo.png";
import EmailIcon from "../../assets/icons/email.png";
import Password from "../../assets/icons/password.png";
import Invisible from "../../assets/icons/invisible.png";
import Visible from "../../assets/icons/eye.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../MobileComponents/Header";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import SignUp from "./SignUp";
// import Test from "./test";


function Login({ myFunction, loginHandler, registerHandler }) {
  const navigate = useNavigate();
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  


  function loginFuction(e) {
    // e.preventDefault();
    // navigate("/dashboard");
    loginHandler();
  }
  // function forgotPasswordFuc() {
  // const loginFuction = () => {
  //   navigate("/dashboard");
  // }

  const forgotPasswordFuc =() => {
    navigate("/verify-forget-email");
  }

 
// Eye Function
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };


  useEffect(() => {
    GetKeyChain(1);
  }, []);

const GetKeyChain = (show)=>{
  var loginCred =  localStorage.loginCred
  if(loginCred != null && loginCred != "null"){
    if(show ==1){
      setTimeout(() => {
        
        setConfAlertDisplay(true)
      }, 1000);
    }
    else if(show ==2){
      loginCred = JSON.parse(loginCred)
      setEmail(loginCred?.email)
      setPassword(loginCred?.password)
      setConfAlertDisplay(false)
    }
  }
}

const [confAlertDisplay, setConfAlertDisplay] = useState(false);


const ConfirmAlert = ()=>(
  <>
   <div id="alerts">
        <div className="alert" >
          <h4>Friend Verifier</h4>
          <div>
            Would you like to use saved account?
          </div>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setConfAlertDisplay(false)}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={() => GetKeyChain(2)}
            >
              <h5 style={{ margin: "0px" }}>Yes</h5>
            </button>
          </div>
        </div>
      </div>
  </>
)
  


  return (
    <>

    {
      confAlertDisplay &&
    <ConfirmAlert/>
    }
      {isLoginForm ? (
        <div className="login_container">
          <Header height={"30%"} padding={""} titleSrc={logo} />
          <main className="login-wrapper LoginInput_upperSpace">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="login-left pe-lg-5 me-xl-5">
                    <form onSubmit={loginHandler}>
                      <div className="login-content">
                        <div className="login-item">
                          <div className="login_icon_position">
                            <img
                              src={EmailIcon}
                              alt="Email Icon"
                              className="login_input_icon img_mt"
                            />
                          </div>
                          <input
                            type="email"
                            autoComplete="username"
                            pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                            id="loginEmail"
                            placeholder="Email"
                            required
                            onChange={(e)=>setEmail(e.target.value)}
                            value={email}
                          />
                        </div>

                        <div className="login-item">
                          <div className="password">
                            <div className="login_icon_position">
                              <img
                                src={Password}
                                alt="Email Icon"
                                className="login_input_icon password_icon img_mt"
                              />
                            </div>
                            <input
                              minLength="8"
                              autoComplete="new-password"
                              id="loginPassword"
                              name="password"
                              type={isPasswordVisible ? "text" : "password"}
                              placeholder="Password"
                              required
                              onChange={(e)=>setPassword(e.target.value)}
                              value={password}
                              // id="loginPassword"
                            />
                            <div className="login_Eyeicon_position">
                              {/* <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="login_Eye_icon eye_color"
                              /> */}
                              <FontAwesomeIcon
                                icon={isPasswordVisible ? faEye : faEyeSlash}
                                 onClick={() => togglePasswordVisibility()}
                                 className="login_Eye_icon eye_color"
                              />
                              {/* <img
                                src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                                alt=""
                                id="eye1"
                                name="eye1"
                                width="30px"
                                onClick={() => myFunction("password", "eye1")}
                              /> */}
                            </div>
                          </div>
                        </div>

                        <div className="login-item">
                          <button type="submit">Login</button>
                        </div>
                        <div className="forgetPswd_div">
                          <p className="forgetHandler" > <Link
                          //  onClick={()=> forgotPasswordFuc()}
                          to={'/verify-forget-email'}
                           >Forgot Password?</Link> </p>
                        </div>

                        <div className="Or_Row">
                          <div className="bar_style" />
                          <h1 className="OR_Text">OR</h1>
                          <div className="bar_style" />
                        </div>

                        <div
                          className="login-create-item"
                          onClick={() => setIsLoginForm(!isLoginForm)}
                        >
                          <button type="submit">Create Account</button>
                        </div>
                        
                         

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <>
        <SignUp
        // myFunction={myFunction}
          loginBack={() => setIsLoginForm(!isLoginForm)}
          registerHandler={registerHandler}
        />
        </>
      )}
    </>
  );
}


export default Login;
