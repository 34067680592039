/** @format */

import React from "react";
import { LoggedFooter } from "../../Components/Footer";
import LoggedHeader from "../../Components/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { axiosGet, axiosPost, axiosGetCall } from "../../utils/axiosRequest";
import Loading from "../../Components/Loading";
import { useEffect, useRef, useCallback } from "react";
import swal from "sweetalert";
import DashboardWrapper from "../../Components/DashboardWrapper";
import SearchAreaComp from "../../Components/SearchArea";
import PayPal from "./PayPal";
import MobileSubscriptions from "../../MobilePages/subscriptions/MobileSubscriptions";

// import Stripe from "stripe";
// import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";

function SubscriptionPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [stateData, setStateData] = useState();

  const [sub, setSub] = useState([]);
  const [paymentvia, setPaymentvia] = useState({
    mode: "paypal",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [planInfo, setPlanInfo] = useState({});
  const [cancel, setCancel] = useState(false);

  /* mobile screen */

  const [mobileSubscription, setMobileSubscription] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileSubscription(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileSubscription(window.innerWidth <= 575);
  };

  // const [state, setState] = useState({
  //   id: "",
  //   plan: "",
  //   price: "",
  //   search: "",
  //   discount: "",
  //   type: "",
  // });
  const paypalContainerRef = useRef(null);

  useEffect(() => {
    // const url = `/subscriptionPlans/list`;
    // axiosGet(url, (callBack) => {
    //   console.log(callBack);
    //   if (callBack?.status === 200) {
    //     setSub(callBack.data.data);
    //   } else {
    //     alert(callBack.data.message);
    //   }
    // });

    GetPlansList(0);
    // GetSearch();
    // localStorage.setItem("pendingSearchSuccess", JSON.stringify(false));

    // var metaBody = localStorage?.metaBody;
    // if (metaBody != "null" && metaBody != null) {
    //   metaBody = JSON.parse(metaBody);
    //   setStateData(metaBody);
    // }
  }, []);

  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    GetPlansList(selectedTab);
    GetPlansList(selectedTab);
  }, [selectedTab]);

  // useEffect(() => {
  //   if (window.paypal) {
  //     renderButtons();
  //   }
  //   // .Buttons({
  //   //   createOrder: (data, actions, err) => {
  //   //     return actions.order.create({
  //   //       intent: "CAPTURE",
  //   //       purchase_units: [
  //   //         {
  //   //           description: "Cool looking table",
  //   //           amount: {
  //   //             currency_code: "CAD",
  //   //             value: 650.0,
  //   //           },
  //   //         },
  //   //       ],
  //   //     });
  //   //   },
  //   //   onApprove: async (data, actions) => {
  //   //     const order = await actions.order.capture();
  //   //     console.log(order);
  //   //   },
  //   //   onError: (err) => {
  //   //     console.log(err);
  //   //   },
  //   // })
  //   // .render(paypal.current);
  // }, []);

  const [PayAsYouGo, setPayAsYouGo] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);

  const GetPlansList = (SelectedPlan = 0) => {
    var url = "/subscriptionPlans/list?planType=Yearly&accessTier=specialOffer";
    axiosGet(url, (callBack) => {
      if (callBack?.status == 200) {
        console.log(
          "🚀 ~ file: SpecialOffers.jsx:127 ~ axiosGet ~ callBack:",
          JSON.stringify(callBack)
        );
        // if (SelectedPlan == 0) {
        //   setPayAsYouGo(callBack?.data?.data);
        //   setSelected(callBack?.data?.data[0]);
        //   if (state) {
        //     setShowFinalPayment(true);
        //   }
        // } else {
        setYearlyPlans(callBack?.data?.data);
        // }
      }
    });
  };
  const GetSearch = (SelectedPlan = 0) => {
    var url = "/getSearch";
    axiosGet(url, (callBack) => {
      console.log(
        "🚀 ~ file: SubscriptionPage.jsx:148 ~ axiosGet ~ callBack:",
        JSON.stringify(callBack?.data)
      );
      if (callBack?.status == 200) {
        setPlanInfo(callBack?.data?.data);
      }
    });
  };

  const [showFinalPayment, setShowFinalPayment] = useState(false);

  function call(e) {
    setShowFinalPayment(true);
  }
  const colors = ["#BAD8E0", "#FAD143", "#030303"];

  var search = "";
  const [selected, setSelected] = useState();

  const [view, setView] = useState(false);
  const [view1, setView1] = useState(false);

  function Warning1() {
    return (
      <div id="alerts">
        <div className="alert" style={{ height: 200 }}>
          <h4>Alert!</h4>
          <div>
            You must cancel the current plan before purchasing a new
            subscription.
          </div>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView1(false)}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={() => {
                setView1(false);
                setView(true);
              }}
            >
              <h5 style={{ margin: "0px" }}>Cancel Plan</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }
  function Warning() {
    return (
      <div id="alerts">
        <div className="alert" style={{ height: 400 }}>
          <h4>Please Confirm Subscription Cancelation!</h4>
          <div>
            By canceling your subscription you will not be charged again at the
            end of your billing cycle, you can use the remaining credits that
            you may have until the end of your subscription when all credits
            will be lost.
          </div>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView(false)}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={() => cancelSub(false)}
            >
              <h5 style={{ margin: "0px" }}>Yes</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }

  const cancelSub = () => {
    setCancel(true);
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    var url = "/getSearch/cancelPlan";
    axiosGetCall(url, (callBack) => {
      console.log(
        "🚀 ~ file: SubscriptionPage.jsx:240 ~ axiosGetCall ~ callBack:",
        JSON.stringify(callBack?.data)
      );
      if (callBack?.status == 200) {
        if (
          callBack?.data?.data?.offerTracker?.specialOffer?.status == "active"
        ) {
          alert(callBack?.data?.data?.offerTracker?.specialOffer?.status);
          return;
        }
        setView(false);
        swal("Plan canceled");
      }
      setCancel(false);
    });
  };

  const GetMetaBody = () => {
    var metaBody = localStorage?.metaBody;
    if (metaBody != "null" && metaBody != null) {
      metaBody = JSON.parse(metaBody);

      return metaBody?.fields?.map((item, index) =>
        Object.keys(metaBody?.fields[index]) != "city" &&
        Object.keys(metaBody?.fields[index]) == "phone"
          ? formatPhoneNumber(
              metaBody?.fields[index][Object.keys(metaBody?.fields[index])[0]]
            )
          : metaBody?.fields[index][Object.keys(metaBody?.fields[index])[0]] +
            " "
      );
    }
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  };
  //  state?.metaBody?.fields?.map((item, index) => {
  //   item;
  //   Object.keys(state?.metaBody?.fields[index]) != "city" &&
  //     state?.metaBody?.fields[index][
  //       Object.keys(state?.metaBody?.fields[index])[0]
  //     ] + " ";
  // })
  function List({ list, type }) {
    if (list.totalSearch > 100) {
      search = "Unlimited";
    } else {
      search = list.totalSearch;
    }
    return (
      <>
        <div
          key={list._id}
          className="radio-btn radio-btn2"
          onClick={() => setSelected(list)}
          style={{ height: 170 }}
        >
          <div
            style={{
              height: 150,
              width: "100%",
              borderRadius: 10,
              border: "1px #777777 solid",
              padding: 20,
              paddingTop: 20,
              backgroundColor: type == 4 ? "#BECEEA" : type == 5 && "#EFEFF1",
            }}
          >
            <div className="row">
              <div className="col-md-10">
                {/* {search === 1 ? ( */}
                <>
                  {PayAsYouGo?.length > 0 && type == 1 ? (
                    <h4>
                      <strong>{PayAsYouGo[0]?.planName}</strong>
                    </h4>
                  ) : type == 2 || type == 3 || type == 4 ? (
                    <>
                      <h4>
                        <strong>
                          {search} {list?.planName}
                        </strong>
                      </h4>
                    </>
                  ) : (
                    type == 5 && <></>
                  )}
                  <div>
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: type == 5 ? "#000" : "#305A9C",
                      }}
                    >
                      {" "}
                      {type == 5 ? (
                        <>{/* <GetMetaBody /> */}</>
                      ) : (
                        <>
                          $
                          {list?.discountedPrice
                            ? list?.discountedPrice
                            : list?.price}
                        </>
                      )}
                    </span>{" "}
                    {type != 5 && (
                      <>
                        /{" "}
                        <span
                          style={{
                            // fontSize: 24,
                            // fontWeight: "400",
                            color: type == 4 ? "black" : "lightgray",
                          }}
                        >
                          {type == 1 ? "Per search" : list?.description}
                        </span>
                      </>
                    )}
                  </div>
                  {type == 5 ? (
                    <>
                      <div>
                        <div style={{ color: "#777777" }}>
                          <span
                            style={{
                              // fontSize: 18,
                              // fontWeight: "400",
                              // color: type == 5 ? "#000" : "#305A9C",
                              fontSize: 24,
                              fontWeight: "400",
                              color: "#305A9C",
                            }}
                          >
                            $
                            {list?.discountedPrice
                              ? list?.discountedPrice
                              : list?.price}
                          </span>
                          <>
                            /{" "}
                            <span
                              style={{
                                color: "black",
                              }}
                            >
                              {"Per search"}
                            </span>
                          </>

                          {list.discountPercentage ? (
                            <div>
                              <div style={{ color: "#777777" }}>
                                Regular Price: ${list.price} -{" "}
                                <strong style={{ color: "red" }}>
                                  {list.discountPercentage}% off!
                                </strong>
                              </div>
                            </div>
                          ) : (
                            <>
                              Price:{" "}
                              {list?.discountedPrice
                                ? list?.discountedPrice
                                : list?.price}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {list.discountPercentage ? (
                        <div>
                          <div style={{ color: "#777777" }}>
                            Regular Price: {list.price} -{" "}
                            <strong style={{ color: "red" }}>
                              {list.discountPercentage}% off!
                            </strong>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div style={{ color: "#777777" }}>
                            {list?.description}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
              <div
                className="col-md-2"
                style={{
                  // border: "1px solid red",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  height: 35,
                  marginTop: 25,
                }}
              >
                <div
                  style={{
                    width: 50,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      backgroundColor: "white",
                      borderRadius: 15,
                      // position: "relative",
                      // left: 10,
                      border: "0.5px solid gray",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {list._id == selected?._id && (
                      <div
                        style={{
                          height: 20,
                          width: 20,
                          backgroundColor: "#315A9C",
                          borderRadius: 10,
                          // position: "relative",
                          // left: 10,
                          // border: "0.5px solid red",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  function List1({ list }) {
    if (list.totalSearch > 100) {
      search = "Unlimited";
    } else {
      search = list.totalSearch;
    }
    return (
      <>
        <div
          key={list._id}
          className="radio-btn radio-btn2"
          onClick={() => setSelected(list)}
          style={{ height: 150 }}
        >
          <label htmlFor={list._id}>
            <div
              style={{
                display: "flex",
                // justifyContent: "flex-start",
                // alignItems: "center",
                // width: "max-content",
              }}
            >
              <div className="row">
                <div className="col-md-8">
                  {search === 1 ? (
                    <>
                      {PayAsYouGo?.length > 0 && (
                        <h4>
                          <strong>{PayAsYouGo[0]?.planName}</strong>
                        </h4>
                      )}
                      <div>
                        <span>
                          {" "}
                          $
                          {list?.discountedPrice
                            ? list?.discountedPrice
                            : list?.price}
                        </span>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <div style={{ width: 50 }}>
                    <div
                      style={{
                        height: 30,
                        width: 30,
                        backgroundColor: "white",
                        borderRadius: 15,
                        // position: "relative",
                        // left: 10,
                        border: "0.5px solid gray",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {list._id == selected?._id && (
                        <div
                          style={{
                            height: 20,
                            width: 20,
                            backgroundColor: "#315A9C",
                            borderRadius: 10,
                            // position: "relative",
                            // left: 10,
                            // border: "0.5px solid red",
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <span className="radio-cnt">
                {search === 1 ? (
                  <>
                    {/* <p>Pay As You Go</p> */}
                    {/* <h4> */}
                    {/* <div className="row">
                      <div className="col-md-2">
                        <p>
                        
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p> Per Background Check</p>
                      </div>
                    </div> */}

                    {/* </h4> */}
                  </>
                ) : (
                  <>
                    {search === "Unlimited" ? (
                      <>
                        <p>{search} Searches</p>
                        <h4>
                          <strong>
                            $
                            {list?.discountedPrice
                              ? list?.discountedPrice
                              : list?.price}
                          </strong>{" "}
                          Billed Monthly
                        </h4>
                      </>
                    ) : (
                      <div>
                        <p>{search} Searches Per Month</p>
                        <h4>
                          <strong>
                            $
                            {list?.discountedPrice
                              ? list?.discountedPrice
                              : list?.price}
                          </strong>{" "}
                          {list?.planType == "Monthly"
                            ? "/ " + list?.planType
                            : "/ " + list?.description}
                          {/* Billed Monthly */}
                        </h4>
                      </div>
                    )}
                  </>
                )}
              </span>
              <span className="radio-cntinr">
                {/* {search === 1 ? (
              <></>
            ) : (
              <>
                <h2>
                  {list.discountPercentage}% <strong>off!</strong>
                </h2>
                <p>(Regular Price: {list.price}) </p>
              </>
            )} */}
                <h2>
                  {list?.discountedPrice && list?.discountPercentage ? (
                    <>
                      {list.discountPercentage}% <strong>off!</strong>
                      <p>(Regular Price: {list.price}) </p>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "100px" }} />
                    </>
                  )}
                </h2>
              </span>
            </div>
          </label>
        </div>
      </>
    );
  }

  const BilledSelectorComp = () => (
    <>
      <div
        style={{
          height: 70,
          width: "100%",
          justifyContent: "flex-end",
          flexDirection: "row",
          paddingHorizontal: 20,
          // alignItems: "center",
          marginBottom: 10,
          bottom: 20,
          position: "relative",
          right: 0,
          border: "1px solid red",
        }}
      >
        <div
          style={{
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <p>Billed:</p>
          <div
            style={{
              display: "flex",

              height: 35,
              width: "100%" / 2,
              // backgroundColor: "red",
              borderRadius: 20,
              flexDirection: "row",
              alignItems: "center",
              // border
              borderWidth: 0.5,
              paddingHorizontal: 3,
              marginLeft: 10,
            }}
          >
            <div
              activeOpacity={0.5}
              onPress={() => {
                // setSelectedPlan(1);
              }}
              style={{
                height: 30,
                width: "50%",
                // backgroundColor: SelectedPlan == 1 && "#305A9C",
                backgroundColor: "#305A9C",
                borderRadius: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={
                  // styles.choosePlanContainerText,
                  {
                    // color: SelectedPlan == 1 ? "white" : "black",
                    fontSize: 12,
                  }
                }
              >
                Monthly
              </p>
            </div>
            <div
              activeOpacity={0.5}
              onPress={() => {
                // setSelectedPlan(2);
              }}
              style={{
                height: 30,
                width: "50%",
                // backgroundColor: SelectedPlan == 2 && "#305A9C",
                borderRadius: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={
                  // styles.choosePlanContainerText,
                  {
                    // color: SelectedPlan == 2 ? "white" : "black",
                    fontSize: 12,
                  }
                }
              >
                Yearly
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const PayPalPaymentRequest = (obj) => {
    // setShowModal(false);
    var url =
      "/orders/successPayment?planId=" +
      selected?._id +
      "&paymentMode=online&paymentVia=google&purchaseToken=" +
      obj?.purchaseToken;

    console.log(
      "🚀 ~ file: SubscriptionPage.jsx:654 ~ PayPalPaymentRequest ~ url:",
      url
    );
    // var Token = userData?.loginDetails?.Authorization;
    // setPayPalLoading(true);
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    axiosGetCall(url, (callBack) => {
      if (callBack?.data?.status == 200) {
        swal("Payment successful.");

        localStorage.setItem("pendingSearchSuccess", JSON.stringify(true));
        navigate(-1);
      }
      // setPayPalLoading(false);
      // simpleAlertCall("Payment successful.", () => {
      //   navigation.goBack();
      // });
    });
  };

  const [isChecked, setIsChecked] = useState(false);

  // document
  //   .querySelector('input[name="payMode"]:checked')
  //   .addEventListener("click", function () {
  //     // Query for only the checked checkboxes and put the result in an array
  //     let checked = document.querySelectorAll("input[type='checkbox']:checked");

  //     // alert(checked);
  //     // console.clear();
  //     // // Loop over the array and inspect contents
  //     checked.forEach(function (cb) {
  //       console.log(cb.value);
  //     });
  //   });

  const GetCheckBox = () => {
    var checkbox = document.getElementById("payMode");
    setIsChecked(checkbox?.checked);
  };

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileSubscription ? (
        <MobileSubscriptions />
      ) : (
        <div>
          {view1 && <Warning1 />}
          {view && <Warning />}
          <LoggedHeader />

          <div className="dashboard-wrapper">
            <div className="container">
              <SearchAreaComp />
              {/* <DashboardWrapper /> */}
              {/* <br /> */}

              <div className="row">
                <div className="col-md-4">
                  {!cancel && <DashboardWrapper />}
                </div>
                <div className="col-md-8">
                  <div
                    className="subscribe-box"
                    style={
                      {
                        // textAlign: "center",
                      }
                    }
                  >
                    <h4>🎊 Special One Time Deal 🎊</h4>
                    <br />
                    <div>
                      Your security is important to us, and we'd hate to see you
                      leave. We have a one time special offer for you to stay;
                      {"\n"}Check it out.
                    </div>
                    <br />
                    <>
                      {yearlyPlans?.map((item, index) => (
                        <>
                          {item?.planCategory && (
                            <div
                              style={{
                                width: 120,
                                height: 40,
                                backgroundColor:
                                  item?.planCategory == "Basic"
                                    ? colors[0]
                                    : item?.planCategory == "Gold"
                                    ? colors[1]
                                    : item?.planCategory == "Platinum"
                                    ? colors[2]
                                    : "gray",
                                //   position: "absolute",
                                borderRadius: 30,
                                //   bottom: 100,
                                left: 10,
                                top: 15,
                                position: "relative",
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    item?.planCategory == "Platinum"
                                      ? "white"
                                      : "black",
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",

                                  textAlign: "center",
                                  top: 5,
                                  position: "relative",
                                  // fontSize: 14,
                                }}
                              >
                                {item?.planCategory}
                              </p>
                            </div>
                          )}
                          <List list={item} type={2} />
                        </>
                      ))}
                      {/* <h4>Yearly</h4> */}

                      {/* {sub?.map((item, index) => (
                          <>
                            {item?.planCategory && (
                              <div
                                style={{
                                  width: 120,
                                  height: 40,
                                  backgroundColor:
                                    item?.planCategory == "Basic"
                                      ? colors[0]
                                      : item?.planCategory == "Gold"
                                      ? colors[1]
                                      : item?.planCategory == "Platinum"
                                      ? colors[2]
                                      : "gray",
                                  //   position: "absolute",
                                  borderRadius: 30,
                                  //   bottom: 100,
                                  left: 10,
                                  top: 15,
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    color:
                                      item?.planCategory == "Platinum"
                                        ? "white"
                                        : "black",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    textAlign: "center",
                                    top: 5,
                                    position: "relative",
                                    // fontSize: 14,
                                  }}
                                >
                                  {item?.planCategory}
                                </p>
                              </div>
                            )}
                            <List list={item} type={3} />
                          </>
                        ))} */}
                      <div className="subscribe-btn" style={{ width: "100%" }}>
                        <button
                          onClick={() => {
                            // alert(JSON.stringify(planInfo?.planId));
                            // call();
                            // return;
                            if (planInfo?.hasOwnProperty("planId")) {
                              if (
                                planInfo?.planId?.planType == "OneTime" ||
                                (planInfo?.planId?.planType == "Monthly" &&
                                  planInfo?.freeSearches <= 0 &&
                                  planInfo?.totalSearch <= 0)
                              ) {
                                call();
                              } else {
                                setView1(true);
                              }
                            } else {
                              call();
                            }
                          }}
                          // onClick={call}
                          className="active Link"
                        >
                          Choose
                        </button>
                      </div>
                      <div className="subscribe-btn" style={{ width: "100%" }}>
                        <button
                          onClick={() => {
                            // alert(JSON.stringify(planInfo?.planId));
                            // call();
                            // return;
                            if (planInfo?.hasOwnProperty("planId")) {
                              if (
                                planInfo?.planId?.planType == "OneTime" ||
                                (planInfo?.planId?.planType == "Monthly" &&
                                  planInfo?.freeSearches <= 0 &&
                                  planInfo?.totalSearch <= 0)
                              ) {
                                call();
                              } else {
                                setView1(true);
                              }
                            } else {
                              call();
                            }
                          }}
                          // onClick={call}
                          className="active Link"
                        >
                          Choose
                        </button>
                      </div>
                      {showFinalPayment && (
                        <div
                          className="subscribe-btn cancel"
                          // style={{ width: "100%", backgroundColor: "white" }}
                        >
                          <button onClick={call} className="s">
                            Cancel
                          </button>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LoggedFooter />
        </div>
      )}
    </>
  );
}

export default SubscriptionPage;
