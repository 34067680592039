/** @format */

import LoggedHeader from "../../../Components/Header";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { axiosPost, axiosPut } from "../../../utils/axiosRequest";
import swal from "sweetalert";
import Loading from "../../../Components/Loading";
import { LoggedFooter } from "../../../Components/Footer";
import MobileUpdateAccount from "../../../MobilePages/menuPages/account/MobileUpdateAccount";
import { useDispatch } from "react-redux";
import { logOut } from "../../../redux/action";
function UpdatePhone() {
  const userData = JSON.parse(localStorage.getItem("loginDetails"));
  const [phone, setPhone] = useState("");
  const [oldPhone, setOldPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* mobile screen */

  const [mobileUpdatePhone, setMobileUpdatePhone] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileUpdatePhone(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileUpdatePhone(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const Id = localStorage.getItem("@loginDetails");
    if (Id === null) {
      navigate("/login-signup");
    }
  });

  function PhoneChanger(e) {
    if (!phone) {
      swal("Please enter mobile number");
      return;
    }
    e.preventDefault();
    setIsLoading(true);
    const url = `/user/change-identifier`;
    const obj = {
      mode: "phone",
      phone: phone,
      countryCode: "+1",
    };
    axiosPost(url, obj, "", (callBack) => {
      setIsLoading(false);
      if (callBack?.status === 200) {
        swal(callBack.message);
        //   navigate("/dashboard");
        //   navigate("/");
        navigate("/phoneVerificationOtp", {
          state: {
            data: callBack,
            obj: obj,
            phone: phone,
            countryCode: "+1",
            screen: "updatePhone",
          },
        });
      } else if (callBack?.status === 401) {
        swal({
          title: "Unauthorized Access Detected",
          text: callBack.message,
          showCancelButton: true,
          closeOnConfirm: false,
          animation: "slide-from-top",
          buttons: {
            confirm: {
              text: "Log out",
              className: "red-button",
            },
          },
          dangerMode: true,
        }).then(() => {
          dispatch(logOut());
          navigate("/login-signup");
        });
      } else {
        swal(callBack.message);
      }
    });
  }

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileUpdatePhone ? (
        <MobileUpdateAccount PhoneChanger={PhoneChanger} setPhone={setPhone} />
      ) : (
        <div>
          <LoggedHeader />

          <main className="login-wrapper">
            <div className="container">
              <div className="login-left moible-wrap">
                <h2>Update Phone Number</h2>
                <form onSubmit={PhoneChanger}>
                  <div className="login-content mobile-cntnt">
                    {/* <div className="login-item">
                  <label htmlFor="lg1">Old Phone</label>
                  <input
                    className="Phone"
                    type="number"
                    minLength="10"
                    maxLength="10"
                    placeholder="Old Phone"
                    onChange={(e) => setOldPhone(e.target.value)}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    required
                  />
                </div> */}

                    <div className="login-item">
                      {/* <label htmlFor="lg1">New Phone</label> */}
                      {/* <input
                    className="Phone"
                    type="number"
                    minLength="10"
                    maxLength="10"
                    id="newtel"
                    name="newtel"
                    placeholder="Enter new phone number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  /> */}

                      <div className="login-item selectCode">
                        <div className="phoneCode">
                          <option value="+1">USA (+1)</option>
                        </div>
                        <div className="phoneVerifications">
                          <input
                            type="number"
                            id="newtel"
                            placeholder="Enter new phone number"
                            name="newtel"
                            onChange={(e) => setPhone(e.target.value)}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            required
                            //   value={phone}
                            maxLength={10}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="update-btns">
                      <button type="submit"> Update New Mobile </button>
                      {/* <button
                    className="back"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    {" "}
                    BACK{" "}
                  </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>

          <LoggedFooter />
        </div>
      )}
    </>
  );
}

export default UpdatePhone;
