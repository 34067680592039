import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faLocationDot, faSliders } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import search from "../images/search.png";
import Header from "./Header";
import Arrow from "../assets/icons/left-Arrow.png"
import {
  faChevronDown,
  faCircleInfo,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import r1 from "../images/r-1.png";
import r2 from "../images/r-2.png";
import r3 from "../images/r-3.png";
import r4 from "../images/r-4.png";
import r5 from "../images/r-5.png";
import r6 from "../images/r-6.png";
import profilePic from "../images/nomugshot.png";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import AlertIcon from "../images/alert.png";
import CopyToClipboard from "react-copy-to-clipboard";
import swal from "sweetalert";

function MobileRecordPage({ state, toTitleCase, getStateName, convertInches, setViewAlert, setSearchingData }) {
  const navigate = useNavigate();

  function hide(val) {
    const div = document.getElementById(val);
    if (div.style.display === "none") {
      div.style.display = "block";
    } else {
      div.style.display = "none";
    }
  }

  return (
    <div className="login_container record_mt">
      <Header
        position={"fixed"}
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        leftButtonClick={() => navigate(-1)}
        title={"Records"}
      />
      <main className="support-wrapper">
        <div className="container login_container">
          <div className="row">
            <div className="col-lg-12">

              {(state?.criminalData?.length > 0 &&
                state?.criminalData != "No Data Found!") ||
                (state?.sexOffendedData?.length > 0 &&
                  state?.sexOffendedData != "No Data Found!") ||
                (state?.bankRuptciesData?.length > 0 &&
                  state?.bankRuptciesData != "No Data Found!") ? (
                <div>
                  <div className="alert-box">
                    <p >
                      <img src={AlertIcon} alt="" />
                      {state?.sexOffendedData?.length > 0 &&
                        state?.sexOffendedData != "No Data Found!"
                        ? "Alert! Sex Offender Record Detected "
                        : state?.bankRuptciesData?.length > 0 &&
                          state?.bankRuptciesData != "No Data Found!"
                          ? "Alert! Bankruptcies Record Detected "
                          : "Alert! Criminal Record Detected "}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="record-partmain">
                {/* Overview */}
                <div className="record-btn mobileRecord_Btn">
                  <br />
                  <a>
                    <span>
                      <img src={r1} alt="" /> Overview
                    </span>{" "}
                    <FontAwesomeIcon
                      className="recordDropDown_icon"
                      icon={faChevronDown}
                      onClick={() => hide("Ov")}
                    />
                  </a>
                </div>
                <div id="Ov" className="record-box mobileRecord_box">
                  {/* <h2> Shadab Khan</h2> */}
                  <div className="record-innner">
                    <div className="record-innrcnt">
                      <p className="profileName">
                        {toTitleCase(state?.requestObj?.FIRST_NAME)}{" "}
                        {toTitleCase(state?.requestObj?.MIDDLE_NAME)}{" "}
                        {toTitleCase(state?.requestObj?.LAST_NAME)}, {" "}
                        {state?.requestObj?.age}
                      </p>
                      <div className="profileContainer">
                        <div className="profileImg">
                          <img src={profilePic} alt="profile" />
                        </div>
                        <div className="details">
                          <h6>Aliases</h6>
                          <p>
                            {state?.aliasesData?.length > 0 &&
                              state?.aliasesData?.map((item, inde) => (
                                <>
                                  {item?.firstName} {item?.midName} {item?.lastName}
                                </>
                              ))}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="record-rightcnt record_row dob_row">
                      <div className="recordRowCol1">
                        <h6>Date of Birth</h6>
                        <p>{state?.requestObj?.formattedDob}</p>
                      </div>
                    </div>

                  </div>
                </div>


                {/* Contact Info  */}
                <div className="record-btn mobileRecord_Btn">
                  <a>
                    <span >
                      <img src={r2} alt="" className="mobile_icon" /> Contact Info
                    </span>{" "}
                    <FontAwesomeIcon
                      className="recordDropDown_icon"
                      icon={faChevronDown}
                      onClick={() => hide("Ci")}
                    />
                  </a>
                </div>
                <div id="Ci" className="record-box mobileRecord_box">
                  <h6>Most Recent Number </h6>
                  <div style={{ display: "flex", justifyContent: 'space-between', height: 'max-content' }}>
                    <p>
                      {state?.requestObj?.PHONE ? (
                        <p>{state?.requestObj?.PHONE}</p>
                      ) : (
                        <>
                          {state?.phone && (
                            <p>{state?.phone.length > 0 && state?.phone[0]}</p>
                          )}
                        </>
                      )}
                    </p>
                    {
                      (state?.requestObj?.PHONE || (state?.phone.length > 0 && state?.phone[0])) &&
                      <CopyToClipboard
                        text={`${state?.requestObj?.PHONE || (state?.phone.length > 0 && state?.phone[0])}`}
                        onCopy={() => swal('Copied !')}>
                        {/* <Image src={copy} alt="Copy email address" width={12} height={12} className="copytoclipimg ml-2" /> */}
                        <FontAwesomeIcon
                          icon={faCopy}
                          className="fontAwesomeIcon_img"
                          style={{ color: "gray", width: "15px", marginRight: "5px" }}
                        />
                      </CopyToClipboard>
                    }
                  </div>
                  <h6>Other Numbers</h6>
                  <p>
                    {state?.phone?.map((item, index) => (
                      <>
                        {state?.requestObj?.PHONE &&
                          item != state?.requestObj?.PHONE && <p>{item}</p>}
                      </>
                    ))}
                  </p>
                  <br />
                  <h6>Most Recent Email </h6>
                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <p>
                      {state?.requestObj?.EMAIL ? (
                        <p>{state?.requestObj?.EMAIL}</p>
                      ) : (
                        <>
                          {state?.email && (
                            <p>{state?.email.length > 0 && state?.email[0]}</p>
                          )}
                        </>
                      )}
                    </p>
                    {
                      (state?.requestObj?.EMAIL || (state?.email.length > 0 && state?.email[0])) &&
                      <CopyToClipboard
                        text={`${state?.requestObj?.EMAIL || (state?.email.length > 0 && state?.email[0])}`}
                        onCopy={() => swal("Copied !")}>
                        {/* <Image src={copy} alt="Copy email address" width={12} height={12} className="copytoclipimg ml-2" /> */}
                        <FontAwesomeIcon
                          icon={faCopy}
                          className="fontAwesomeIcon_img"
                          style={{ color: "gray", width: "15px", marginRight: "5px" }}
                        />
                      </CopyToClipboard>
                    }
                  </div>
                  <h6>Other Emails</h6>
                  <p>
                    {state?.email?.map((item, index) => (
                      <>
                        {!state?.requestObj?.EMAIL ? (
                          <>{index != 0 && <p>{item}</p>}</>
                        ) : (
                          <p>{item}</p>
                        )}
                      </>
                    ))}
                  </p>
                </div>

                {/* Address Info */}
                <div className="record-btn mobileRecord_Btn">
                  <a>
                    <span>
                      <img className="home_icon" src={r3} alt="" /> Address Info
                    </span>{" "}
                    <FontAwesomeIcon
                      className="recordDropDown_icon"
                      icon={faChevronDown}
                      onClick={() => hide("Ai")}
                    />
                  </a>
                </div>
                <div id="Ai" className="record-box mobileRecord_box">
                  <h6>Most Recent Address </h6>
                  <p>
                    {state?.requestObj?.ADDRESS}
                    <br />
                    {state?.requestObj?.CITY}
                    {state?.requestObj?.STATE
                      ? ", " + getStateName(state?.requestObj?.STATE)
                      : ""}
                  </p>
                  <hr />
                  <h6>Past Addresses</h6>
                  <>
                    {state?.address?.map((item, index) => (
                      <>
                        <h4>{item?.address}</h4>
                        {/* <br /> */}
                        <h4>
                          {item?.city} ,{getStateName(item?.state)}
                        </h4>
                        <h4>{item?.zip ? item?.zip + "\n" : ""}</h4>
                        <br />
                        <br />
                      </>
                    ))}
                  </>
                </div>

                {/* Potential Association */}
                <div className="record-btn mobileRecord_Btn">
                  <a>
                    <span>
                      <img className="potential_icon" src={r4} alt="" /> Potential Associates
                    </span>{" "}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      onClick={() => hide("pA")}
                    />
                  </a>
                </div>



                {state?.potientialAssociate?.length > 0 && (
                  <div>
                    {state?.potientialAssociate?.map((items, index) => (
                      <div
                        className="recent-serchbox"
                        style={{ textAlign: "left", height: 120 }}

                        onClick={() => {
                          setViewAlert(true);
                          var item = items?._source;
                          item.age = items?.age;
                          item.formattedDob = items?.formattedDob;
                          var d0b = items?._index.includes("dob");
                          if (d0b) {
                            item.index = "dob";
                          } else {
                            item.index = "email";
                          }
                          setSearchingData({
                            state: {
                              //   data: callBack.data,
                              //   inputState: state,
                              //   inputName: name,
                              metaBody: items,
                              research: true,
                              //   research: true,
                              //   firstName,
                              //   lastName,
                              //   state,
                              //   email,
                              //   phone,
                              //   street,
                              //   city,
                            },
                          });
                          // alert(JSON.stringify(items));
                          // console.log(
                          //   "🚀 ~ file: RecordDisplay.jsx:575 ~ RecordDisplay ~ JSON.stringify(items):",
                          //   JSON.stringify(items)
                          // );
                          // setState(items);
                          // navigate("/result-page", {
                          //   research: true,
                          //   obj: items,
                          // });
                        }}

















                      // onClick={() => {
                      //   setViewAlert(true);
                      //   var item = items?._source;
                      //   item.age = items?.age;
                      //   item.formattedDob = items?.formattedDob;
                      //   var d0b = items?._index.includes("dob");
                      //   if (d0b) {
                      //     item.index = "dob";
                      //   } else {
                      //     item.index = "email";
                      //   }
                      //   setSearchingData({
                      //     state: {
                      //       //   data: callBack.data,
                      //       //   inputState: state,
                      //       //   inputName: name,
                      //       metaBody: items,
                      //       research: true,
                      //       //   research: true,
                      //       //   firstName,
                      //       //   lastName,
                      //       //   state,
                      //       //   email,
                      //       //   phone,
                      //       //   street,
                      //       //   city,
                      //     },
                      //   });
                      //   // alert(JSON.stringify(items));
                      //   // console.log(
                      //   //   "🚀 ~ file: RecordDisplay.jsx:575 ~ RecordDisplay ~ JSON.stringify(items):",
                      //   //   JSON.stringify(items)
                      //   // );
                      //   // setState(items);
                      //   // navigate("/result-page", {
                      //   //   research: true,
                      //   //   obj: items,
                      //   // });
                      // }}
                      >
                        <div>
                          <h6>
                            {toTitleCase(items?._source?.FIRST_NAME)}{" "}
                            {toTitleCase(items?._source?.LAST_NAME)}
                            {""}
                            {items?.age && ", " + items?.age}
                          </h6>

                          <Link>
                            <h5
                              style={{
                                color: "#5979AE",
                                border: "1px solid blue",
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                paddingVertical: 10,
                                width: 200,
                              }}
                            >
                              Scan this Person
                            </h5>
                          </Link>
                        </div>
                      </div>
                    ))}
                    <br />
                  </div>
                )}


                {/* Criminal Records */}
                <div className="record-btn mobileRecord_Btn">
                  <a>
                    <span>
                      <img className="criminal_icon" src={r5} alt="" /> Criminal Records
                    </span>{" "}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      onClick={() => hide("cR")}
                    />
                  </a>
                </div>

                {/* <div id="cR"  style={{ textAlign: "center" }} className="record-box mobileRecord_box"> */}




                {state?.criminalData?.length > 0 &&
                  state?.criminalData != "No Data Found!" && (

                    <div>


                      {
                        // state?.criminalData?.map((item, index) => (
                        state?.criminalData?.map((item, index) => (
                          <>
                            <div id="cR" style={{ textAlign: "center" }} className="record-box mobileRecord_box">

                              <div
                                className="recordCriminal_Data"
                                style={{ textAlign: "left" }}
                              >
                                <div className="recent-srcnt">
                                  <div className="sourceState_Btn"   >
                                    {item?._source?.sourceState
                                      ? getStateName(item?._source?.sourceState)
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: 2 }}>
                                    {item?._source?.OffenseDate && (
                                      <>
                                        <>
                                          <h6>
                                            Offense Date:
                                          </h6>
                                          <p style={{ color: "black" }}>
                                            {item?._source?.OffenseDate &&
                                              moment(
                                                item?._source?.OffenseDate
                                              ).format("DD/MM/YYYY")}{" "}
                                            {"\n"}
                                          </p>
                                        </>
                                      </>
                                    )}

                                    {item?._source?.Category && (
                                      <>
                                        <h6>
                                          Offense Category:
                                        </h6>
                                        <p style={{ color: "black" }}>
                                          {toTitleCase(item?._source?.Category)}
                                          {"\n"}
                                        </p>
                                        <br />
                                      </>
                                    )}

                                    {item?._source?.OffenseDesc1 && (
                                      <>
                                        <h6>
                                          Offense Description:
                                        </h6>
                                        <p
                                        // style={{ ...styles.detailText }}
                                        >
                                          {toTitleCase(
                                            item?._source?.OffenseDesc1
                                          )}
                                          {"\n"}
                                        </p>
                                        <br />
                                      </>
                                    )}
                                    {item?._source?.Plea && (
                                      <>
                                        <h6>Plea:</h6>
                                        <p style={{ color: "black" }}>
                                          {toTitleCase(item?._source?.Plea)}
                                          {"\n"}
                                        </p>
                                        <br />
                                      </>
                                    )}

                                    {item?._source?.Court && (
                                      <>
                                        <h6>Court:</h6>
                                        <p style={{ color: "black" }}>
                                          {toTitleCase(item?._source?.Court)}
                                          {"\n"}
                                        </p>
                                        <br />
                                      </>
                                    )}

                                    {item?._source?.sourceState && (
                                      <>
                                        <h6>State:</h6>
                                        <p style={{ color: "black" }}>
                                          {getStateName(
                                            item?._source?.sourceState
                                          )}
                                          {"\n"}
                                        </p>
                                        <br />
                                      </>
                                    )}

                                    {item?._source?.Disposition && (
                                      <>
                                        <h6>
                                          Disposition:
                                        </h6>
                                        <p style={{ color: "black" }}>
                                          {toTitleCase(
                                            item?._source?.Disposition
                                          )}
                                          {"\n"}
                                        </p>
                                        <br />
                                      </>
                                    )}

                                    {item?._source?.DispositionDate && (
                                      <>
                                        <h6>
                                          Disposition Date:
                                        </h6>
                                        <p style={{ color: "black" }}>
                                          {moment(
                                            item?._source?.DispositionDate,
                                            "YYYYMMDD"
                                          ).format("MM/DD/YYYY")}
                                          {"\n"}
                                        </p>
                                        <br />
                                      </>
                                    )}

                                    {item?._source?.sourceName && (
                                      <>
                                        <h6>Source:</h6>
                                        <p style={{ color: "black" }}>
                                          {toTitleCase(item?._source?.sourceName)}
                                          {"\n"}
                                        </p>
                                      </>
                                    )}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      }




                      <br />
                    </div>
                  )}


                {/* </div> */}


                {/* Sex Offender */}
                <div className="record-btn mobileRecord_Btn">
                  <a>
                    <span>
                      <img className="criminal_icon" src={r5} alt="" /> Sex Offender
                    </span>{" "}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      onClick={() => hide("sEx")}
                    />
                  </a>
                </div>

                {/* <div id="sEx" className="record-box mobileRecord_box"> */}
                {/* <h4> Sex Offender</h4> */}
                {state?.sexOffendedData?.length > 0 &&
                  state?.sexOffendedData != "No Data Found!" && (
                    <div>
                      {state?.sexOffendedData?.map((item, index) => (
                        <div id="sEx" className="record-box mobileRecord_box">
                          <div
                            className="recent-serchbox1"
                            style={{ textAlign: "left" }}
                          >
                            <div className="recent-srcnt">
                              <div
                                style={{
                                  width: 100,
                                  height: 22,
                                  marginTop: 10,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#466BA4",
                                  borderRadius: 5,
                                  display: "flex",
                                  color: "white",
                                }}
                              >
                                {item?._source?.SOURCE
                                  ? getStateName(item?._source?.SOURCE)
                                  : "-"}
                              </div>
                              {<br />}
                              <div>
                                <div>
                                  {item?._source?.RISKLEVEL && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Risk Level:
                                      </h5>
                                      <p>
                                        {item?._source?.RISKLEVEL &&
                                          item?._source?.RISKLEVEL}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}

                                  {item?._source?.COUNTY && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        County:
                                      </h5>
                                      <p>
                                        {item?._source?.COUNTY &&
                                          item?._source?.COUNTY}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.HAIRCOLOR && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Hair Color:
                                      </h5>
                                      <p>
                                        {item?._source?.HAIRCOLOR &&
                                          item?._source?.HAIRCOLOR}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.EYECOLOR && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Eye Color:
                                      </h5>
                                      <p>
                                        {item?._source?.EYECOLOR &&
                                          item?._source?.EYECOLOR}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.HEIGHT && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Height:
                                      </h5>
                                      <p>
                                        {item?._source?.HEIGHT &&
                                          convertInches(
                                            item?._source?.HEIGHT
                                          )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.WEIGHT && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Weight:
                                      </h5>
                                      <p>
                                        {item?._source?.WEIGHT}
                                        {/* {item?._source?.WEIGHT &&
                                            convertInches(
                                              item?._source?.WEIGHT
                                            )} */}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.RACE && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Race:
                                      </h5>
                                      <p>
                                        {item?._source?.RACE &&
                                          toTitleCase(item?._source?.RACE)}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.GENDER && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Gender:
                                      </h5>
                                      <p>
                                        {item?._source?.GENDER &&
                                          toTitleCase(item?._source?.GENDER)}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.CASENUMBER && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Case Number:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.CASENUMBER
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.OFFENSEDAT && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        File Date:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.OFFENSEDAT
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.OFFENSEDAT && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Case Type:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.OFFENDERCA
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.OFFENSEDES && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Statue:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.OFFENSEDES
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.OFFENSECOD && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Statue Code:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.OFFENSECOD
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.CONVICTION && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Conviction:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.CONVICTION
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}

                                  {item?._source?.CONV_PLACE && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Place of Conviction:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.CONV_PLACE
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                  {item?._source?.VICTIMSAGE && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Victim's Age:
                                      </h5>
                                      <p>
                                        {toTitleCase(
                                          item?._source?.VICTIMSAGE
                                        )}
                                        {"\n"}
                                      </p>
                                    </>
                                  )}
                                </div>

                                {/* {item?._source?.HAIRCOLOR && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Hair Color:
                                      </h5>
                                      <p>
                                        {item?._source?.HAIRCOLOR &&
                                          item?._source?.HAIRCOLOR}
                                        {"\n"}
                                      </p>
                                    </>
                                  )} */}
                              </div>
                              <div style={{ marginTop: 2 }}>
                                {item?._source?.OffenseDate && (
                                  <>
                                    <p
                                    // style={{
                                    //   ...styles.fullname,
                                    //   marginTop: 8,
                                    // }}
                                    >
                                      Offense Date:
                                    </p>
                                    <p style={{ color: "black" }}>
                                      {item?._source?.OffenseDate &&
                                        moment(
                                          item?._source?.OffenseDate
                                        ).format("DD/MM/YYYY")}{" "}
                                      {"\n"}
                                    </p>
                                  </>
                                )}
                                {item?._source?.Category && (
                                  <>
                                    <p style={{ fontSize: 25 }}>
                                      Offense Category:
                                    </p>
                                    <p style={{ color: "black" }}>
                                      {toTitleCase(item?._source?.Category)}
                                      {"\n"}
                                    </p>
                                    <br />
                                  </>
                                )}
                                {item?._source?.OffenseDesc1 && (
                                  <>
                                    <p style={{ fontSize: 25 }}>
                                      Offense Description:
                                    </p>
                                    <p
                                    // style={{ ...styles.detailText }}
                                    >
                                      {toTitleCase(
                                        item?._source?.OffenseDesc1
                                      )}
                                      {"\n"}
                                    </p>
                                    <br />
                                  </>
                                )}
                                {item?._source?.Plea && (
                                  <>
                                    <p style={{ fontSize: 25 }}>Plea:</p>
                                    <p style={{ color: "black" }}>
                                      {toTitleCase(item?._source?.Plea)}
                                      {"\n"}
                                    </p>
                                    <br />
                                  </>
                                )}

                                {item?._source?.Court && (
                                  <>
                                    <p style={{ fontSize: 25 }}>Court:</p>
                                    <p style={{ color: "black" }}>
                                      {toTitleCase(item?._source?.Court)}
                                      {"\n"}
                                    </p>
                                    <br />
                                  </>
                                )}

                                {item?._source?.sourceState && (
                                  <>
                                    <p style={{ fontSize: 25 }}>State:</p>
                                    <p style={{ color: "black" }}>
                                      {getStateName(
                                        item?._source?.sourceState
                                      )}
                                      {"\n"}
                                    </p>
                                    <br />
                                  </>
                                )}

                                {item?._source?.Disposition && (
                                  <>
                                    <p style={{ fontSize: 25 }}>
                                      Disposition:
                                    </p>
                                    <p style={{ color: "black" }}>
                                      {toTitleCase(
                                        item?._source?.Disposition
                                      )}
                                      {"\n"}
                                    </p>
                                    <br />
                                  </>
                                )}

                                {item?._source?.DispositionDate && (
                                  <>
                                    <p style={{ fontSize: 25 }}>
                                      Disposition Date:
                                    </p>
                                    <p style={{ color: "black" }}>
                                      {moment(
                                        item?._source?.DispositionDate,
                                        "YYYYMMDD"
                                      ).format("MM/DD/YYYY")}
                                      {"\n"}
                                    </p>
                                    <br />
                                  </>
                                )}

                                {item?._source?.sourceName && (
                                  <>
                                    <p style={{ fontSize: 25 }}>Source:</p>
                                    <p style={{ color: "black" }}>
                                      {toTitleCase(item?._source?.sourceName)}
                                      {"\n"}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <br />
                    </div>
                  )}
                {/* </div> */}


                {/* Bankruptcies */}
                <div className="record-btn mobileRecord_Btn">
                  <a>
                    <span>
                      <img className="criminal_icon" src={r6} alt="" /> Bankruptcies
                    </span>{" "}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      onClick={() => hide("BK")}
                    />
                  </a>
                </div>
                {/* <div id="BK" className="record-box mobileRecord_box">
                  <h4> Bankruptcies </h4>
                </div> */}
                {/* <div className="recbox2" >
                  <div className="record-box norecbox">
                    <p> "No Record Found"</p>
                  </div>
                </div> */}


                {/* Disclaimer */}
                <div className="record-btn mobileRecord_Btn">
                  <a>
                    <span>
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        onClick={() => hide("Dl")}
                        style={{ marginRight: 5 }}
                      />
                      Disclaimer
                    </span>{" "}
                  </a>
                </div>

                <div id="Dl" className="recent-serchbox1 mobileRecord_box" style={{ textAlign: "left" }}>
                  <div className="recent-srcnt Disclaimer_text">
                    Our service gathers public records, including criminal
                    records from government agencies, affiliates, and third
                    parties. However, as we are not the creators of these
                    databases, we cannot ensure the accuracy of the information
                    or attest to the person's character.
                    <br />
                    <br />
                    Court records, which are publicly accessible through
                    government agencies, may contain criminal details such as
                    felonies, misdemeanors, arrests, or infractions.
                    <br />
                    <br />
                    Nevertheless, these public records can be unreliable,
                    incomplete, or unrelated to the individual in question. It
                    is crucial to independently verify a person's criminal
                    history at the appropriate courthouse and not solely depend
                    on the information provided here.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
    </div>


  );
}

export default MobileRecordPage;
