import {
  LOGIN_DETAILS,
  LOG_OUT,
  SEARCH_DETAILS,
  META_BODY,
} from "./actionType";
import { EmptyStorage } from "../utils/AsyncStorage";

const initialState = {
  loginDetails: {},
  searchDetails: {},
  metaBody: {},
};
export const mainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_DETAILS:
      return { ...state, loginDetails: payload };
    case SEARCH_DETAILS:
      return { ...state, searchDetails: payload };
    case META_BODY:
      return { ...state, metaBody: payload };
    case LOG_OUT: {
      EmptyStorage();
      // localStorage.clear();
      return { loginDetails: {} };
    }
    default:
      return state;
  }
};
