import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Header from "../../MobileComponents/Header";
import { useNavigate } from "react-router-dom";

function SignUpOTP({ count, resendOtp, verify, setOtp, otp }) {
  const navigate = useNavigate();
  // const [otp, setOtp] = useState("");

  function OtpSendFun() {
  }

  return (
    <div className="login_container">
      <Header
        height={""}
        padding={""}
        title={"Email Verification"}
        buttonSrc={"Cancel"}
        leftButtonClick={() => navigate(-1)}
      />
      <main className="OTP-wrapper">
        <div className="container">
          <form onSubmit={(e) => verify(e)}>
            <div className="row">
              <div className="col-lg-12 col-md-12 OtpVerify">
                <div className="login-left pe-lg-5 me-xl-5">
                  <div className="login-content">
                    <div className="Otp_title_container">
                      <p className="Otp_title">
                        Please check your message and enter the security code
                        below
                      </p>
                    </div>
                    <div className="otp_code_container">
                      <OtpInput
                        inputStyle={{
                          width: "43px",
                          height: "55px",
                          margin: "0 5px",
                          fontSize: "18px",
                          borderRadius: "5px",
                          border: "none",
                          outline: "none",
                          backgroundColor: "#eaebee",
                          textAlign: "center",
                          transition: "background-color 0.3s",
                          // color:'red'
                        }}
                        //   inputClassName="otp-input"
                        // inputFocusStyle={{
                        //   backgroundColor: "red ! important",
                        // }}
                        value={otp}
                        onChange={setOtp}
                        // onChange={setOtp}
                        numInputs={6}
                        //   renderSeparator={<span>-</span>}
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="Otp_title_container">
                      <p className="Otp_title count_down_mb" id="time">
                        {count > 0 ? (
                          <>
                            Didn't recieve the SMS Code? You can request a new
                            one in{" "}
                            <span style={{ color: "red" }}>{count} second</span>{" "}
                          </>
                        ) : (
                          <div onClick={resendOtp}>Resend</div>
                        )}
                      </p>
                    </div>

                    <div
                      className="login-item otpConfirm_Btn"
                      // onClick={OtpSendFun}
                    >
                      <button type="submit">Confirm</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

export default SignUpOTP;
