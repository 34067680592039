/** @format */

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { SigninFooter } from "../../../Components/Footer";
import { SigninHeader } from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import { axiosGet, axiosPost } from "../../../utils/axiosRequest";
import { useLocation } from "react-router-dom";
import SignUpOTP from "../../../MobilePages/Auth/SignUpOTP";

function EmailOtpVerification(props) {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(60);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [mobOtpVerification, setMobOtpVerification] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobOtpVerification(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobOtpVerification(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // const Id = localStorage.id;

    if (!state?.hasOwnProperty("data")) {
      navigate("/login-signup");
    }

    var timer = setTimeout(update, 1000);

    function update() {
      for (var i = count; i > 0; i--) {
        // document.getElementById("time").style.color = "red";
        setCount(count - 1);
        setTimeout(1000);
      }
    }

    if (count === 0) {
      setCount("");
      document.getElementById("time").style.color = "blue";
    }
  });

  function verify(e) {
    e.preventDefault();
    const id = localStorage.id;
    setIsLoading(true);
    const url = "/user/verify-otp";
    const obj = {
      device: "web",
      deviceInfo: {},
      code: otp,
      purpose: "registration",
    };
    var token = state?.data?.authToken;
    axiosPost(url, obj, token, (callBack) => {
      if (callBack?.status === 200) {
        //console.log(callBack);
        // axiosGet(`/user/${id}`, (callBack) => {
        //   //console.log(callBack);
        //   const email = callBack.data.data.email;
        //   localStorage.setItem("email", email);
        // });
        // localStorage.setItem("url", url);
        setIsLoading(false);
        swal(callBack.message);
        navigate("/phoneVerification", {
          replace: true,
          state: {
            data: callBack?.data,
            obj: obj,
          },
        });
      } else {
        setIsLoading(false);
        swal(callBack.message);
      }
    });
  }

  function resendOtp() {
    // const url = `/user/resendCode?email=${location.state.email}&type=email&purpose=email`;
    const url = `/user/resend-otp`;
    var obj = {
      device: "web",
      deviceInfo: {},
      mode: "email",
      purpose: "registration",
      email: state?.email,
    };
    axiosPost(url, obj, "", (callBack) => {
      if (callBack?.status === 200) {
        swal(callBack.data);
        setCount(60);
      } else {
        swal(callBack.message);
      }
    });
  }

  function Sec() {
    if (count > 0) {
      return " seconds";
    } else {
      return (
        <span onClick={resendOtp} style={{ cursor: "pointer" }}>
          Resend
        </span>
      );
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobOtpVerification ? (
        <SignUpOTP
          count={count}
          resendOtp={resendOtp}
          verify={verify}
          setOtp={setOtp}
          otp={otp}
        />
      ) : (
        <div>
          <SigninHeader />

          <main className="login-wrapper">
            <div className="container">
              <div className="login-left moible-wrap">
                <h2>OTP Send Successfully</h2>
                <p>We send you an OTP to your Email</p>
                <form onSubmit={(e) => verify(e)}>
                  <div className="login-content mobile-cntnt">
                    <div className="login-item">
                      <label htmlFor="lg1">Enter OTP code below</label>
                      <input
                        type="number"
                        id="lg1"
                        placeholder="OTP"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 6);
                        }}
                        name="otp"
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      />
                      <p
                        style={{
                          fontSize: "15px",
                          padding: "10px 18px",
                          textAlign: "center",
                        }}
                      >
                        Didn't receive the OTP email? You can request a new one
                        in{" "}
                        <span id="time" style={{ color: "red" }}>
                          {count}
                          <Sec />
                        </span>
                      </p>
                    </div>

                    <div className="login-item">
                      <button type="submit">Verify</button>
                    </div>

                    <div className="login-links">
                      <p>
                        Already have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Login here
                        </Link>
                      </p>
                      <p>
                        Don’t have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Signup here
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>

          <SigninFooter />
        </div>
      )}
    </>
  );
}

export default EmailOtpVerification;
