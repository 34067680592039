import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import search from "../images/search.png";

const history = [
  {
    name: "SCOTT",
    titlename: "Pores",
  },
  {
    name: "Kavin",
    titlename: "Sharma",
  },
];

function RecentSearches() {
  const navigate = useNavigate();
  // const [history, setHistory] = useState([]);

  return (
    <div>
      <div className="login-item home_recentSearch">
        <div className="recent_search">
          <button className="recent_search_btn" type="submit">
            RECENT SEARCHES
          </button>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        {history.map((item, index) => (
          <div
            className="recent-serchbox pointer"
            style={{ textAlign: "left" }}
            onClick={() => {
              "";
            }}
          >
            <div className="recent-srcnt">
              <h4>People Search</h4>
              <p>{item.name}</p>
            </div>
            <img src={search} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecentSearches;
