/** @format */

import logo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/action";
import { useEffect, useState } from "react";

function LoggedHeader() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [view, setView] = useState(false);

  function Warning() {
    return (
      <div id="alerts">
        <div className="alert">
          <h4>Do want to log out?</h4>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView(false)}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button className="login-btn loginactive Link" onClick={logingout}>
              <h5 style={{ margin: "0px" }}>Yes</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }

  function logingout() {
    dispatch(logOut());
    navigate("/");
  }

  return (
    <header className="header-area">
      {view ? <Warning /> : false}
      <div className="container">
        <div className="header-main">
          <div className="header-logo">
            <div className="navbar-brand">
              <img
                src={logo}
                alt=""
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
            </div>
          </div>

          <ul className="navbar-nav">
            <li className="logout">
              {" "}
              <button
                className="login-btn loginactive Link"
                onClick={() => setView(true)}
                style={{ padding: "7px 30px" }}
              >
                {" "}
                Log Out{" "}
              </button>{" "}
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export function Header() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function toggle(name) {
    const element = document.getElementById(name);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function menu() {
    const element = document.getElementById("none-menu");

    if (element.style.display === "block") {
      element.style.display = "none";
    } else {
      element.style.display = "block";
    }
  }

  return (
    <header className="header-area" style={{ position: "sticky" }}>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="navbar-brand">
            <img src={logo} alt="" />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <span onClick={menu} className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto align-items-center">
              <li>
                <a id="active" onClick={() => window.scrollTo(0, 0)}>
                  Home
                </a>
              </li>
              <li>
                <a onClick={() => toggle("about")}>About</a>
              </li>
              <li>
                <a onClick={() => toggle("freeSearch")}>Free Search</a>
              </li>
              <li>
                <a onClick={() => toggle("pricing")}>Pricing</a>
              </li>
              <li>
                <a href="mailto:hi@friendverifier.com">Contact</a>
              </li>
              <li>
                <Link to="/login-signup" className="login-btn loginactive Link">
                  Login / Sign Up
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="none-menu" id="none-menu">
        <ul className="navbar-nav">
          <li>
            <a id="active" onClick={() => window.scrollTo(0, 0)}>
              Home
            </a>
          </li>
          <li>
            <a onClick={() => toggle("about")}>About</a>
          </li>
          <li>
            <a onClick={() => toggle("freeSearch")}>Free Search</a>
          </li>
          <li>
            <a onClick={() => toggle("pricing")}>Pricing</a>
          </li>
          {/* <li>
            <a onClick={() => toggle("contact")}>Contact</a>
          </li> */}
          <li>
            {" "}
            <Link className="login-btn loginactive Link" to="/login-signup">
              {" "}
              Login / Sign Up{" "}
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}
export function LoggedHomeHeader() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function toggle(name) {
    const element = document.getElementById(name);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function menu() {
    const element = document.getElementById("none-menu");
    if (element.style.display === "block") {
      element.style.display = "none";
    } else {
      element.style.display = "block";
    }
  }

  return (
    <header className="header-area">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="navbar-brand">
            <img
              src={logo}
              alt=""
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <span onClick={menu} className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto align-items-center">
              <li>
                <a href="/" id="active">
                  Home
                </a>
              </li>
              <li>
                <a onClick={() => toggle("about")}>About</a>
              </li>
              <li>
                <a onClick={() => toggle("freeSearch")}>Free Search</a>
              </li>
              <li>
                <a onClick={() => toggle("pricing")}>Pricing</a>
              </li>
              {/* <li>
                <a onClick={() => toggle("contact")}>Contact</a>
              </li> */}
              <li>
                <Link to="/dashboard" className="login-btn loginactive Link">
                  Dashboard
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="none-menu" id="none-menu">
        <ul className="navbar-nav">
          <li>
            <a href="/" id="active">
              Home
            </a>
          </li>
          <li>
            <a onClick={() => toggle("about")}>About</a>
          </li>
          <li>
            <a onClick={() => toggle("freeSearch")}>Free Search</a>
          </li>
          <li>
            <a onClick={() => toggle("pricing")}>Pricing</a>
          </li>
          {/* <li>
            <a onClick={() => toggle("contact")}>Contact</a>
          </li> */}
          <li>
            {" "}
            <Link className="login-btn loginactive Link" to="/dashboard">
              {" "}
              Dashboard{" "}
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export function SigninHeader() {
  // useEffect(() => {
  //     window.scrollTo(0, 0)
  // }, [])

  const navigate = useNavigate();

  return (
    <header className="header-area">
      <div className="container">
        <div className="header-main">
          <div className="header-logo">
            <div className="navbar-brand">
              <img
                src={logo}
                alt=""
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
          </div>

          <ul className="navbar-nav">
            <li className="logout">
              <Link
                className="login-btn loginactive Link"
                to="/"
                style={{ padding: "7px 30px" }}
              >
                {" "}
                Home{" "}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export function ContactHeader() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function menu() {
    const element = document.getElementById("none-menu");
    if (element.style.display === "block") {
      element.style.display = "none";
    } else {
      element.style.display = "block";
    }
  }

  return (
    <header className="header-area">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="navbar-brand" href="#">
            <img
              src={logo}
              alt=""
              onClick={() => {
                window.history.back();
              }}
            />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <span onClick={menu} className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto align-items-center">
              <li>
                <Link to="/dos">Do’s &amp; Don’ts </Link>
              </li>
              <li>
                <Link to="/termsandconditions">Terms of Use </Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy </Link>
              </li>
              <li>
                <Link to="/do-not-sell">Do Not Sell My Info</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="none-menu" id="none-menu">
        <ul className="navbar-nav">
          <li>
            <Link to="/dos">Do’s &amp; Don’ts </Link>
          </li>
          <li>
            <Link to="/termsandconditions">Terms of Use </Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy </Link>
          </li>
          <li>
            <Link to="/do-not-sell">Do Not Sell My Info</Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default LoggedHeader;
