/** @format */

import React from "react";
import { useState, useEffect } from "react";
import "../../style.css";
import { SigninFooter } from "../../Components/Footer";
import { SigninHeader } from "../../Components/Header";
import { loginDetails } from "../../redux/action";
import { useDispatch } from "react-redux";
import { axiosPost } from "../../utils/axiosRequest";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../../Components/Loading";
import { SaveStorageItems } from "../../utils/AsyncStorage";
import EmailOtpVerification from "./Signup/EmailOtp";
import SignUp from "../../MobilePages/Auth/SignUp";
import Login from "../../MobilePages/Auth/Login";
// import { useMediaQuery } from "react-responsive";

//Main Function

function LoginSignup() {
  const dispatch = useDispatch();

  {
    /* mobile screen */
  }
  const [mobileLogin, setMobileLogin] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileLogin(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileLogin(window.innerWidth <= 575);
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // const[loginEmail, setLoginEmail] = useState('');
  // const[loginPassword, setLoginPassword] = useState('');
  // const[registerEmail, setRegisterEmail] = useState('');
  // const[registerFirstName, setRegisterFirstName] = useState('');
  // const[registerLastName, setRegisterLastName] = useState('');
  // const[registerPassword, setRegisterPassword] = useState('');
  // const[registerReferallId, setRegisterReferallId] = useState('');

  //Login Function

  function loginHandler(e) {
    e.preventDefault();
    setIsLoading(true);
    const loginPassword = document.getElementById("loginPassword").value;
    const loginEmail = document
      .getElementById("loginEmail")
      .value.toLowerCase();
    const url = "/user/login";
    const obj = {
      email: loginEmail,
      password: loginPassword,
      device: "web",
      deviceInfo: {},
    };
    console.log(obj);
    axiosPost(url, obj, "", (callBack) => {
      if (callBack?.status === 200) {
        if (callBack?.data?.user?.registrationStatus == "verifyEmail") {
          navigate("/otpVerification", {
            replace: true,
            state: {
              data: callBack?.data,
              obj: obj,
              screen: "email",
            },
          });
        } else if (callBack?.data?.user?.registrationStatus == "getPhoneNo") {
          navigate("/phoneVerification", {
            replace: true,
            state: {
              data: callBack?.data,
              obj: obj,
              screen: "email",
            },
          });
        } else if (callBack?.data?.user?.registrationStatus == "verifyPhone") {
          navigate("/phoneVerificationOtp", {
            replace: true,
            state: {
              data: callBack?.data,
              obj: obj,
              screen: "email",
            },
          });
        } else if (
          callBack?.data?.user?.registrationStatus == "registerTerms"
        ) {
          navigate("/termsandservice", {
            replace: true,
            state: {
              data: callBack?.data,
            },
          });
        } else if (callBack?.data?.user?.registrationStatus == "registered") {
          SaveStorageItems(
            "@loginDetails",
            JSON.stringify(callBack.data),
            0,
            () => {
              dispatch(loginDetails({ payload: callBack?.data }));
              navigate("/dashboard"); // Navigating to the next screen
            }
          );
        }
        setIsLoading(false);
        return;

        SaveStorageItems(
          "loginDetails",
          JSON.stringify(callBack.data.data),
          0,
          () => {
            dispatch(loginDetails({ payload: callBack?.data.data }));
            localStorage.setItem("id", callBack.data.data._id);
            localStorage.setItem("tokken", callBack.data.Authorization);
            localStorage.setItem(
              "loginCred",
              JSON.stringify({ email: loginEmail, password: loginPassword })
            );
            setIsLoading(false);
            navigate("/dashboard");
            window.location.reload();
          }
        );
      } else {
        setIsLoading(false);
        alert(callBack.message);
      }
    });
  }

  //Forget Password Function

  function forgetHandler() {
    navigate("/verify-forget-email");
  }

  //Signup Function

  function registerHandler(e) {
    console.log("registerHandler", e);
    setIsLoading(true);
    e.preventDefault();
    const registerFirstName = document.getElementById("sf").value;
    const registerLastName = document.getElementById("sl").value;
    var registerEmail = document.getElementById("se").value.toLowerCase();
    var registerCNFEmail = document.getElementById("sce").value.toLowerCase();
    const registerPassword = document.getElementById("sp").value;
    if (registerCNFEmail != registerEmail) {
      setIsLoading(false);
      swal("Email and Confirm email did not matched.");
      return;
    }

    const confirmPass = document.getElementById("password").value;
    const url = "/user/register-email";
    const obj = {
      email: registerEmail,
      fullName: registerFirstName + " " + registerLastName,
      firstName: registerFirstName,
      lastName: registerLastName,
      password: registerPassword,
      mode: "email",
      device: "web",
      deviceInfo: {},
    };
    //console.log(obj);
    if (confirmPass === registerPassword) {
      var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;
      // if (pattern.test(registerPassword)) {
      axiosPost(url, obj, "", (callBack) => {
        setIsLoading(false);
        if (callBack?.status === 200) {
          const step = callBack.data.step;
          console.log("callBack........", callBack);
          localStorage.setItem("id", callBack.data.userId);
          if (step === "step2") {
            swal(callBack.message);
            setIsLoading(false);
            navigate("/phoneVerification");
          } else {
            //console.log(callBack);
            localStorage.setItem(
              "@loginDetails",
              JSON.stringify({ isSignedUp: true })
            );
            setIsLoading(false);
            // swal(callBack.message);
            navigate("/phoneVerification", {
              replace: true,
              state: {
                email: registerEmail,
                data: callBack?.data,
                obj: obj,
                screen: "email",
              },
            });
          }
        } else {
          setIsLoading(false);
          swal(callBack.message);
        }
      });
      // } else {
      //   setIsLoading(false);
      //   swal(
      //     "Your password must be at least 8 characters long and contain at least one upper case letter, a special character (@#$*!) and a number"
      //   );
      // }
    } else {
      setIsLoading(false);
      swal(
        " The password and password confirmation do not match, please reenter"
      );
    }
  }

  //Random Function

  function myFunction(name, eyes) {
    var x = document.querySelector(`input[name=${name}]`);
    var y = document.querySelector(`#${eyes}`);
    if (
      x.type === "password" ||
      y.src ===
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
    ) {
      x.type = "text";
      y.src =
        "https://w7.pngwing.com/pngs/910/805/png-transparent-eye-key-look-password-security-see-view-minimalisticons-icon-thumbnail.png";
    } else {
      x.type = "password";
      y.src =
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png";
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileLogin ? (
        <>
          <Login
            myFunction={myFunction}
            loginHandler={loginHandler}
            registerHandler={registerHandler}
          />
        </>
      ) : (
        <>
          <div>
            <SigninHeader />
            <main className="login-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="login-left pe-lg-5 me-xl-5">
                      <h2>
                        Login in to <br />
                        your account
                      </h2>
                      <form onSubmit={loginHandler}>
                        <div className="login-content">
                          <div className="login-item">
                            <label htmlFor="loginEmail">Email Address</label>
                            <input
                              type="email"
                              autoComplete="username"
                              pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                              id="loginEmail"
                              placeholder="Email"
                              required
                            />
                          </div>

                          <div className="login-item">
                            <label htmlFor="password">Password</label>
                            <div className="password">
                              <input
                                minLength="8"
                                autoComplete="new-password"
                                id="loginPassword"
                                name="password"
                                type="password"
                                placeholder="Password"
                                required
                              />
                              <img
                                src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                                alt=""
                                id="eye1"
                                name="eye1"
                                width="30px"
                                onClick={() => myFunction("password", "eye1")}
                              />
                            </div>
                            <p
                              className="forgetHandler"
                              onClick={forgetHandler}
                            >
                              {" "}
                              Forgot Password?
                            </p>
                          </div>

                          <div className="login-item">
                            <button type="submit">Login</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="login-right">
                      <div className="login-rightcnt">
                        <h2>Create account</h2>
                        <form onSubmit={registerHandler}>
                          <div className="login-item">
                            <label htmlFor="l1">First Name</label>
                            <input
                              type="text"
                              id="sf"
                              placeholder="First Name"
                              required
                            />
                          </div>
                          <div className="login-item">
                            <label htmlFor="l1">Last Name</label>
                            <input
                              type="text"
                              id="sl"
                              placeholder="Last Name"
                            />
                          </div>
                          <div className="login-item">
                            <label htmlFor="se">Email Address</label>
                            <input
                              type="email"
                              autoComplete="email"
                              pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                              id="se"
                              placeholder="Email"
                              required
                            />
                          </div>
                          <div className="login-item">
                            <label htmlFor="se">Confirm Email Address</label>
                            <input
                              type="email"
                              autoComplete="email"
                              pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                              id="sce"
                              placeholder="Confirm Email"
                              required
                            />
                          </div>
                          <div className="login-item">
                            <label htmlFor="sp">Password</label>
                            <div className="password">
                              <input
                                className="password"
                                autoComplete="new-password"
                                type="password"
                                id="sp"
                                placeholder="Password"
                                required
                                name="NewPassword"
                              />
                              <img
                                src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                                alt=""
                                name="eye4"
                                id="eye4"
                                width="30px"
                                onClick={() =>
                                  myFunction("NewPassword", "eye4")
                                }
                              />
                            </div>
                          </div>
                          <div className="login-item">
                            <label htmlFor="password">Confirm Password</label>
                            <div className="password">
                              <input
                                name="confirmPassword"
                                autoComplete="new-password"
                                type="password"
                                id="password"
                                placeholder="Confirm Password"
                                required
                              />
                              <img
                                src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                                alt=""
                                name="eye2"
                                id="eye2"
                                width="30px"
                                onClick={() =>
                                  myFunction("confirmPassword", "eye2")
                                }
                              />
                            </div>
                          </div>
                          {/* <div className="login-item">
                                        <label htmlFor="l6">Referal ID</label>
                                        <input  type="text" id="l6" placeholder="Referal ID" onChange={(e)=> setRegisterReferallId(e.target.value)}/>
                                    </div> */}

                          <div className="login-item submit-btn2">
                            <button type="submit">Create Account</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <SigninFooter />
          </div>
        </>
      )}
    </>
  );
}

export default LoginSignup;
