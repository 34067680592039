import { Link } from "react-router-dom";
import { LoggedFooter } from "../../Components/Footer";
import LoggedHeader from "../../Components/Header";

function Failure() {
    return(
        <div>
            <LoggedHeader/>

            <main className="login-wrapper">
                <div className="container">
                    <div className="login-left moible-wrap">
                        <br/>
                        <br/>
                        <br/>
                        <p>Error Occurred !!</p>
                        <br/>
                        <h2>Payment Failed 💀</h2>
                        <br/>
                        <br/>
                        <b><a href="https://friends-verifier.netlify.app/dashboard">Dashboard</a></b>
                    </div>
                </div>
            </main>

        <LoggedFooter/>
        </div>
    )
    
}

export default Failure;
