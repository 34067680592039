       



import { useState } from "react";
import { useNavigate } from "react-router-dom";

 function TermsBottomBar(){
    const navigate = useNavigate();
    return(
        <div className="termsBottom_container">
          <div className="terms_bottom">
            <div>
             <p>Disagree</p>
            </div>
            <div>
             <p>Agree</p>
            </div>
          </div>
        </div>
    )
 }

 export default TermsBottomBar;