/** @format */

import menu from "../images/menu.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { axiosGetCall, axiosGet, axiosDelete } from "../utils/axiosRequest";
import swal from "sweetalert";
import AdImage from "../assets/icons/Ads.png";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/action";

function DashboardWrapper() {
  const [left, setLeft] = useState(0);
  const [free, setFree] = useState(0);
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");
  const [searches, setSearches] = useState("0");
  const navigate = useNavigate();
  const [view, setView] = useState(0);
  const [planInfo, setPlanInfo] = useState({});
  const [isSuperUser, setIsSuperUser] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const id = localStorage.getItem("@loginDetails");
    // console.log("🚀 ~ file: DashboardWrapper.jsx:20 ~ useEffect ~ localStorage:", (localStorage?.loginDetails?.isSuperUser))
    if (id === null) {
      navigate("/login-signup");
    }
    // if (localStorage.tokken != "null" && localStorage.tokken != null) {
    GetSearchFun();
    GetPlansList();
    // }
    // if (
    //   localStorage?.loginDetails != null &&
    //   localStorage?.loginDetails != "null"
    // ) {
    //   // var superUser = JSON.parse(localStorage?.loginDetails);
    //   // setIsSuperUser(superUser?.isSuperUser);
    // }
  }, []);

  const GetSearchFun = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    const get = "/getSearch";
    axiosGetCall(get, (callBack) => {
      console.log(
        "🚀 ~ file: DashboardWrapper.jsx:48 ~ axiosGetCall ~ callBack:",
        JSON.stringify(callBack)
      );
      if (callBack?.status === 200) {
        const left = callBack.data.data.totalSearch;
        setFree(callBack.data.data.freeSearches);
        if (callBack.data.data.planId) {
          setPlanInfo(callBack?.data?.data);
          setPrice(callBack.data.data.planId.price);
          setType(callBack.data.data.planId.planType);
          setSearches(callBack.data.data.planId.totalSearch);
        } else {
          console.log(
            "🚀 ~ file: DashboardWrapper.jsx:45 ~ axiosGetCall ~ aler:",
            callBack.data?.data
          );
        }
        if (left > 500) {
          setLeft(left);
        } else {
          setLeft(left);
        }
      } else if (callBack?.status === 401) {
        swal({
          title: "Unauthorized Access Detected",
          text: callBack.message,
          showCancelButton: true,
          closeOnConfirm: false,
          animation: "slide-from-top",
          buttons: {
            confirm: {
              text: "Log out",
              className: "red-button",
            },
          },
          dangerMode: true,
        }).then(() => {
          dispatch(logOut());
          navigate("/login-signup");
        });
      } else {
        // swal(callBack.message)
        swal({
          title: "Friend Verifier",
          text: callBack.message,
          showCancelButton: true,
          closeOnConfirm: false,
          animation: "slide-from-top",
        }).then(() => {
          // navigate("/login-signup");
        });
        // alert(callBack.message);
      }
    });
  };

  const [defaultPlanPrice, setDefaultPlanPrice] = useState("");

  const GetPlansList = () => {
    var url = "/subscriptionPlans/list?planType=OneTime";
    axiosGet(url, (callBack) => {
      if (callBack?.status == 200) {
        setPrice(
          callBack?.data?.data[0]?.discountedPrice
            ? callBack?.data?.data[0]?.discountedPrice
            : callBack?.data?.data[0]?.price
        );
      }
    });
  };

  function hide(val) {
    const div = document.getElementById(val);
    if (div.style.display === "none") {
      div.style.display = "block";
    } else {
      div.style.display = "none";
    }
  }

  function change(value) {
    navigate(`/update${value}`);
  }

  function invite() {
    navigate("/invite");
  }

  const cancelSub = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    var url = "/getSearch/cancelPlan";
    axiosGetCall(url, (callBack) => {
      if (callBack?.status == 200) {
        setView(0);
        swal("Plan canceled");
        GetSearchFun();
        GetPlansList();
        setPlanInfo({});
        setPrice(0);
      }
    });
  };

  function Warning() {
    if (view == 1) {
      return (
        <div id="alerts">
          <div className="alert" style={{ height: 400 }}>
            <h4>Please Confirm Subscription Cancelation!</h4>
            <div>
              By canceling your subscription you will not be charged again at
              the end of your billing cycle, you can use the remaining credits
              that you may have until the end of your subscription when all
              credits will be lost.
            </div>
            <div className="buttons">
              <button
                className="login-btn loginactive Link"
                onClick={() => setView(0)}
              >
                <h5 style={{ margin: "0px" }}>No</h5>
              </button>
              <button
                className="login-btn loginactive Link"
                onClick={() => cancelSub(0)}
              >
                <h5 style={{ margin: "0px" }}>Yes</h5>
              </button>
            </div>
          </div>
        </div>
      );
    } else if (view == 2) {
      return (
        <div id="alerts">
          <div className="alert" style={{ height: 300 }}>
            <h4>Are you Sure?</h4>
            <div>
              Deleting your account you will losse all of your search history
              and settings.
            </div>
            <div className="buttons">
              <button
                className="login-btn loginactive Link"
                onClick={() => setView(0)}
              >
                <h5 style={{ margin: "0px" }}>No</h5>
              </button>
              <button
                className="login-btn loginactive Link"
                onClick={() => deleteAccount(0)}
              >
                <h5 style={{ margin: "0px" }}>Delete</h5>
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  const deleteAccount = () => {
    setView(0);
    // var userid = userData?.data?._id;
    const url = "/user/delete";
    axiosDelete(url, (callBack) => {
      console.log("callBack", callBack?.data);

      if (callBack?.status == 200) {
        alert("Your Account Deleted");
        dispatch(logOut());
        navigate("/");
        // dispatch(logOut({}));
        // navigation.reset({
        //   index: 0,
        //   routes: [{ name: "LoginScreen" }],
        // });
      } else {
        alert(" Account Not Deleted");
      }
    });
  };

  // var left = localStorage.left

  return (
    <div>
      {view > 0 ? <Warning /> : false}

      <div style={{ marginBottom: 10 }}>
        <div className="dashboard-card">
          <div className="row">
            <div className="col-md-9">
              <h5>{free} Free Credits </h5>
            </div>
            <div className="col-md-3">
              <img src={menu} alt="" onClick={() => hide("hidewrapper2")} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <div className="dashboard-card">
          <div className="row">
            <div className="col-md-9">
              <h5>
                {planInfo?.hasOwnProperty("planId") ? (
                  <>{left} Credits Left </>
                ) : (
                  <>{isSuperUser ? "Super User" : "Pay As You Go"}</>
                )}
              </h5>
            </div>
            <div className="col-md-3">
              <img src={menu} alt="" onClick={() => hide("hidewrapper1")} />
            </div>
          </div>

          <div id="hidewrapper1">
            <p>
              {type == "OneTime" ? (
                <>Pay As You Go Each Search is ${price}/ each</>
              ) : (
                <>
                  {planInfo?.hasOwnProperty("planId") ? (
                    <>
                      {planInfo?.planId?.totalSearch}{" "}
                      {planInfo?.planId?.planName}
                      <br />
                      {/* {planInfo?.totalSearch} of{" "} */}
                      {/* {planInfo?.planId?.totalSearch} credits remain */}
                    </>
                  ) : (
                    <>
                      {isSuperUser ? (
                        "Free Access"
                      ) : (
                        <>{price} per background check</>
                      )}
                    </>
                  )}

                  {/* {type} {searches} of credits remaining */}
                </>
              )}
            </p>
            <Link className="subscrip-btn Link" to="/subscriptionPage">
              Subscriptions
            </Link>
            {searches > 0 ? (
              <div id="hidewrapper3">
                <ul style={{ paddingLeft: "0", paddingTop: "2px" }}>
                  <li>
                    {planInfo?.hasOwnProperty("planId") ? (
                      <a onClick={() => setView(1)}>Cancel my subscription</a>
                    ) : (
                      <>
                        <div id="hidewrapper3">
                          <ul style={{ paddingLeft: "0", paddingTop: "2px" }}>
                            <li>
                              <a onClick={() => navigate("/subscriptionPage")}>
                                Save money with a subscription
                              </a>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <div id="hidewrapper3">
                  <ul
                    style={{
                      paddingLeft: "0",
                      paddingTop: "2px",
                      textAlign: "center",
                    }}
                  >
                    <li>
                      <a
                        style={{ fontSize: "0.75rem" }}
                        onClick={() => navigate("/subscriptionPage")}
                      >
                        Save money with a subscription
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <div className="dashboard-card">
          <div className="row">
            <div className="col-md-9">
              <h5>My Account </h5>
            </div>
            <div className="col-md-3">
              <img src={menu} alt="" onClick={() => hide("hidewrapper1")} />
            </div>
          </div>

          <div id="hidewrapper3">
            <ul style={{ paddingLeft: "0", paddingTop: "2px" }}>
              <li>
                <a
                  style={{ fontSize: "0.85rem", textDecoration: "underline" }}
                  onClick={() => change("Email")}
                >
                  Change Email Address
                </a>
              </li>
              <li>
                <u>
                  <a
                    style={{ fontSize: "0.85rem", textDecoration: "underline" }}
                    onClick={() => change("Password")}
                  >
                    Change Password
                  </a>
                </u>
              </li>
              <li>
                <a
                  style={{ fontSize: "0.85rem", textDecoration: "underline" }}
                  onClick={() => change("Phone")}
                >
                  Change Mobile Number
                </a>
              </li>
              <li>
                <Link
                  style={{ fontSize: "0.85rem", textDecoration: "underline" }}
                  to="/support-ticket"
                >
                  Support Ticket
                </Link>
              </li>
              <li>
                <a
                  style={{ fontSize: "0.85rem", textDecoration: "underline" }}
                  // to="/support-ticket"
                  onClick={() => {
                    setView(2);
                    // swal({
                    //   title: "Are you Sure?",
                    //   text: "Deleting your account you will losse all of your search history and settings",
                    //   showCancelButton: true,
                    //   closeOnConfirm: true,
                    //   animation: "slide-from-top",
                    // }).then(() => {
                    //   // navigate("/login-signup");
                    // });
                  }}
                >
                  Delete My Account
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <img src={AdImage} alt="ad" /> */}
    </div>
  );
}

export default DashboardWrapper;
