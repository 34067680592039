import { useState } from "react";
import logo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";
import Header from "../../MobileComponents/Header";
import Invisible from "../../assets/icons/invisible.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import RecentSearches from "../../MobileComponents/RecentSearches";
import Footer from "../../MobileComponents/Footer";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import USFlag from "../../assets/icons/unitedStatesFlag.png"

function SMSVerification({ mobVerify, setPhone, phone }) {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  function sendSmsFunc(e) {
    mobVerify(e);
    // navigate("/PhoneVerificationOtp");
  }

  return (
    <div className="login_container">
      <Header title={"SMS Verification"} />
      <form className="login-wrapper" onSubmit={(e) => mobVerify(e)}>
        <div className="container login_container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-left pe-lg-5 me-xl-5">
                <div className="login-content">
                  <div className="phoneNo-verification">
                    <h3 className="verifyEmailtext">
                      {" "}
                      Welcome to Friend Verifier!{" "}
                    </h3>
                  </div>
                  <div>
                    <p className="pleaseConfirmText">
                      Please confirm your mobile number. We ask you to <br />
                      do so, to prevent bots.
                    </p>
                  </div>
                </div>
                <div className="HomeInput-item">
                  <div className="rightSideBorder login_icon_position" >
                    <img
                      src={USFlag}
                      alt="Email Icon"
                      className="usFlagIcon_position"
                    />
                  </div>
                    <div className="phoneVerification_input_box">
                      <input 
                         type="number"
                         placeholder="  (000)   000   000"
                         name="phone"
                         id="focus"
                         onChange={(e)=>setPhone(e.target.value)}
                         value={phone}
                      />
                    </div>
                  </div>

                  

                <div
                  className="login-item sms_verification"
                  // onClick={sendSmsFunc}
                >
                  <button className="sms_search_btn" type="submit">
                    Send SMS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SMSVerification;
