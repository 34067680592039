/** @format */

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { SigninFooter } from "../../../Components/Footer";
import { SigninHeader } from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import { axiosPost } from "../../../utils/axiosRequest";
import SMSVerification from "../../../MobilePages/Auth/SmsVerification";
import { useLocation } from "react-router-dom";

function PhoneVerification() {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("+1");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const re = /^[0-9\b]+$/;
  const { state } = useLocation();

  {
    /* mobile screen */
  }
  const [mobileNoVerify, setMobileNoVerify] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileNoVerify(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
    if (!state?.hasOwnProperty("data")) {
      navigate("/login-signup");
    }
  }, [window.innerWidth]);

  useEffect(() => {
    var signUpMobile = localStorage?.signUpMobile;
    if (signUpMobile != "null" && signUpMobile != null) {
      signUpMobile = JSON.parse(signUpMobile);
      setPhone(signUpMobile);
    }
  }, [localStorage?.signUpMobile]);

  const handleResize = () => {
    setMobileNoVerify(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const Id = localStorage.getItem("@loginDetails");
    if (Id === null) {
      navigate("/login-signup");
      // navigate("/phoneVerification");
    }
    //console.log(code);
  });

  function mobVerify(e) {
    console.log("mobVerify called", e);
    e.preventDefault();

    setIsLoading(true);
    const url = "/user/register-phone";
    const obj = {
      device: "web",
      deviceInfo: {},
      countryCode: code,
      phone: phone,
    };
    var token = state?.data?.authToken;

    axiosPost(url, obj, token, (callBack) => {
      if (callBack?.status === 200) {
        swal(callBack.message);
        setIsLoading(false);
        setMobileNoVerify(true);
        navigate("/phoneVerificationOtp", {
          replace: true,
          state: {
            phone: phone,
            cc: code,
            data: callBack?.data,
            obj: obj,
          },
        });
      } else {
        setIsLoading(false);
        swal(callBack.message);
      }
    });
  }

  return (
    <>
      {mobileNoVerify ? (
        <SMSVerification
          mobVerify={mobVerify}
          setPhone={setPhone}
          phone={phone}
        />
      ) : (
        <div>
          {isLoading ? <Loading /> : false}
          <SigninHeader />

          <main className="login-wrapper">
            <div className="container">
              <div className="login-left moible-wrap">
                <h2>Confirm mobile number</h2>
                <p>We do this for security and preventing bots</p>
                <form onSubmit={(e) => mobVerify(e)}>
                  <div className="login-content mobile-cntnt">
                    <div className="login-item selectCode">
                      <div className="phoneCode">
                        {/* <select
                      name="countryCode"
                      id="countryCode"
                      onChange={(e) => setCode(e.target.value)}
                    > */}
                        <option value="+1">USA (+1)</option>
                        {/* <option value="+44" >UK (+44)</option>
                                            <option value="+91" >IN (+91)</option> */}
                        {/* </select> */}
                      </div>
                      <div className="phoneVerification">
                        <input
                          type="number"
                          id="lg1"
                          placeholder="Mobile Number"
                          name="phone"
                          onChange={(e) => {
                            if (
                              re.test(e.target.value) ||
                              e.target.value == ""
                            ) {
                              setPhone(e.target.value);
                            }
                          }}
                          required
                          value={phone}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className="login-item">
                      <button type="submit">Send Code</button>
                    </div>

                    <div className="login-links">
                      <p>
                        Already have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Login here
                        </Link>
                      </p>
                      <p>
                        Don’t have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Signup here
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>

          <SigninFooter />
        </div>
      )}
    </>
  );
}

export default PhoneVerification;
