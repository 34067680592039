import logo from "../images/logo.png";
import load from "../images/Loading.gif";
import warn from "../images/warning.png";
import LoggedHeader from "./Header";

function Searching() {
  return (
    <div id="loading" className="loadingScreen_mt">
      <LoggedHeader />
      <div className="load">
        <div className="loading">
          <img src={logo} alt="" width={"250px"} />
        </div>
        <h4>Loading...</h4>
        <img src={load} alt="" width={"150px"} />
        <p id="loadText">
          It may take up to 30 seconds for our report to load since they are
          compiled in real-time from billions of records from multiple data
          sources.
        </p>
        <div className="warning">
          <p>
            <img src={warn} alt="" width={"25px"} /> Don't leave the app, your
            progress will be lost
          </p>
        </div>
      </div>
    </div>
  );
}

export default Searching;
