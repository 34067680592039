/** @format */

import { useState } from "react";
import logo from "../../assets/icons/logo.png";
import UserIcon from "../../assets/icons/email.png";
import Password from "../../assets/icons/password.png";
import Invisible from "../../assets/icons/invisible.png";
import EmailIcon from "../../assets/icons/emailIcon.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faUser } from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../utils/axiosRequest";
import swal from "sweetalert";

function SignUp({ loginBack, registerHandler }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const navigate = useNavigate();
  const login = () => {
    loginBack();
  };

  function myFunction(name, eyes) {
    var x = document.querySelector(`input[name=${name}]`);
    var y = document.querySelector(`#${eyes}`);
    if (
      x.type === "password" ||
      y.src ===
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
    ) {
      x.type = "text";
      y.src =
        "https://w7.pngwing.com/pngs/910/805/png-transparent-eye-key-look-password-security-see-view-minimalisticons-icon-thumbnail.png";
    } else {
      x.type = "password";
      y.src =
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png";
    }
  }

  // Eye Function
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  return (
    <div className="login_container createAccount_mt">
      <header
        className="header-area"
        style={{ height: "20%", position: "fixed" }}
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="" />
            </a>
          </nav>
        </div>
      </header>
      <main className="SignUp-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-left pe-lg-5 me-xl-5">
                <form onSubmit={registerHandler}>
                  <div className="login-content">
                    <div className="login-item">
                      <div className="login_icon_position">
                        <img
                          src={UserIcon}
                          alt="Email Icon"
                          className="login_input_icon img_mt"
                        />
                      </div>
                      <input
                        type="text"
                        id="sf"
                        placeholder="First Name"
                        required
                      />
                    </div>
                    <div className="login-item">
                      <div className="login_icon_position">
                        <img
                          src={UserIcon}
                          alt="Email Icon"
                          className="login_input_icon img_mt"
                        />
                      </div>
                      <input
                        type="text"
                        id="sl"
                        placeholder="Last Name"
                        required
                      />
                    </div>
                    <div className="login-item">
                      <div className="login_icon_position">
                        <img
                          src={EmailIcon}
                          alt="Email Icon"
                          className="login_input_icon img_mt"
                        />
                      </div>
                      <input
                        type="email"
                        autoComplete="email"
                        pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                        id="se"
                        placeholder="Email Address"
                        required
                      />
                    </div>
                    <div className="login-item">
                      <div className="login_icon_position">
                        <img
                          src={EmailIcon}
                          alt="Email Icon"
                          className="login_input_icon img_mt"
                        />
                      </div>
                      <input
                        type="email"
                        pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                        id="sce"
                        autoComplete="username"
                        placeholder="Confirm Email Address"
                        required
                      />
                    </div>

                    <div className="login-item">
                      <div className="password">
                        <div className="login_icon_position">
                          <img
                            src={Password}
                            alt="Email Icon"
                            className="login_input_icon password_icon img_mt"
                          />
                        </div>
                        <input
                          minLength={"8"}
                          id="sp"
                          autoComplete="new-password"
                          name="password"
                          type={isPasswordVisible ? "text" : "password"}
                          placeholder="Password"
                          required
                        />
                        <div className="login_Eyeicon_position">
                          <FontAwesomeIcon
                            icon={isPasswordVisible ? faEye : faEyeSlash}
                            id="eye1"
                            name="eye1"
                            width="25px"
                            onClick={() =>
                              togglePasswordVisibility("password", "eye1")
                            }
                            className="login_Eye_icon eye_color"
                          />
                          {/* <img
                            src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                            alt=""
                            id="eye1"
                            name="eye1"
                            width="25px"
                            onClick={() => myFunction("password", "eye1")}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="login-item">
                      <div className="password">
                        <div className="login_icon_position">
                          <img
                            src={Password}
                            alt="Email Icon"
                            className="login_input_icon password_icon img_mt"
                          />
                        </div>
                        <input
                          id="password"
                          autoComplete="new-password"
                          name="confirmPassword"
                          type={isConfirmPasswordVisible ? "text" : "password"}
                          placeholder="Confirm Password"
                          required
                        />
                        <div className="login_Eyeicon_position">
                          <FontAwesomeIcon
                            icon={isConfirmPasswordVisible ? faEye : faEyeSlash}
                            id="eye2"
                            name="eye2"
                            width="25px"
                            onClick={() =>
                              toggleConfirmPasswordVisibility(
                                "confirmPassword",
                                "eye2"
                              )
                            }
                            className="login_Eye_icon eye_color"
                          />
                          {/* <img
                            src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                            alt=""
                            id="eye2"
                            name="eye2"
                            width="25px"
                            onClick={() => myFunction("confirmPassword", "eye2")}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div
                      className="login-item login_Create_account_item"
                      // onClick={createAccountFunc}
                    >
                      <button type="submit">Create Account</button>
                    </div>
                    <div onClick={login}>
                      <p className="Already_have_accound">
                        {" "}
                        Already have an account?
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SignUp;
