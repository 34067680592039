/** @format */

import React from "react";
import { LoggedFooter } from "../../Components/Footer";
import LoggedHeader from "../../Components/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { axiosGet, axiosPost, axiosGetCall } from "../../utils/axiosRequest";
import Loading from "../../Components/Loading";
import { useEffect, useRef, useCallback } from "react";
import swal from "sweetalert";
import DashboardWrapper from "../../Components/DashboardWrapper";
import SearchAreaComp from "../../Components/SearchArea";
import PayPal from "../../Pages/Subscription/PayPal";
import Header from "../../MobileComponents/Header";
import Arrow from "../../assets/icons/left-Arrow.png";

// import Stripe from "stripe";
// import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";

function MobileSubscriptions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [stateData, setStateData] = useState();

  const [sub, setSub] = useState([]);
  const [paymentvia, setPaymentvia] = useState({
    mode: "paypal",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [planInfo, setPlanInfo] = useState({});
  const [cancel, setCancel] = useState(false);

  const paypalContainerRef = useRef(null);

  useEffect(() => {
    GetPlansList(0);
    GetSearch();
    localStorage.setItem("pendingSearchSuccess", JSON.stringify(false));

    var metaBody = localStorage?.metaBody;
    if (metaBody != "null" && metaBody != null) {
      metaBody = JSON.parse(metaBody);
      setStateData(metaBody);
    }
  }, []);

  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    GetPlansList(selectedTab);
    GetPlansList(selectedTab);
  }, [selectedTab]);

  const [PayAsYouGo, setPayAsYouGo] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);

  const GetPlansList = (SelectedPlan = 0) => {
    var url;
    if (SelectedPlan == 1) {
      url = "/subscriptionPlans/list?planType=Monthly";
    } else if (SelectedPlan == 2) {
      url = "/subscriptionPlans/list?planType=Yearly";
    } else if (SelectedPlan == 0) {
      url = "/subscriptionPlans/list?planType=OneTime";
    }
    axiosGet(url, (callBack) => {
      if (callBack?.status == 200) {
        if (SelectedPlan == 0) {
          setPayAsYouGo(callBack?.data?.data);
          setSelected(callBack?.data?.data[0]);
          if (state) {
            setShowFinalPayment(true);
          }
        } else {
          setSub(callBack?.data?.data);
        }
      }
    });
  };
  const GetSearch = (SelectedPlan = 0) => {
    var url = "/getSearch";
    axiosGet(url, (callBack) => {
      if (callBack?.status == 200) {
        setPlanInfo(callBack?.data?.data);
      }
    });
  };

  const [showFinalPayment, setShowFinalPayment] = useState(false);

  function call(e) {
    setShowFinalPayment(true);
  }
  const colors = ["#BAD8E0", "#FAD143", "#030303"];

  var search = "";
  const [selected, setSelected] = useState();

  const [view, setView] = useState(false);
  const [view1, setView1] = useState(false);

  function Warning1() {
    return (
      <div id="alerts">
        <div className="alert" style={{ height: 200 }}>
          <h4>Alert!</h4>
          <div>
            You must cancel the current plan before purchasing a new
            subscription.
          </div>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView1(false)}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={() => {
                setView1(false);
                setView(true);
              }}
            >
              <h5 style={{ margin: "0px" }}>Cancel Plan</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }
  function Warning() {
    return (
      <div id="alerts">
        <div className="alert" style={{ height: 400 }}>
          <h4>Please Confirm Subscription Cancelation!</h4>
          <div>
            By canceling your subscription you will not be charged again at the
            end of your billing cycle, you can use the remaining credits that
            you may have until the end of your subscription when all credits
            will be lost.
          </div>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView(false)}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={() => cancelSub(false)}
            >
              <h5 style={{ margin: "0px" }}>Yes</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }

  const cancelSub = () => {
    setCancel(true);
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    var url = "/getSearch/cancelPlan";
    axiosGetCall(url, (callBack) => {
      if (callBack?.status == 200) {
        setView(false);
        swal("Plan canceled");
      }
      setCancel(false);
    });
  };

  const GetMetaBody = () => {
    var metaBody = localStorage?.metaBody;
    if (metaBody != "null" && metaBody != null) {
      metaBody = JSON.parse(metaBody);

      return metaBody?.fields?.map((item, index) =>
        Object.keys(metaBody?.fields[index]) != "city" &&
        Object.keys(metaBody?.fields[index]) == "phone"
          ? formatPhoneNumber(
              metaBody?.fields[index][Object.keys(metaBody?.fields[index])[0]]
            )
          : metaBody?.fields[index][Object.keys(metaBody?.fields[index])[0]] +
            " "
      );
    }
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  };

  function List({ list, type }) {
    if (list.totalSearch > 100) {
      search = "Unlimited";
    } else {
      search = list.totalSearch;
    }
    return (
      <>
        <div
          key={list._id}
          className="radio-btn radio-btn2"
          onClick={() => setSelected(list)}
          style={{ height: 170 }}
        >
          <div
            className=""
            style={{
              minHeight: 150,
              width: "100%",
              borderRadius: 10,
              border: "1px #777777 solid",
              padding: 20,
              paddingTop: 20,
              backgroundColor: type == 4 ? "#BECEEA" : type == 5 && "#EFEFF1",
              // height:'110%'
            }}
          >
            <div className="row">
              <div className="col-md-10 planCard_col1">
                {/* {search === 1 ? ( */}
                <>
                  {PayAsYouGo?.length > 0 && type == 1 ? (
                    <h4>
                      <strong>{PayAsYouGo[0]?.planName}</strong>
                    </h4>
                  ) : type == 2 || type == 3 || type == 4 ? (
                    <>
                      <h4>
                        <strong>
                          {search} {list?.planName}
                        </strong>
                      </h4>
                    </>
                  ) : (
                    type == 5 && (
                      <h4>
                        <h5 style={{ fontWeight: "bold" }}>
                          <>
                            {/* {JSON.stringify(state?.metaBody)} */}
                            {state?.metaBody?.fields?.length > 0 ? (
                              <>
                                {state?.metaBody?.fields[0]?.hasOwnProperty(
                                  "firstName"
                                )
                                  ? "People Search"
                                  : state?.metaBody?.fields[0]?.hasOwnProperty(
                                      "email"
                                    )
                                  ? "Email Search"
                                  : state?.metaBody?.fields[0]?.hasOwnProperty(
                                      "phone"
                                    )
                                  ? "Phone Search"
                                  : "Associate People Search"}
                              </>
                            ) : (
                              "Potential Associate Search"
                            )}
                          </>
                        </h5>
                      </h4>
                    )
                  )}
                  <div>
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: type == 5 ? "#000" : "#305A9C",
                      }}
                    >
                      {" "}
                      {type == 5 ? (
                        <>
                          <GetMetaBody />
                        </>
                      ) : (
                        <>
                          $
                          {list?.discountedPrice
                            ? list?.discountedPrice
                            : list?.price}
                        </>
                      )}
                    </span>{" "}
                    {type != 5 && (
                      <>
                        /{" "}
                        <span
                          style={{
                            color: type == 4 ? "black" : "lightgray",
                          }}
                        >
                          {type == 1 ? "Per search" : list?.description}
                        </span>
                      </>
                    )}
                  </div>
                  {type == 5 ? (
                    <>
                      <div>
                        <div style={{ color: "#777777" }}>
                          <span
                            style={{
                              fontSize: 24,
                              fontWeight: "400",
                              color: "#305A9C",
                            }}
                          >
                            $
                            {list?.discountedPrice
                              ? list?.discountedPrice
                              : list?.price}
                          </span>
                          <>
                            /{" "}
                            <span
                              style={{
                                color: "black",
                              }}
                            >
                              {"Per search"}
                            </span>
                          </>

                          {list.discountPercentage ? (
                            <div>
                              <div className="regularSize_row">
                                Regular Price: ${list.price} -{" "}
                                <strong style={{ color: "red" }}>
                                  {list.discountPercentage}% off!
                                </strong>
                              </div>
                            </div>
                          ) : (
                            <>
                              Price:{" "}
                              {list?.discountedPrice
                                ? list?.discountedPrice
                                : list?.price}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {list.discountPercentage ? (
                        <div>
                          <div style={{ color: "#777777" }}>
                            Regular Price: {list.price} -{" "}
                            <strong style={{ color: "red" }}>
                              {list.discountPercentage}% off!
                            </strong>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div style={{ color: "#777777" }}>
                            {list?.description}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
              <div
                className="col-md-2 planCard_col2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  height: 35,
                  marginTop: 25,
                }}
              >
                <div
                  style={{
                    width: 50,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "60px",
                      // bottom: '52%',
                      height: 30,
                      width: 30,
                      backgroundColor: "white",
                      borderRadius: 15,
                      border: "0.5px solid gray",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {list._id == selected?._id && (
                      <div
                        style={{
                          height: 20,
                          width: 20,
                          backgroundColor: "#315A9C",
                          borderRadius: 10,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  function List1({ list }) {
    if (list.totalSearch > 100) {
      search = "Unlimited";
    } else {
      search = list.totalSearch;
    }
    return (
      <>
        <div
          key={list._id}
          className="radio-btn radio-btn2 "
          onClick={() => setSelected(list)}
          style={{ minHeight: 150 }}
        >
          <label htmlFor={list._id}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div className="row">
                <div className="col-md-8">
                  {search === 1 ? (
                    <>
                      {PayAsYouGo?.length > 0 && (
                        <h4>
                          <strong>{PayAsYouGo[0]?.planName}</strong>
                        </h4>
                      )}
                      <div>
                        <span>
                          {" "}
                          $
                          {list?.discountedPrice
                            ? list?.discountedPrice
                            : list?.price}
                        </span>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <div style={{ width: 50 }}>
                    <div
                      style={{
                        height: 30,
                        width: 30,
                        backgroundColor: "white",
                        borderRadius: 15,
                        border: "0.5px solid gray",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {list._id == selected?._id && (
                        <div
                          style={{
                            height: 20,
                            width: 20,
                            backgroundColor: "#315A9C",
                            borderRadius: 10,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <span className="radio-cnt">
                {search === 1 ? (
                  <></>
                ) : (
                  <>
                    {search === "Unlimited" ? (
                      <>
                        <p>{search} Searches</p>
                        <h4>
                          <strong>
                            $
                            {list?.discountedPrice
                              ? list?.discountedPrice
                              : list?.price}
                          </strong>{" "}
                          Billed Monthly
                        </h4>
                      </>
                    ) : (
                      <div>
                        <p>{search} Searches Per Month</p>
                        <h4>
                          <strong>
                            $
                            {list?.discountedPrice
                              ? list?.discountedPrice
                              : list?.price}
                          </strong>{" "}
                          {list?.planType == "Monthly"
                            ? "/ " + list?.planType
                            : "/ " + list?.description}
                        </h4>
                      </div>
                    )}
                  </>
                )}
              </span>
              <span className="radio-cntinr">
                <h2>
                  {list?.discountedPrice && list?.discountPercentage ? (
                    <>
                      {list.discountPercentage}% <strong>off!</strong>
                      <p>(Regular Price: {list.price}) </p>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "100px" }} />
                    </>
                  )}
                </h2>
              </span>
            </div>
          </label>
        </div>
      </>
    );
  }

  const BilledSelectorComp = () => (
    <>
      <div
        style={{
          height: 70,
          width: "100%",
          justifyContent: "flex-end",
          flexDirection: "row",
          paddingHorizontal: 20,
          marginBottom: 10,
          bottom: 20,
          position: "relative",
          right: 0,
          border: "1px solid red",
        }}
      >
        <div
          style={{
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <p>Billed:</p>
          <div
            style={{
              display: "flex",

              height: 35,
              width: "100%" / 2,
              borderRadius: 20,
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              paddingHorizontal: 3,
              marginLeft: 10,
            }}
          >
            <div
              activeOpacity={0.5}
              onPress={() => {}}
              style={{
                height: 30,
                width: "50%",
                backgroundColor: "#305A9C",
                borderRadius: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 12,
                }}
              >
                Monthly
              </p>
            </div>
            <div
              activeOpacity={0.5}
              onPress={() => {}}
              style={{
                height: 30,
                width: "50%",
                borderRadius: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 12,
                }}
              >
                Yearly
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const PayPalPaymentRequest = (obj) => {
    var url =
      "/orders/successPayment?planId=" +
      selected?._id +
      "&paymentMode=online&paymentVia=google&purchaseToken=" +
      obj?.purchaseToken;

    console.log(
      "🚀 ~ file: SubscriptionPage.jsx:654 ~ PayPalPaymentRequest ~ url:",
      url
    );
    // var Token = userData?.loginDetails?.Authorization;
    // setPayPalLoading(true);
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    axiosGetCall(url, (callBack) => {
      if (callBack?.data?.status == 200) {
        swal("Payment successful.");

        localStorage.setItem("pendingSearchSuccess", JSON.stringify(true));
        navigate(-1);
      }
      // setPayPalLoading(false);
      // simpleAlertCall("Payment successful.", () => {
      //   navigation.goBack();
      // });
    });
  };

  const [isChecked, setIsChecked] = useState(false);

  const GetCheckBox = () => {
    var checkbox = document.getElementById("payMode");
    setIsChecked(checkbox?.checked);
  };

  return (
    <div className="login_container subscription_mt">
      {view1 && <Warning1 />}
      {view && <Warning />}
      {isLoading ? <Loading /> : false}
      <Header
        position={"fixed"}
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        title={"My Subscription"}
        leftButtonClick={() => navigate(-1)}
      />

      <div className="subscribe-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="subscribe-box mobile_subsBox">
                <h4>
                  {showFinalPayment && state
                    ? "Complete Report Generation"
                    : showFinalPayment
                    ? "Selected Plan"
                    : "Choose Plan"}
                </h4>
                {showFinalPayment ? (
                  <>
                    {state ? (
                      <List list={selected} type={5} />
                    ) : (
                      <List list={selected} type={4} />
                    )}
                    <h4 style={{ marginTop: 40 }}>{"Payment Options"}</h4>
                    <div></div>
                    <PayPal
                      PayPalPaymentRequest={PayPalPaymentRequest}
                      planId={selected}
                      isChecked={isChecked}
                    />
                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                      <label class="containersS">
                        I acknowledge and accept that I will not use Friend
                        Verifier to assess someone's qualification for credit,
                        insurance, employment, housing, or any other purposes
                        governed by the Fair Credit Reporting Act (FCRA). I am
                        fully aware that Friend Verifier does not function as a
                        consumer reporting agency. Thus, I hereby consent to
                        abide by the Friend Verifier Consumer Terms of Use and
                        Privacy Policy.
                        <input id="payMode" type="checkbox" name="payMode" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div className="subscribe-btn" style={{ width: "100%" }}>
                      <button
                        onClick={() => {
                          if (state) {
                            navigate(-1);
                          } else {
                            setShowFinalPayment(false);
                          }
                        }}
                        className="active Link"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {PayAsYouGo?.map((item, index) => (
                      <>
                        {item?.planCategory}
                        <List list={item} type={1} />
                      </>
                    ))}

                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-md-4">
                        <div className="row billed_Row">
                          <div
                            style={{
                              color: "415192",
                              fontWeight: "bold",
                              fontSize: 18,
                              width: "20%",
                            }}
                            className="col-md-3"
                          >
                            Billed:
                          </div>
                          <div
                            className="col-md-8"
                            style={{
                              width: "60%",
                              marginLeft: 10,
                              borderRadius: 15,
                              height: 30,
                              border: "0.5px solid gray",
                            }}
                          >
                            <div className="row">
                              <div
                                className="col-md-6 "
                                style={{
                                  backgroundColor:
                                    selectedTab == 1 && "#315A9C",
                                  borderRadius: 15,
                                  height: 30,
                                  color: selectedTab == 1 ? "white" : "black",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "45%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                }}
                                onClick={() => {
                                  setSelectedTab(1);
                                }}
                              >
                                Monthly
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  width: "55%",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  backgroundColor:
                                    selectedTab == 2 && "#315A9C",
                                  borderRadius: 15,
                                  height: 30,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: selectedTab == 2 ? "white" : "black",
                                }}
                                onClick={() => {
                                  setSelectedTab(2);
                                }}
                              >
                                Yearly
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {yearlyPlans?.map((item, index) => (
                      <>
                        {item?.planCategory && (
                          <div
                            style={{
                              width: 120,
                              height: 35,
                              backgroundColor:
                                item?.planCategory == "Basic"
                                  ? colors[0]
                                  : item?.planCategory == "Gold"
                                  ? colors[1]
                                  : item?.planCategory == "Platinum"
                                  ? colors[2]
                                  : "gray",
                              borderRadius: 30,
                              left: 10,
                              top: 15,
                              position: "relative",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  item?.planCategory == "Platinum"
                                    ? "white"
                                    : "black",
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                textAlign: "center",
                                top: 5,
                                position: "relative",
                              }}
                            >
                              {item?.planCategory}
                            </p>
                          </div>
                        )}
                        <List list={item} type={2} />
                      </>
                    ))}

                    {sub?.map((item, index) => (
                      <>
                        {item?.planCategory && (
                          <div
                            style={{
                              width: 120,
                              height: 35,
                              backgroundColor:
                                item?.planCategory == "Basic"
                                  ? colors[0]
                                  : item?.planCategory == "Gold"
                                  ? colors[1]
                                  : item?.planCategory == "Platinum"
                                  ? colors[2]
                                  : "gray",
                              borderRadius: 30,
                              left: 10,
                              top: 15,
                              position: "relative",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  item?.planCategory == "Platinum"
                                    ? "white"
                                    : "black",
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                textAlign: "center",
                                top: 5,
                                position: "relative",
                              }}
                            >
                              {item?.planCategory}
                            </p>
                          </div>
                        )}
                        <List list={item} type={3} />
                      </>
                    ))}
                    <div className="subscribe-btn" style={{ width: "100%" }}>
                      <button
                        onClick={() => {
                          if (planInfo?.hasOwnProperty("planId")) {
                            if (
                              planInfo?.planId?.planType == "OneTime" ||
                              (planInfo?.planId?.planType == "Monthly" &&
                                planInfo?.freeSearches <= 0 &&
                                planInfo?.totalSearch <= 0)
                            ) {
                              call();
                            } else {
                              setView1(true);
                            }
                          } else {
                            call();
                          }
                        }}
                        className="active Link"
                      >
                        Choose
                      </button>
                    </div>
                    {showFinalPayment && (
                      <div
                        className="subscribe-btn cancel"
                        // style={{ width: "100%", backgroundColor: "white" }}
                      >
                        <button onClick={call} className="s">
                          Cancel
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSubscriptions;
