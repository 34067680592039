import React from "react"
import Footer from "../../../Components/Footer"
import { ContactHeader } from "../../../Components/Header"

export default function DoNotSell(){
	return(
		<body>

			<ContactHeader/>
			<br />
			<br />
			<section className="not-sell-hero">
				<div className="container">
					<div className="not-sell-title">
						<h2>Do Not Sell My Info</h2>
					</div>
				</div>
			</section>
			

			<div className="note-sell-text">
				<div className="container">
					<div className="not-sell-inner">
						<p>Your safety is our priority at Friend Verifier. We provide a quick and easy process to remove your
							information from our system via two methods.</p>

						<p>If you are a Friend Verifier user, you can search for your record within the app and then flag your
							record. We will then send you a verification email to confirm the removal of your record. This process
							takes 24 and 48 hours (business days) to complete. </p>
						<p>If you do not have an account, fill out the form below, and please add as much info as possible so we can
							determine if we have information about you. If we find a match, we will send you an email to verify the
							record for removal. This process is lengthy and can take up to three business days. If you have any
							questions, email us at <a href="privacy@friendverifier.com">privacy@friendverifier.com</a></p>
						<p>If you find multiple records about yourself on Friend Verifier, you can request to have them removed.</p>
						<p>We constantly obtain new databases and regularly update the databases we already have. We strive to do
							our best to match new records to the existing records in our databases</p>
						<p>There may be instances where an existing record is just different enough from a new record, where our
							algorithms see these records as two unique records and may appear in our search results. To prevent fraud
							and abuse and protect our Opt-Out process's integrity, we only permit you to remove one record from our
							system through our online process. </p>
						<p>If you find more than one record about you in our search results and want to remove them, don't hesitate
							to contact us at <a href="privacy@friendverifier.com">privacy@friendverifier.com</a> , and we will be happy to help you.</p>
						<p></p>


					</div>
				</div>
			</div>

			<section className="opt-area overflow-hidden">
				{/* <div className="container">
					<h2>Opt-Out Request</h2>
					<div className="opt-form">
						<form action="#">
							<div className="row g-">
								<div className="col-md-6 col-lg-6">
									<input type="text" placeholder="First Name"/>
								</div>
								<div className="col-md-6 col-lg-6">
									<input type="text" placeholder="Middle Name"/>
								</div>
								<div className="col-md-6 col-lg-6">
									<input type="text" placeholder="Last Name"/>
								</div>
								<div className="col-md-6 col-lg-6">
									<div className="date-pic">
										<label htmlFor="datepicker"> Date of Birth </label><br />
										<input type="text" id="datepicker" placeholder="MM/DD/YYYY"/>
									</div>
								</div>
								<div className="col-md-6 col-lg-6">
									<input type="tel" placeholder="Phone Number"/>
								</div>
								<div className="col-md-6 col-lg-6">
									<input type="email" placeholder="Email"/>
								</div>
								<div className="col-md-6 col-lg-6">
									<input type="text" placeholder="Address"/>
								</div>

								<div className="col-md-6 col-lg-6">
									<input type="text" placeholder="City"/>
								</div>

								<div className="col-md-6 col-lg-6">
									<select name="" id="">
										<option value="topic">State</option>
										<option value="topic">Topic</option>
										<option value="topic">Topic</option>
										<option value="topic">Topic</option>
									</select>

								</div>

								<div className="col-md-6 col-lg-6">
									<input type="text" placeholder="Zip"/>
								</div>

								<div className="col-md-12 col-lg-12">
									<div className="submit-btn ">
										<button type="submit">submit</button>
									</div>
								</div>

							</div>
						</form>
					</div>
				</div> */}
			</section>

			<br /><br />
			<Footer/>

		</body>
	)
}


