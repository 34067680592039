/** @format */

// import Login from "../../MobileComponents/Auth/Login";
import { useEffect, useState } from "react";
import Login from "./Auth/Login";
import GetStarted from "./GetStarted";

function MobileHome() {
  const platform = navigator.platform;
  const [getOS, setGetOS] = useState();

  function detectOS() {
    const platform = navigator.platform;
    if (platform.indexOf("Win") !== -1) return "Windows";
    if (platform.indexOf("Mac") !== -1) return "Mac OS";
    if (platform.indexOf("Linux") !== -1) return "Linux";
    if (platform.indexOf("iPhone") !== -1) return "iOS";
    if (platform.indexOf("Android") !== -1) return "Android";
    if (platform.indexOf("iPad") !== -1) return "iPad";
    return "Unknown";
  }

  useEffect(() => {
    //     checkApp();
    //     // checkAppInstalled();
    //     return;

    // const detectedOs = detectOS();
    const OS = detectOS();
    console.log("🚀 ~ useEffect ~ OS:", OS);
    if (OS == "Linux" || OS == "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US";
    } else if (OS == "iOS") {
      window.location.href =
        "https://apps.apple.com/app/friend-verifier-search/id1669432431";
    }
    return;

    try {
      intentURI = "friendverifier://";
      alert(window.location.href === intentURI);
      //   intentURI =
      //   var isSuc = window.location
      //     .assign(intentURI)
      //     .then((result) => {
      //       alert("ll");
      //     })
      //     .catch((err) => {
      //       alert("llll");
      //     });
      //   //     "friendverifier://scan/#friendverifier;scheme=friendverifier://send?#text=text=some%20text;s.browser_fallback_url=https://play.google.com/store/apps/details?id=com.whatsapp;end";
      //   setTimeout(() => {
      //     alert(isSuc);
      //   }, 2000);
    } catch (error) {
      console.log("🚀 ~ file: MobileHome.jsx:45 ~ useEffect ~ error:", error);
    }

    return;

    // if (detectedOs == "Linux" || detectedOs == "iOS") {
    var intentURI;
    const fallbackURL =
      "https://play.google.com/store/apps/details?id=com.yourapp.package"; // Replace with your app's Play Store URL

    // if (detectedOs == "Linux") {
    //   intentURI = "friendverifier://";
    // } else {
    //   intentURI = "com.devusama.FriendVerifier://";
    // }
    // try {
    //   window.location.href = intentURI;
    // } catch (error) {
    //   console.log("🚀 ~ file: MobileHome.jsx:32 ~ useEffect ~ error:", error);
    // }
    //   setTimeout(() => {
    //     window.location.href = fallbackURL; // Redirect to Play Store if the app isn't installed
    //   }, 500);
    // } else {

    // }

    //     setGetOS(detectedOs);
    //     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //     // alert(userAgent);
    //     const intentURI = "friendverifier://";
    //     // const intentURI = "myapp://com.devusama.FriendVerifier/.MainActivity";

    //     // window.location.href = intentURI;

    //     const datat = (window.location.href = intentURI);
    //     // var datat = window.location.replace("friendverifier://");
    //     alert(datat);

    //     // window.location.href = "com.devusama.FriendVerifier://";

    //     // window.location.href =
    //     //   "https://play.google.com/store/apps/details?id={your-package-name}";

    //     // }
  }, []);

  //   function checkApp() {
  //     var intentLink = "friendverifier://";
  //     try {
  //       window.location.href = intentLink;
  //     } catch (error) {
  //       alert("sdfdsf");
  //     }
  //     setTimeout(function () {
  //       // If the intent did not work, it means the app is not installed
  //       // You can handle this accordingly, e.g., display a message or redirect to the app store
  //       //   alert(JSON.stringify(document));
  //       //   if (document.hidden) {
  //       //     alert("App is not installed.");
  //       //   } else {
  //       //     alert("App is installed.");
  //       //   }
  //     }, 2000); // Adjust the timeout duration as needed
  //   }
  const [isAppInstalled, setIsAppInstalled] = useState(null);

  //   useEffect(() => {
  //     alert(isAppInstalled);
  //     console.log("App Installation Status:", isAppInstalled);
  //   }, [isAppInstalled]); //
  //   async function checkAppInstalled() {
  //     const relatedApps = await navigator.getInstalledRelatedApps();
  //     relatedApps.forEach((app) => {
  //       //   console.log(app.id, app.platform, app.url);
  //       alert("ddd");
  //     });

  //     return;
  //     // Create a hidden iframe with the custom URL scheme
  //     var iframe = document.createElement("iframe");
  //     iframe.style.display = "none";
  //     iframe.src = "friendverifier://"; // Replace with your app's custom URL scheme

  //     // Add the iframe to the document
  //     document.body.appendChild(iframe);

  //     // Set a timeout to check if the app is opened
  //     var timeout = setTimeout(() => {
  //       // If the iframe is still in the document, the app didn't open; it's likely not installed
  //       if (document.body.contains(iframe)) {
  //         setIsAppInstalled(false); // Set the state variable to false
  //         // alert("false");
  //       } else {
  //         setIsAppInstalled(true); // Set the state variable to true
  //       }
  //       // Remove the iframe
  //       document.body.removeChild(iframe);
  //     }, 1000); // Adjust the timeout duration as needed (1 second in this example)

  //     // When the app is opened, clear the timeout
  //     window.addEventListener("blur", () => {
  //       clearTimeout(timeout);
  //     });
  //   }

  //   function checkAppInstalled() {
  //     // alert("sdfdsfds");
  //     var appLink = document.getElementById("appLink");
  //     appLink.style.display = "none"; // Hide the link

  //     setTimeout(function () {
  //       alert(appLink);
  //       // If the link is still hidden, the app didn't open; it's likely not installed
  //       if (appLink.style.display === "none") {
  //         alert("App is not installed.");
  //       } else {
  //         alert("App is installed.");
  //       }
  //       appLink.style.display = "block"; // Restore the link
  //     }, 1000); // Adjust the timeout duration as needed (1 second in this example)
  //   }

  // Call the function when the page loads
  //   window.addEventListener("load", checkAppInstalled);

  //   alert(platform);

  //   const [isAppInstalled, setIsAppInstalled] = useState(null);

  return (
    <div>
      {/* <a href="friendverifier://" target="_blank" rel="noopener noreferrer">
        Share on WhatsApp
      </a> */}

      <GetStarted />
    </div>
  );
}

export default MobileHome;
