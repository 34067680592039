/** @format */

import React, { useState, useEffect } from "react";
import userImg from "../../images/user.png";
import phoneImg from "../../images/phone-2.png";
import emailImg from "../../images/email-2.png";
import homeImg from "../../images/home-2.png";
import option from "../../images/options.png";
import DashboardWrapper from "../../Components/DashboardWrapper";
import { LoggedFooter } from "../../Components/Footer";
import LoggedHeader from "../../Components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import locat from "../../images/location.png";
import RFIcon from "../../images/RFIcon.png";
import swal from "sweetalert";
import { axiosPost, axiosGetCall } from "../../utils/axiosRequest";
import LoadingPage from "./LoadingPage";
import ReactPaginate from "react-paginate";
import SearchAreaComp from "../../Components/SearchArea";
import { logOut } from "../../redux/action";

// import Loading from "../../Components/Searching";
import { useDispatch } from "react-redux";
// import { searchDetails } from "../../redux/action";
import Searching from "../../Components/Searching";
import MobileResultPage from "../../MobileComponents/MobileResultPage";
import Loading from "../../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";

function ResultPage() {
  const { state } = useLocation();
  const left = localStorage.left;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [view, setView] = useState(false);
  const [inputState, setInputState] = useState("");
  const [inputName, setInputName] = useState("");
  const [isRefine, setIsRefine] = useState("");
  const [data, setData] = useState([]);

  // const [inputState, setInputState] = useState(location.state.inputState)
  // const [inputName, setInputName] = useState(location.state.inputName)
  // const [isRefine, setIsRefine] = useState(location.state.refine)
  // const items = useSelector((state) => state.searchDetails);
  const items = data.response;
  const [searchData, setSearchData] = useState([]);
  const [details, setDetails] = useState();

  function off() {
    setView(false);
  }
  const [showBuy, setShowBuy] = useState(false);

  // useEffect(() => {
  //   var metaBody = localStorage?.metaBody;
  //   if (metaBody != "null" && metaBody != null) {
  //     metaBody = JSON.parse(metaBody);
  //   handlePageClick(metaBody)
  // }
  // }, []);

  // useEffect(() => {
  //   let NewData = localStorage.searchData;
  //   if (NewData) {
  //     NewData = JSON.parse(NewData);
  //     setData(NewData);
  //   }
  //   if (state?.research) {
  //     var pendingSearchSuccess = localStorage.getItem("pendingSearchSuccess");
  //     if (
  //       pendingSearchSuccess != "null" &&
  //       pendingSearchSuccess != null &&
  //       (pendingSearchSuccess == true || pendingSearchSuccess == "true")
  //       //  &&
  //       //
  //     ) {
  //       handlePageClickPot();
  //     } else {
  //       navigate("/dashboard");
  //       // if (state) {
  //       //   handlePageClick();
  //       // }
  //     }
  //   } else {
  //     if (state) {
  //       handlePageClick();
  //     }
  //   }
  // }, [state]);

  /* mobile screen */
  const [mobileResultPage, setMobileResultPage] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileResultPage(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileResultPage(window.innerWidth <= 575);
  };

  useEffect(() => {
    var metaBody = localStorage?.metaBody;
    if (metaBody != "null" && metaBody != null) {
      metaBody = JSON.parse(metaBody);
      var potentialSearch = localStorage?.potentialSearch;
      if (potentialSearch != null && potentialSearch != "null") {
        var SearchingData = localStorage?.SearchingData;
        if (SearchingData != "null" && SearchingData != null) {
          SearchingData = JSON.parse(SearchingData);
          SearchingData.metaBody.metaBody = { research: true };

          handlePageClickPot(SearchingData?.metaBody);
        } else {
          handlePageClick(metaBody);
        }
      } else {
        var pendingSearch = localStorage?.pendingSearch;
        if (pendingSearch != null && pendingSearch != "null") {
          pendingSearch = JSON.parse(pendingSearch);
          var pendingSearchSuccess = localStorage.getItem(
            "pendingSearchSuccess"
          );
          if (
            pendingSearchSuccess != null &&
            pendingSearchSuccess != "null" &&
            (pendingSearchSuccess == true || pendingSearchSuccess == "true")
          ) {
            search(pendingSearch);
            return;
          } else {
            handlePageClick(metaBody);
          }
        }
        handlePageClick(metaBody);
      }
      // alert(JSON.stringify(metaBody));
    } else {
      var SearchingData = localStorage?.SearchingData;
      if (SearchingData != "null" && SearchingData != null) {
        SearchingData = JSON.parse(SearchingData);
        SearchingData.metaBody.metaBody = { research: true };
        var Id = localStorage.getItem("pendingSearch");
        var pendingSearchSuccess = localStorage.getItem("pendingSearchSuccess");
        if (Id != "null" && Id != null) {
          Id = JSON.parse(Id);
          if (
            pendingSearchSuccess != "null" &&
            pendingSearchSuccess != null
            //  &&
            //
          ) {
            pendingSearchSuccess = JSON.parse(pendingSearchSuccess);
            if (pendingSearchSuccess == true) {
              handlePageClickPot(SearchingData?.metaBody);
            } else {
              navigate("/dashboard");
            }
          }
        } else {
          handlePageClickPot(SearchingData?.metaBody);
        }

        // alert(2);
        return;
      } else {
      }
      navigate("/dashboard");
    }
  }, [localStorage?.metaBody]);

  useEffect(() => {
    // var Id = localStorage.getItem("pendingSearch");
    // var pendingSearchSuccess = localStorage.getItem("pendingSearchSuccess");
    // if (Id != "null" && Id != null) {
    //   Id = JSON.parse(Id);
    //   if (
    //     pendingSearchSuccess != "null" &&
    //     pendingSearchSuccess != null
    //     //  &&
    //     //
    //   ) {
    //     pendingSearchSuccess = JSON.parse(pendingSearchSuccess);
    //     if (pendingSearchSuccess == true) {
    //       search(Id);
    //     }
    //   }
    // }
  }, []);

  function Alert() {
    return (
      <div id="alerts">
        <div className="alert">
          <div>To view this record one of your credits will be deducted</div>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView(false)}
            >
              Cancel
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={() => search(details)}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    );
  }

  // function off(){
  //     setView(false)
  // }

  // function setView(true){
  //         setView(true)
  // }

  function search(data) {
    setView(false);
    localStorage.setItem("personalData", JSON.stringify(data));
    // setIsLoading(true);
    // alert(JSON.stringify(data));
    // return;

    const id = localStorage["@loginDetails"];
    if (id === null) {
      swal({
        title: "Friend Verifier",
        text: "In order to perform a search you must be logged in. Please login or create an account!",
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
      }).then(() => {
        navigate("/login-signup");
      });
    } else {
      setIsLoading(true);
      setIsSearching(true);
      if (left !== 0) {
        const header = {
          headers: {
            Authorization: `Bearer ${localStorage.tokken}`,
          },
        };
        const url = "/search/searchCluster";
        // alert(JSON.stringify(data?._source));
        // return;
        if (data?._source) {
          data._source.age = data?.age ? data?.age : "";
          data._source.searchType = data?.age ? data?.age : "";
          data._source.formattedDob = data?.formattedDob
            ? data?.formattedDob
            : "";
          if (data?._index) {
            var d0b = data?._index.includes("dob");
            if (d0b) {
              data._source.index = "dob";
              // data._source.index = "address";
            } else {
              data._source.index = "email";
            }
          }
        }
        setTimeout(() => {
          axiosPost(url, data, "", (callBack) => {
            console.log("🚀 ~ axiosPost ~ callBack:", JSON.stringify(callBack));
            setView(false);
            setIsSearching(false);
            setIsLoading(false);

            if (callBack?.status === 200) {
              setIsLoading(false);
              // setIsSearching(false);
              localStorage.setItem("pendingSearch", null);
              localStorage.setItem("pendingSearchSuccess", null);
              navigate("/record-page", { state: callBack?.data });
            } else if (callBack?.status === 401) {
              swal({
                title: "Unauthorized Access Detected",
                text: callBack.message,
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top",
                buttons: {
                  confirm: {
                    text: "Log out",
                    className: "red-button",
                  },
                },
                dangerMode: true,
              }).then(() => {
                dispatch(logOut());
                navigate("/login-signup");
              });
            } else {
              setView(false);
              // setIsSearching(false);
              setIsLoading(false);
              // swal(callBack.message);
              // navigate(-1);
              if (callBack?.status === 400) {
                if (
                  callBack?.message ===
                  "No Search Available, Please Purchase a plan!"
                ) {
                  localStorage.setItem("pendingSearch", JSON.stringify(data));
                  setShowBuy(true);
                  swal({
                    title: "Pay As You Go",
                    text: "You do not have a subscription, this report will cost $4.99",
                    showCancelButton: true,
                    closeOnConfirm: true,
                    animation: "slide-from-top",
                    showDenyButton: true,
                    confirmButtonColor: "#DD6B55",
                  }).then(() => {
                    navigate("/subscriptionPage", { state: data });
                  });
                } else {
                  swal(callBack?.message);
                }
              } else if (callBack?.status === 401) {
                swal({
                  title: "Unauthorized Access Detected",
                  text: callBack.message,
                  showCancelButton: true,
                  closeOnConfirm: false,
                  animation: "slide-from-top",
                  buttons: {
                    confirm: {
                      text: "Log out",
                      className: "red-button",
                    },
                  },
                  dangerMode: true,
                }).then(() => {
                  dispatch(logOut());
                  navigate("/login-signup");
                });
              } else {
                swal("Record is currently unavailable due to maintenance.");
              }
            }
          });
        }, 100);

        // alert(JSON.stringify(data));
      } else {
        setIsLoading(false);
        setIsSearching(false);
        swal("Please Subscribe you have no Search Left");
        navigate("/subscriptionPage");
      }
    }
  }

  const PER_PAGE = 10;
  console.log(items);

  const [isPhone, setIsPhone] = useState(false);
  const [Email, setEmail] = useState(false);
  const handlePageClick = (metaBody) => {
    var exists =
      metaBody?.fields.filter(function (o) {
        return o.hasOwnProperty("phone");
      }).length > 0;
    setIsPhone(exists);
    var emailExist =
      metaBody?.fields.filter(function (o) {
        return o.hasOwnProperty("email");
      }).length > 0;
    setEmail(emailExist);

    setIsSearching(true);
    // const obj = JSON.parse(localStorage.obj);
    // console.log(obj);
    const url = `/search/openSearch`;

    axiosPost(url, metaBody, "", (callBack) => {
      console.log("Searching");
      if (callBack?.status === 200) {
        setSearchData(callBack.data.response);
        setIsSearching(false);
      } else if (callBack?.status === 401) {
        swal({
          title: "Unauthorized Access Detected",
          text: callBack.message,
          showCancelButton: true,
          closeOnConfirm: false,
          animation: "slide-from-top",
          buttons: {
            confirm: {
              text: "Log out",
              className: "red-button",
            },
          },
          dangerMode: true,
        }).then(() => {
          dispatch(logOut());
          navigate("/login-signup");
        });
      } else {
        setIsSearching(false);
        swal(callBack.message);
      }
    });
  };
  const handlePageClickPot = (metaBody) => {
    // JSON.stringify(metaBody);
    // return;
    // state.metaBody.metaBody = { research: true };
    // alert(JSON.stringify(state?.metaBody));
    // return;
    setIsSearching(true);
    search(metaBody);
    return;
    const obj = JSON.parse(localStorage.obj);
    console.log(obj);
    var url = "/search/searchCluster";

    if (!state?.research) {
      state.metaBody = obj;
    } else {
      state.metaBody = { research: true };
    }

    axiosPost(url, state?.metaBody, (callBack) => {
      console.log("Searching");
      if (callBack?.status === 200) {
        setSearchData(callBack.data.response);
        setIsSearching(false);
      } else {
        setIsSearching(false);
        swal(callBack.message);
      }
    });
  };

  const alreadyViewed = (val) => {
    RecentUserView(val?.viewed?._id);
    // setView(true);
    return;
  };

  const RecentUserView = (_id) => {
    // if (userData?.hasOwnProperty("authToken")) {
    var url = "/search/getHistory?_id=" + _id;
    setIsLoading(true);
    setIsSearching(true);
    axiosGetCall(url, (callBack) => {
      setIsLoading(false);
      setIsSearching(false);
      console.log(
        "🚀 ~ axiosGetCall ~ callBack:",
        JSON.stringify(callBack?.data?.data)
      );
      navigate("/record-page", { state: callBack?.data?.data[0]?.search });
      // navigation.navigate("ResultDeatilScreen", {
      //   data: callBack?.data[0]?.search,
      //   newData: SelectedData,
      //   requestNew: callBack?.data[0]?.search,
      // });
    });
    // }
  };

  const currentPageData = searchData?.map((item, index) => (
    <div
      className="result-box pointer"
      onClick={() => {
        if (item?.viewed?.date) {
          alreadyViewed(item);
          return;
        }
        setView(true);
        if (state?.research) {
          // item = Object.assign(item, state);
          item.metaBody = { research: true };
        } else {
          // data.metaBody = state?.metaBody;
          // const mergeArray = { ...data.metaBody, ...state?.metaBody };
          // alert(JSON.stringify(state?.metaBody));
          item = Object.assign(item, state);
          console.log(
            "🚀 ~ file: ResultPage.jsx:270 ~ ResultPage ~ item:",
            JSON.stringify(item)
          );
          // console.log(
          //   "🚀 ~ file: ResultPage.jsx:130 ~ search ~ mergeArray:",
          //   JSON.stringify(Ndata)
          // );
        }
        setDetails(item);
      }}
    >
      {item?.viewed?.date && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            background: "#415192",
            padding: "5px 10px",
            fontSize: "12px",
            fontWeight: 500,
            color: "white",
          }}
        >
          Viewed on : {new Date(item?.viewed?.date).toDateString()}
        </div>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div className="result-left">
          {item?.viewed?.date && <br />}
          <h4>
            {item?._source.FIRST_NAME} {item?._source?.MIDDLE_NAME}{" "}
            {item?._source.LAST_NAME}
          </h4>
          <p>
            <FontAwesomeIcon
              icon={faLocationDot}
              className="fontAwesomeIcon_img"
              style={{ color: "#c4c7d2", marginRight: "5px" }}
            />
            {item?._source.CITY}
            {/* {typeof(item?._source.STATE)} */}
            {item?._source?.STATE?.trim() && ", " + item?._source.STATE}
            {/* : "Not Found" */}
          </p>
        </div>
        <div className="result-right">
          <h4 className="resultAge">
            {item?.age ? (
              <>
                {item?.age} <br /> <span>Years Old</span>{" "}
              </>
            ) : (
              <p></p>
            )}
          </h4>
          {/* <p style={{color:'white', margin:'0px'}}>  .</p>
                <Link className="d-none d-sm-inline-block Link" onClick={() => {setView(true)}} >View Report</Link> */}
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <span>
            <FontAwesomeIcon
              icon={faEnvelope}
              className="fontAwesomeIcon_img"
              style={{ color: "#000", width: "15px", marginRight: "5px" }}
            />
            {item?.emails?.length} Email Addresses
          </span>
          <span>
            <FontAwesomeIcon
              icon={faMobileScreen}
              className="fontAwesomeIcon_img"
              style={{ color: "#000", width: "10px", marginRight: "5px" }}
            />
            {item?.phones?.length} Phone Numbers
          </span>
        </div>
        <hr />
        <Link
          className="d-none d-sm-inline-block Link"
          onClick={() => {
            // setView(true);
            // setDetails(item);
          }}
        >
          View Report
        </Link>
      </div>
    </div>
  ));

  const pageCount = Math.ceil((data.count > 100 ? 100 : data.count) / PER_PAGE);

  function SearchArea() {
    // const data = (useSelector((state) => state.loginDetails));
    // const data = localStorage.id;
    // console.log(data);
    // const navigate = useNavigate();
    // const object = JSON.parse(localStorage.obj)
    // console.log(object);
    // const fname = object.fields[firstName]
    // console.log(fname);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [age, setAge] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");

    function toggleDisplay(hideClass, showClass, showColor, hideColor) {
      setAge("");
      setCity("");
      setPhone("");
      setState("");
      setEmail("");
      setStreet("");
      setLastName("");
      setFirstName("");
      setMiddleName("");

      const hideElements = document.getElementsByClassName(hideClass);
      const showElements = document.getElementsByClassName(showClass);
      const hideColors = document.getElementsByClassName(hideColor);
      const showColors = document.getElementsByClassName(showColor);

      for (let i = 0; i < hideColors.length; i++) {
        hideColors[i].style.color = "gray";
        hideColors[i].style.filter = "grayscale(100%)";
        hideColors[i].style.opacity = ".7";
      }
      for (let i = 0; i < showColors.length; i++) {
        showColors[i].style.color = "#3b5997";
        showColors[i].style.filter = "grayscale(0%)";
        showColors[i].style.opacity = "2";
      }

      for (let i = 0; i < hideElements.length; i++) {
        hideElements[i].style.display = "none";
      }

      for (let i = 0; i < showElements.length; i++) {
        showElements[i].style.display = "block";
      }
    }

    function check(event, obj) {
      // alert(JSON.stringify(event) + " . " + JSON.stringify(obj));
      event.preventDefault();
      setIsSearching(true);

      if (
        (obj === "fname" && lastName === "") ||
        (obj === "fname" && lastName === null)
      ) {
        swal("First and last name fields are required.");
        setIsSearching(false);
      } else if (
        (obj === "fname" && firstName === "") ||
        (obj === "fname" && firstName === null)
      ) {
        swal("First and last name fields are required.");
        setIsSearching(false);
      } else if (obj === "phone" && phone === "") {
        swal("Please enter a phone number.");
        setIsSearching(false);
      } else if (obj === "email" && email === "") {
        swal(
          "Please enter a correctly formatted email address (name@domain.com)."
        );
        setIsSearching(false);
      } else if (obj === "add" && state === "") {
        swal(
          "The street name, city and state are required fields. Please try again"
        );
        setIsSearching(false);
      } else if (obj === "add" && street === "") {
        swal(
          "The street name, city and state are required fields. Please try again"
        );
        setIsSearching(false);
      } else if (obj === "add" && city === "") {
        swal(
          "The street name, city and state are required fields. Please try again"
        );
        setIsSearching(false);
      } else {
        var fields = [];
        if (firstName) {
          fields.push({ firstName: firstName });
          setInputName(firstName + " " + lastName);
        }
        if (middleName) {
          fields.push({ middleName: middleName });
        }
        if (lastName) {
          fields.push({ lastName: lastName });
        }
        if (age) {
          fields.push({ age: age });
        }
        if (city) {
          fields.push({ city: city });
        }
        if (state) {
          fields.push({ state: state });
          setInputState(state);
        }
        if (phone) {
          fields.push({ phone: phone });
        }
        if (email) {
          fields.push({ email: email });
        }
        if (street) {
          fields.push({ street: street });
        }

        var object = {
          fields: fields,
        };

        localStorage.setItem("obj", JSON.stringify(obj));
        const url = `/search/openSearch?pageNo=1&pageSize=10`;

        axiosPost(url, object, "", (callBack) => {
          console.log("Searching");
          if (callBack?.status === 200) {
            // localStorage.setItem("searchData", JSON.stringify(callBack.data));
            setSearchData(callBack.data.response);
            setIsSearching(false);
          } else if (callBack?.status === 401) {
            swal({
              title: "Unauthorized Access Detected",
              text: callBack.message,
              showCancelButton: true,
              closeOnConfirm: false,
              animation: "slide-from-top",
              buttons: {
                confirm: {
                  text: "Log out",
                  className: "red-button",
                },
              },
              dangerMode: true,
            }).then(() => {
              dispatch(logOut());
              navigate("/login-signup");
            });
          } else {
            setIsSearching(false);
            alert(callBack.message);
          }
        });
      }
    }

    return (
      <div>
        {/* <div className="friend-searchbar">
          <ul>
            <li className="li a">
              <a
                className="ns eas aas pas"
                onClick={() => toggleDisplay("sbn", "sn", "ns", "nas")}
                style={{ color: "#3b5997" }}
              >
                <img className="ns eas aas pas img" src={userImg} alt="" />
                Search by Name
              </a>
            </li>

            <li className="d-none d-lg-block"> | </li>

            <li className="li a">
              <a
                className="ps eas aas nas"
                onClick={() => toggleDisplay("sbp", "sp", "ps", "pas")}
                style={{ filter: "grayscale(100%)", opacity: "0.7" }}
              >
                <img className="ps eas aas nas img" src={phoneImg} alt="" />
                Search by Phone
              </a>
            </li>

            <li className="d-none d-lg-block"> | </li>

            <li className="li a">
              <a
                className="es pas aas nas"
                onClick={() => toggleDisplay("sbe", "se", "es", "eas")}
                style={{ filter: "grayscale(100%)", opacity: "0.7" }}
              >
                <img className="es pas aas nas img" src={emailImg} alt="" />
                Search by Email
              </a>
            </li>

            <li className="d-none d-lg-block"> | </li>

            <li className="li a">
              <a
                className="as pas eas nas"
                onClick={() => toggleDisplay("sba", "sa", "as", "aas")}
                style={{ filter: "grayscale(100%)", opacity: "0.7" }}
              >
                <img className="as pas aas nas img" src={homeImg} alt="" />
                Search by Address
              </a>
            </li>
          </ul>
        </div> */}

        {/* <form
          className="sn sbp sbe sba"
          onSubmit={(event) => {
            check(event, "fname");
            setIsRefine("name");
          }}
        >
          <div className="search-box" id="search-box">
            <div className="search-item">
              <input
                type="text"
                id="focus"
                placeholder="First Name"
                name="fName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div
              id="middle"
              style={{ display: "none" }}
              className="search-item"
            >
              <input
                type="text"
                placeholder="Middle Name"
                name="lName"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              />
            </div>

            <div className="search-item">
              <input
                type="text"
                placeholder="Last Name"
                name="lName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div id="age" style={{ display: "none" }} className="search-item">
              <input
                type="text"
                placeholder="Age"
                name="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>

            <div className="search-item">
              <input
                type="text"
                placeholder="City"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className="search-item">
              <select
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                <option value="" defaultChecked>
                  All States
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>

            <div className="search-item search-btn">
              <div id="search">
                <button type="submit">Search</button>
              </div>
              <box
                id="option"
                className="option"
                onClick={() => {
                  document
                    .getElementById("option")
                    .classList.toggle("option-b");
                  refine();
                }}
              >
                <img src={option} alt="option" width={"25px"} />
              </box>
            </div>
          </div>
        </form> */}
        {/* <form
          className="sp sbn sba sbe"
          onSubmit={(event) => {
            check(event, "phone");
            setIsRefine("");
          }}
        >
          <div className="search-box" id="search-box">
            <div className="search-item ">
              <input
                type="number"
                id="focus"
                placeholder="Phone Number"
                maxLength="10"
                name="phone"
                style={{ width: "100%", MozAppearance: "textfield" }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="search-item search-btn">
              <div>
                <button type="submit">Search</button>
              </div>
            </div>
          </div>
        </form>
        <form
          className="se sbp sbn sba"
          onSubmit={(event) => {
            check(event, "email");
            setIsRefine("");
          }}
        >
          <div className="search-box" id="search-box">
            <div className="search-item">
              <input
                type="email"
                id="focus"
                placeholder="E-mail"
                pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                name="email"
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="search-item search-btn">
              <div>
                <button type="submit">Search</button>
              </div>
            </div>
          </div>
        </form>
        <form
          className="sa sbp sbe sbn"
          onSubmit={(event) => {
            check(event, "add");
            setIsRefine("");
          }}
        >
          <div className="search-box" id="search-box">
            <div className="search-item">
              <input
                type="text"
                id="focus"
                placeholder="Street Address"
                name="street"
                style={{ width: "100%" }}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>

            <div className="search-item">
              <input
                type="text"
                placeholder="City"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className="search-item">
              <select
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                <option value="" defaultChecked>
                  All States
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>

            <div className="search-item search-btn">
              <div>
                <button type="submit">Search</button>
              </div>
            </div>
          </div>
        </form> */}
        <SearchAreaComp />
      </div>
    );
  }

  function refine() {
    document.getElementById("focus").focus();
    const age = document.getElementById("age");
    const middle = document.getElementById("middle");
    if (age.style.display === "block") {
      age.style.display = "none";
      middle.style.display = "none";
    } else {
      age.style.display = "block";
      middle.style.display = "block";
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  }

  const getFirstLatter = (text) => {
    return text?.charAt(0);
  };

  const toTitleCase = (str) => {
    if (!str) return str;
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getStateName = (state) => {
    const data = options.find((items) => {
      return items.abbreviation == state;
    });
    return data?.name;
  };

  const options = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const ItemForPhone = (item) => {
    var items = item;
    var item = item?.item?._source;
    item.age = item?.item?.age;
    item.formattedDob = item?.item?.formattedDob;

    var d0b = items.item?._index.includes("dob");
    if (d0b) {
      items.item._source.index = "dob";
      // data._source.index = "address";
    } else {
      items.item._source.index = "email";
    }

    var newItem = items?.item;
    // alert(JSON.stringify(state?.metaBody));
    // item.metaBody = state?.metaBody;
    // items.item.metaBody = state?.metaBody;
    Object.assign(newItem, state);
    // if (d0b) {
    //   item.index = "dob";
    //   item.type = "dob";
    // } else {
    //   item.index = "email";
    //   item.type = "email";
    // }
    items.item._source = item;
    // items.item._source.index = items?.item?.type;
    // alert(JSON.stringify(items?.item?._source?.index));
    return (
      <>
        {searchData[0]?.viewed?.date && (
          <div
            style={{
              // position: "absolute",
              top: "0px",
              left: "0px",
              background: "#415192",
              padding: "5px 10px",
              fontSize: "12px",
              fontWeight: 500,
              color: "white",
              zIndex: 1,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            Viewed on : {new Date(searchData[0]?.viewed?.date).toDateString()}
          </div>
        )}
        <div
          className="recent-serchbox2"
          style={{ textAlign: "left" }}
          // key={item._id}
          onClick={() => {
            // navigate("/record-page", { state: item?.search });
            // console.log(
            //   "🚀 ~ file: Dashboard.jsx:89 ~ Dashboard ~ JSON.stringify(item):",
            //   JSON.stringify(item)
            // );
          }}
        >
          <div className="recent-srcnt">
            {Email ? (
              <>
                <h3>{item?.EMAIL}</h3>
                <br />
                <div>
                  <p>Full Name</p>
                  {item?.FIRST_NAME ? getFirstLatter(item?.FIRST_NAME) : "-"}
                  {""}
                  ********
                </div>
                <br />
                <div>
                  <p>Location</p>
                  {toTitleCase(item?.CITY)}
                  {toTitleCase(item?.STATE)
                    ? ", " + getStateName(item?.STATE)
                    : null}
                </div>
              </>
            ) : (
              <>
                <h3>{formatPhoneNumber(item?.PHONE)}</h3>
                <br />
                <div>
                  <p>Full Name</p>
                  {item?.FIRST_NAME ? getFirstLatter(item?.FIRST_NAME) : "-"}
                  {""}
                  ********
                </div>
                <br />
                <div>
                  <p>Location</p>
                  {toTitleCase(item?.CITY)}
                  {toTitleCase(item?.STATE)
                    ? ", " + getStateName(item?.STATE)
                    : null}
                </div>
              </>
            )}
            <br />

            <div
              style={{
                width: 300,
                height: 50,
                backgroundColor: "#6DAB45",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "white",
                borderRadius: 5,
              }}
              className="pointer"
              onClick={() => {
                if (items?.item?.viewed?.date) {
                  alreadyViewed(items?.item);
                  return;
                }
                setView(true);
                setDetails(items?.item);
              }}
            >
              View Report
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* {isLoading ? <Loading /> : false} */}
      {mobileResultPage ? (
        <>
          {isSearching ? <Searching /> : false}
          {view ? <Alert /> : false}

          <MobileResultPage
            Email={Email}
            isPhone={isPhone}
            searchData={searchData}
            setView={setView}
            setDetails={setDetails}
            state={state}
            ItemForPhone={ItemForPhone}
          />
        </>
      ) : (
        <>
          <div>
            {isSearching ? <Searching /> : false}
            {view ? <Alert /> : false}
            <LoggedHeader />

            <div className="dashboard-wrapper">
              <div className="container">
                <SearchArea />

                <div className="row">
                  <div className="col-md-4">
                    <DashboardWrapper />
                  </div>
                  <div className="col-md-8">
                    {isRefine !== "" ? (
                      <div className="result-part">
                        <div className="result-heading">
                          <p>
                            {data.count} Results found for{" "}
                            <b> {inputName.toUpperCase()} </b> in{" "}
                            <b>
                              {inputState === "" ? "All State" : inputState}
                            </b>
                            <br />
                            <a onClick={refine}>Refine Search</a>
                          </p>
                        </div>
                      </div>
                    ) : (
                      false
                    )}

                    <div className="result-title">
                      {!Email && !isPhone && (
                        <h2>{searchData?.length} Results</h2>
                      )}
                    </div>
                    <div className="pagination"></div>
                    <br />
                    {Email || isPhone ? (
                      <>
                        {searchData?.length > 0 ? (
                          <>
                            <div
                              onClick={() => {
                                // setView(true);
                                // setDetails(item);
                              }}
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    src={RFIcon}
                                    alt=""
                                    style={{ height: 20, width: 25 }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 10,
                                    marginLeft: 10,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 16,
                                    }}
                                  >
                                    Record Found
                                  </p>
                                </div>
                              </div>
                            </div>
                            <br />
                            {searchData?.length > 0 && (
                              <ItemForPhone item={searchData[0]} />
                            )}
                          </>
                        ) : (
                          <div className="result-title">
                            <h2>0 Results</h2>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <>{currentPageData}</>
                      </>
                    )}
                  </div>
                </div>

                <p></p>
              </div>
            </div>
            <LoggedFooter />
          </div>
        </>
      )}
    </>
  );
}

export default ResultPage;

// 5162256467
