/** @format */

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LoggedFooter } from "../Components/Footer";
import LoggedHeader from "../Components/Header";
import swal from "sweetalert";
import {
  axiosDelete,
  axiosGetCall,
  axiosPost,
  axiosPut,
} from "../utils/axiosRequest";
import { logOut } from "../redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SupportTicketChat from "../MobilePages/menuPages/supportAndLegal.jsx/SupportTicketChat";

function ContatUs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const location = useLocation();
  const [view, setView] = useState(false);
  const [type, setType] = useState(-1);
  const [mobileTicket, setMobileTicket] = useState(false);

  useEffect(() => {
    getTickets();
  }, []);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileTicket(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileTicket(window.innerWidth <= 575);
  };

  const getTickets = () => {
    var url = "/ticket/getComments/" + location?.state?.id;
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    axiosGetCall(url, (callBack) => {
      if (callBack?.data?.status == 200) {
        var messagesArr = callBack.data?.data?.map((item, index) => {
          return {
            id: item?.id,
            sent:
              item?.via?.channel == "api"
                ? true
                : item?.via?.channel == "web"
                ? false
                : true,
            msg: item?.body,
            date_time: moment(item?.created_at).format("DD/MM/YYYY hh:mm A"),
          };
        });
        setMessages(messagesArr);
      } else if (callBack?.status === 401) {
        swal({
          title: "Unauthorized Access Detected",
          text: callBack.message,
          showCancelButton: true,
          closeOnConfirm: false,
          animation: "slide-from-top",
          buttons: {
            confirm: {
              text: "Log out",
              className: "red-button",
            },
          },
          dangerMode: true,
        }).then(() => {
          dispatch(logOut());
          navigate("/login-signup");
        });
      }
    });
  };

  const send = () => {
    const msg = document.getElementById("sf").value;
    if (msg.length > 0) {
      var messagess = messages;
      var obj = {
        id: Math.floor(Math.random() * 99999999999999999 + 0),
        sent: true,
        msg: msg,
        date_time: moment().format("DD/MM/YYYY hh:mm A"),
      };
      messagess = [...messages, obj];
      setMessages(messagess);

      var url = `/ticket/createComment/` + location?.state?.id;
      var obj = {
        comment: msg,
      };

      axiosPost(url, obj, "", (callBack) => {});
    }
  };

  const DeleteTicketFun = () => {
    const url = "/ticket/" + location?.state?.id;
    axiosDelete(url, (callBack) => {
      if (callBack?.status == 200) {
        alert(callBack?.data);
        // simpleAlertCall(callBack?.data, () => {
        navigate(-1);
        // });
      } else {
        alert("Something wrong.");
      }
    });
  };
  const CloseTicketFun = () => {
    const url = "/ticket/close/" + location?.state?.id;
    axiosPut(url, {}, (callBack) => {
      if (callBack?.status == 200) {
        alert(callBack?.data);
        navigate(-1);
        // });
      } else {
        alert("Something wrong.");
      }
    });
  };

  function Warning() {
    return (
      <div id="alerts">
        <div className="alert">
          <h4>
            {type == 1
              ? "Do you want to close the ticket?"
              : "Do you want to delete the ticket?"}
          </h4>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => {
                setView(false);
              }}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={() => {
                if (type == 1) {
                  CloseTicketFun();
                } else if (type == 2) {
                  DeleteTicketFun();
                }
              }}
            >
              <h5 style={{ margin: "0px" }}>Yes</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }

  const Chat = () => {
    return (
      <>
        <div class="container">
          {messages?.map((item, index) => (
            <>
              {item?.sent === false ? (
                <>
                  <div style={{ borderRadius: 5 }} class="item">
                    {item?.msg}
                  </div>
                  <p
                    style={{
                      fontSize: 13,
                      color: "#8E8E8E",
                      fontFamily: "Medium",
                      marginTop: 8,
                      marginHorizontal: 35,
                    }}
                  >
                    {item?.date_time}
                  </p>
                </>
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: 5,
                      color: "#fff",
                    }}
                    class="item AlignRight"
                  >
                    {item?.msg}
                  </div>
                  <p
                    style={{
                      fontSize: 13,
                      color: "#8E8E8E",
                      fontFamily: "Medium",
                      marginTop: 8,
                      marginHorizontal: 35,
                    }}
                    class="AlignRight2"
                  >
                    {item?.date_time}
                  </p>
                </>
              )}
            </>
          ))}
        </div>
        <div
          style={{
            height: 60,
            width: "100%",
            backgroundColor: "white",
            bottom: 10,
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {location?.state?.status == "closed" ? (
              <div
                style={{
                  height: 80,
                  width: "105%",
                  borderRadius: 10,
                  backgroundColor: "#F4F1F2",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  Ticket is Closed, Please feel free to create new ticket for
                  further queries/requests.
                </p>
              </div>
            ) : (
              <>
                <input
                  style={{
                    height: 60,
                    width: "95%",
                    border: "1px solid gray",
                    borderRadius: 10,
                    backgroundColor: "#F4F1F2",
                    paddingLeft: 10,
                  }}
                  id="sf"
                  type="text"
                  placeholder="Type here..."
                  required
                />
                <div
                  style={{
                    position: "absolute",
                    right: 250,
                    bottom: 30,
                    height: 25,
                    width: 25,
                  }}
                >
                  <form onSubmit={send}>
                    <div className="search-item search-btn">
                      <div>
                        <button onClick={send} type="submit">
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {view ? <Warning /> : false}

      {mobileTicket ? (
        <SupportTicketChat
          data={location?.state}
          messages={messages}
          setMessages={setMessages}
          send={send}
        />
      ) : (
        <>
          <LoggedHeader />
          <div
            className="contact-area"
            id="contact"
            style={{
              padding: " 20px 0 45px",
              minHeight: "70vh",
            }}
          >
            {location?.state?.status != "closed" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: 20,
                    marginRight: 30,
                  }}
                >
                  <a
                    onClick={() => {
                      setView(true);
                      setType(1);
                    }}
                    style={{ marginRight: 20 }}
                  >
                    Close Ticket
                  </a>
                  <a
                    onClick={() => {
                      setView(true);
                      setType(2);
                    }}
                    style={{ color: "red" }}
                  >
                    Delete Ticket
                  </a>
                </div>
                <hr />
              </>
            )}

            <Chat />
          </div>
        </>
      )}
    </div>
  );
}

export default ContatUs;
