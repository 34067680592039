/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCircleInfo,
  faArrowLeft,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import DashboardWrapper from "../../Components/DashboardWrapper";
import { LoggedFooter } from "../../Components/Footer";
import LoggedHeader from "../../Components/Header";
import SearchArea from "../../Components/SearchArea";
import r1 from "../../images/r-1.png";
import r2 from "../../images/r-2.png";
import r3 from "../../images/r-3.png";
import r4 from "../../images/r-4.png";
import r5 from "../../images/r-5.png";
import r6 from "../../images/r-6.png";
import AlertIcon from "../../images/alert.png";
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { metaBody } from "../../redux/action";
import MobileRecordPage from "../../MobileComponents/MobileRecordPage";
import Loading from "../../Components/Loading";
import { axiosPost } from "../../utils/axiosRequest";
import CopyToClipboard from "react-copy-to-clipboard";
import swal from "sweetalert";

function RecordDisplay() {
  const personData = JSON.parse(localStorage.getItem("personData"));
  const personalData = JSON.parse(localStorage.getItem("personalData"));
  const criminalData = JSON.parse(localStorage.getItem("criminalData"));
  // const sexOffendedData = JSON.parse(localStorage.getItem('sexOffendedData'));
  const bankRuptciesData = JSON.parse(localStorage.getItem("bankRuptciesData"));
  var date = "personData.DOB.toString()";
  // var date = personData.DOB.toString();
  const year = date.substring(0, 4);
  const monthNo = date.substring(4, 6) - 1;
  const day = date.substring(6, 8);
  const data = useLocation();
  const [state, setState] = useState(data?.state);
  const dispatch = useDispatch();

  /* mobile screen */
  const [isLoading, setIsLoading] = useState(false);
  const [mobileRecordPage, setMobileRecordPage] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileRecordPage(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileRecordPage(window.innerWidth <= 575);
  };

  console.log(
    "🚀 ~ file: RecordDisplay.jsx:27 ~ RecordDisplay ~ state:",
    JSON.stringify(state)
  );
  const navigate = useNavigate();

  useEffect(() => {
    function load() {
      window.location.reload();
    }
    localStorage.setItem("SearchingData", null);
  }, []);

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let month = months[monthNo];
  console.log(date);
  var cD = false;
  var bD = false;
  var pA = false;
  // var sD = (false);

  //   if (criminalData === "No Data Found!") {
  //     cD = false;
  //   } else {
  //     cD = true;
  //   }
  //   if (bankRuptciesData === "No Data Found!") {
  //     bD = false;
  //   } else {
  //     bD = true;
  //   }
  // if(sexOffendedData === "No Data Found!"){
  //     sD = (false);
  // }else{
  //     sD = (true)
  // }

  function hide(val) {
    const div = document.getElementById(val);
    if (div.style.display === "none") {
      div.style.display = "block";
    } else {
      div.style.display = "none";
    }
  }

  const toTitleCase = (str) => {
    if (!str) return str;
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const options = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const getStateName = (state) => {
    state = state.toUpperCase();
    const data = options.find((items) => {
      return items.abbreviation == state;
    });
    return data?.name;
  };

  const [ViewAlert, setViewAlert] = useState(false);
  const [SearchingData, setSearchingData] = useState({});

  // navigate("/result-page");

  const PotentialSearch = () => {
    // state: {
    //   //   data: callBack.data,
    //   //   inputState: state,
    //   //   inputName: name,
    //   metaBody: obj,
    //   //   research: true,
    //   //   firstName,
    //   //   lastName,
    //   //   state,
    //   //   email,
    //   //   phone,
    //   //   street,
    //   //   city,
    // },
    navigate("/result-page", SearchingData);
    localStorage.setItem("potentialSearch", true);
    localStorage.setItem("pendingSearchSuccess", true);
    localStorage.setItem("SearchingData", JSON.stringify(SearchingData?.state));
    // Object.assign(SearchingData?.state?.metaBody, { research: true });

    // // var newObj = [{...research: true,SearchingData?.state?.metaBody}]
    // SearchingData.state.research = true;
    // dispatch(
    //   metaBody({
    //     payload: SearchingData?.state,
    //   })
    // );
  };

  function convertInches(inches) {
    let feetFromInches = Math.floor(inches / 12);
    let inchesRemainder = inches % 12;

    let result = feetFromInches + "'" + inchesRemainder;
    return result;
  }

  function Alert({ type = 1 }) {
    if (type == 1) {
      return (
        <div id="alerts">
          <div className="alert">
            <div>To view this record one of your credits will be deducted</div>
            <div className="buttons">
              <button
                className="login-btn loginactive Link"
                onClick={() => setViewAlert(false)}
              >
                Cancel
              </button>
              <button
                className="login-btn loginactive Link"
                onClick={() => PotentialSearch()}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      );
    } else if (type == 2) {
      return (
        <div id="alerts">
          <div className="alert">
            <div>{message}</div>
            <div className="buttons">
              {/* <button
            className="login-btn loginactive Link"
            onClick={() => setViewAlert(false)}
          >
            Cancel
          </button> */}
              <button
                className="login-btn loginactive Link"
                onClick={() => setViewAlert(false)}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  const [message, setMessage] = useState("");
  const [optLoading, setOptLoading] = useState(false);

  const removalRequestFun = () => {
    localStorage.getItem("@loginDetails");
    var url = `/optout/create`;

    var obj = {
      userId: JSON.parse(localStorage.getItem("@loginDetails"))?.user?._id,
      report_Request: state,
      report_Response: state,
    };

    setOptLoading(true);
    axiosPost(url, obj, "", (callBack) => {
      setOptLoading(false);
      if (callBack.status == 200) {
        setMessage(callBack?.message);
        setViewAlert(true);
      } else {
        setMessage(callBack?.message);
        setViewAlert(true);
      }
      console.log("🚀 ~ axiosPost ~ callBack:", callBack);
    });
  };

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileRecordPage ? (
        <>
          {ViewAlert ? <Alert /> : null}
          <MobileRecordPage
            state={state}
            toTitleCase={toTitleCase}
            getStateName={getStateName}
            convertInches={convertInches}
            setViewAlert={setViewAlert}
            setSearchingData={setSearchingData}
          />
        </>
      ) : (
        <>
          <div>
            {ViewAlert ? <Alert type={2} /> : null}
            <LoggedHeader />
            <div className="dashboard-wrapper">
              <div className="container">
                {/* <DashboardWrapper /> */}
                <SearchArea />

                <div className="row">
                  <div className="col-md-4">
                    <DashboardWrapper />
                  </div>
                  <div className="col-md-8">
                    {(state?.criminalData?.length > 0 &&
                      state?.criminalData != "No Data Found!") ||
                    (state?.sexOffendedData?.length > 0 &&
                      state?.sexOffendedData != "No Data Found!") ||
                    (state?.bankRuptciesData?.length > 0 &&
                      state?.bankRuptciesData != "No Data Found!") ? (
                      <div>
                        <div className="alert-box">
                          <p>
                            <img src={AlertIcon} alt="" />
                            {state?.sexOffendedData?.length > 0 &&
                            state?.sexOffendedData != "No Data Found!"
                              ? "Alert! Sex Offender Record Detected "
                              : state?.bankRuptciesData?.length > 0 &&
                                state?.bankRuptciesData != "No Data Found!"
                              ? "Alert! Bankruptcies Record Detected "
                              : "Alert! Criminal Record Detected "}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Link
                          onClick={() => navigate(-1)}
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 18,
                              fontWeight: "bold",
                              color: "#405192",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              onClick={() => hide("bD")}
                              style={{ marginRight: 5 }}
                            />
                            Back to Results
                          </div>
                        </Link>
                      </div>
                      {optLoading ? (
                        <>Loading...</>
                      ) : (
                        <Link
                          onClick={() => removalRequestFun()}
                          style={
                            {
                              // display: "flex",
                              // justifyContent: "flex-end",
                            }
                          }
                        >
                          <div
                            style={{
                              color: "red",
                              fontSize: 18,
                              fontWeight: "bold",
                              // color: "#405192",
                            }}
                          >
                            Request Removal
                          </div>
                        </Link>
                      )}
                    </div>

                    <div className="record-partmain">
                      <div className="record-btn">
                        <br />
                        <a>
                          <span>
                            <img src={r1} alt="" /> Overview
                          </span>{" "}
                          <i className="fas fa-chevron-down"></i>
                        </a>
                      </div>
                      <div className="record-box">
                        <h2>
                          {/* {personData.FIRST_NAME} {personData.LAST_NAME},{" "}
                {personalData.age}{" "} */}
                        </h2>
                        <div className="record-innner">
                          <div className="record-innrcnt">
                            <h6>Full Name</h6>
                            <p>
                              {/* {toTitleCase(state?.requestObj?.FIRST_NAME)}{" "}
                    {toTitleCase(state?.requestObj?.LAST_NAME)} */}
                              {toTitleCase(state?.requestObj?.FIRST_NAME)}{" "}
                              {toTitleCase(state?.requestObj?.MIDDLE_NAME)}{" "}
                              {toTitleCase(state?.requestObj?.LAST_NAME)}
                              {/* {JSON.stringify(personData)} */}
                              {/* {personData.FIRST_NAME} {personData.MIDDLE_NAME}{" "}
                    {personData.LAST_NAME} */}
                            </p>
                            <h6>Aliases</h6>
                            <p>
                              {state?.aliasesData?.length > 0 &&
                                state?.aliasesData?.map((item, inde) => (
                                  <>
                                    {item?.firstName} {item?.midName}{" "}
                                    {item?.lastName}
                                  </>
                                ))}
                            </p>
                          </div>
                          <div className="record-rightcnt">
                            <h6>Date of Birth</h6>
                            <p>{state?.requestObj?.formattedDob}</p>
                            <h6>Age</h6>
                            {state?.requestObj?.age}
                          </div>
                        </div>
                      </div>

                      <div className="record-btn">
                        <a>
                          <span>
                            <img src={r2} alt="" /> Contact Info
                          </span>{" "}
                          <i className="fas fa-chevron-down"></i>
                        </a>
                      </div>
                      <div className="record-box">
                        <h6>Most Recent Number </h6>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "max-content",
                          }}
                        >
                          <p>
                            {state?.requestObj?.PHONE ? (
                              <p>{state?.requestObj?.PHONE}</p>
                            ) : (
                              <>
                                {state?.phone && (
                                  <p>
                                    {state?.phone.length > 0 && state?.phone[0]}
                                  </p>
                                )}
                              </>
                            )}
                          </p>
                          {(state?.requestObj?.PHONE ||
                            (state?.phone.length > 0 && state?.phone[0])) && (
                            <CopyToClipboard
                              text={`${
                                state?.requestObj?.PHONE ||
                                (state?.phone.length > 0 && state?.phone[0])
                              }`}
                              onCopy={() => swal("Copied !")}
                            >
                              {/* <Image src={copy} alt="Copy email address" width={12} height={12} className="copytoclipimg ml-2" /> */}
                              <FontAwesomeIcon
                                icon={faCopy}
                                className="fontAwesomeIcon_img"
                                style={{
                                  color: "gray",
                                  fontSize: "2vw",
                                  marginRight: "5px",
                                }}
                              />
                            </CopyToClipboard>
                          )}
                        </div>
                        <h6>Other Numbers</h6>
                        {state?.phone?.map((item, index) => (
                          <>
                            {state?.requestObj?.PHONE &&
                              item != state?.requestObj?.PHONE && <p>{item}</p>}
                          </>
                        ))}
                        <p>
                          {/* {personData.PHONE_1} */}
                          <br />
                          {/* {personData.PHONE_2} */}
                        </p>
                        <h6>Most Recent Email </h6>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>
                            {state?.requestObj?.EMAIL ? (
                              <p>{state?.requestObj?.EMAIL}</p>
                            ) : (
                              <>
                                {state?.email && (
                                  <p>
                                    {state?.email.length > 0 && state?.email[0]}
                                  </p>
                                )}
                              </>
                            )}
                          </p>
                          {(state?.requestObj?.EMAIL ||
                            (state?.email.length > 0 && state?.email[0])) && (
                            <CopyToClipboard
                              text={`${
                                state?.requestObj?.EMAIL ||
                                (state?.email.length > 0 && state?.email[0])
                              }`}
                              onCopy={() => swal("Copied !")}
                            >
                              {/* <Image src={copy} alt="Copy email address" width={12} height={12} className="copytoclipimg ml-2" /> */}
                              <FontAwesomeIcon
                                icon={faCopy}
                                className="fontAwesomeIcon_img"
                                style={{
                                  color: "gray",
                                  fontSize: "2vw",
                                  marginRight: "5px",
                                }}
                              />
                            </CopyToClipboard>
                          )}
                        </div>
                        <h6>Other Emails</h6>
                        {state?.email?.map((item, index) => (
                          <>
                            {!state?.requestObj?.EMAIL ? (
                              <>{index != 0 && <p>{item}</p>}</>
                            ) : (
                              <p>{item}</p>
                            )}
                          </>
                        ))}
                      </div>

                      <div className="record-btn">
                        <a>
                          <span>
                            <img src={r3} alt="" /> Address Info
                          </span>{" "}
                          <i className="fas fa-chevron-down"></i>
                        </a>
                      </div>
                      <div className="record-box">
                        <h6>Most Recent Address </h6>
                        <p>
                          {state?.requestObj?.ADDRESS}
                          <br />
                          {state?.requestObj?.CITY}
                          {state?.requestObj?.STATE
                            ? ", " + getStateName(state?.requestObj?.STATE)
                            : ""}
                        </p>
                        <hr />
                        <h6>Past Addresses</h6>
                        {state?.address?.map((item, index) => (
                          <>
                            <h4>{item?.address}</h4>
                            {/* <br /> */}
                            <h4>
                              {item?.city} ,{getStateName(item?.state)}
                            </h4>
                            <h4>{item?.zip ? item?.zip + "\n" : ""}</h4>
                            <br />
                            <br />
                          </>
                        ))}
                      </div>

                      <div className="record-btn">
                        <a>
                          <span>
                            <img src={r4} alt="" /> Potential Associates
                          </span>{" "}
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            onClick={() => hide("pA")}
                          />
                        </a>
                        {/* <div className="record-box"> */}
                        {/* </div> */}
                      </div>
                      <div id="pA" style={{ textAlign: "center" }}>
                        {state?.potientialAssociate?.length > 0 && (
                          <div>
                            {state?.potientialAssociate?.map((items, index) => (
                              <div
                                className="recent-serchbox"
                                style={{ textAlign: "left" }}
                                onClick={() => {
                                  setViewAlert(true);
                                  var item = items?._source;
                                  item.age = items?.age;
                                  item.formattedDob = items?.formattedDob;
                                  var d0b = items?._index.includes("dob");
                                  if (d0b) {
                                    item.index = "dob";
                                  } else {
                                    item.index = "email";
                                  }
                                  setSearchingData({
                                    state: {
                                      //   data: callBack.data,
                                      //   inputState: state,
                                      //   inputName: name,
                                      metaBody: items,
                                      research: true,
                                      //   research: true,
                                      //   firstName,
                                      //   lastName,
                                      //   state,
                                      //   email,
                                      //   phone,
                                      //   street,
                                      //   city,
                                    },
                                  });
                                  // alert(JSON.stringify(items));
                                  // console.log(
                                  //   "🚀 ~ file: RecordDisplay.jsx:575 ~ RecordDisplay ~ JSON.stringify(items):",
                                  //   JSON.stringify(items)
                                  // );
                                  // setState(items);
                                  // navigate("/result-page", {
                                  //   research: true,
                                  //   obj: items,
                                  // });
                                }}
                              >
                                <div>
                                  <h4>
                                    {toTitleCase(items?._source?.FIRST_NAME)}{" "}
                                    {toTitleCase(items?._source?.LAST_NAME)}
                                    {""}
                                    {items?.age && ", " + items?.age}
                                  </h4>

                                  <Link>
                                    <h5
                                      style={{
                                        color: "#5979AE",
                                        border: "1px solid blue",
                                        borderRadius: 5,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        paddingVertical: 10,
                                        width: 200,
                                      }}
                                    >
                                      Scan this Person
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            ))}
                            <br />
                          </div>
                        )}
                      </div>
                      <div className="record-box recbox2" id="pA">
                        <p>{pA ? true : "No Record Found"}</p>
                      </div>

                      <div className="record-btn">
                        <a>
                          <span>
                            <img src={r5} alt="" /> Criminal Records
                          </span>{" "}
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            onClick={() => hide("cR")}
                          />
                        </a>
                      </div>

                      <div id="cR" style={{ textAlign: "center" }}>
                        {state?.criminalData?.length > 0 &&
                          state?.criminalData != "No Data Found!" && (
                            <div>
                              {state?.criminalData?.map((item, index) => (
                                <>
                                  {/* {index != 0 && <div style={{ marginTop: 20 }} />} */}
                                  <div
                                    className="recent-serchbox"
                                    style={{ textAlign: "left" }}
                                  >
                                    <div className="recent-srcnt">
                                      <div
                                        style={{
                                          width: 100,
                                          height: 22,
                                          marginTop: 10,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#466BA4",
                                          borderRadius: 5,
                                          display: "flex",
                                          color: "white",
                                        }}
                                      >
                                        {item?._source?.sourceState
                                          ? getStateName(
                                              item?._source?.sourceState
                                            )
                                          : "-"}
                                      </div>
                                      {/* {item?._source?.sourceState
                                    ? getStateName(item?._source?.sourceState)
                                    : "-"}
                                </p> */}
                                      <div style={{ marginTop: 2 }}>
                                        {item?._source?.OffenseDate && (
                                          <>
                                            <p
                                            // style={{
                                            //   ...styles.fullname,
                                            //   marginTop: 8,
                                            // }}
                                            >
                                              Offense Date:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {item?._source?.OffenseDate &&
                                                moment(
                                                  item?._source?.OffenseDate
                                                ).format("DD/MM/YYYY")}{" "}
                                              {"\n"}
                                            </p>
                                          </>
                                        )}
                                        {item?._source?.Category && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Offense Category:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.Category
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}
                                        {item?._source?.OffenseDesc1 && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Offense Description:
                                            </p>
                                            <p
                                            // style={{ ...styles.detailText }}
                                            >
                                              {toTitleCase(
                                                item?._source?.OffenseDesc1
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}
                                        {item?._source?.Plea && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Plea:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(item?._source?.Plea)}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.Court && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Court:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.Court
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.sourceState && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              State:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {getStateName(
                                                item?._source?.sourceState
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.Disposition && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Disposition:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.Disposition
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.DispositionDate && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Disposition Date:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {moment(
                                                item?._source?.DispositionDate,
                                                "YYYYMMDD"
                                              ).format("MM/DD/YYYY")}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.sourceName && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Source:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.sourceName
                                              )}
                                              {"\n"}
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}

                              <br />
                            </div>
                          )}
                      </div>

                      <div className="record-btn">
                        <a>
                          <span>
                            <img src={r5} alt="" /> Sex Offender
                          </span>{" "}
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            onClick={() => hide("sEx")}
                          />
                        </a>
                      </div>

                      <div id="sEx" style={{ textAlign: "center" }}>
                        {state?.sexOffendedData?.length > 0 &&
                          state?.sexOffendedData != "No Data Found!" && (
                            <div>
                              {state?.sexOffendedData?.map((item, index) => (
                                <>
                                  <div
                                    className="recent-serchbox1"
                                    style={{ textAlign: "left" }}
                                  >
                                    <div className="recent-srcnt">
                                      <div
                                        style={{
                                          width: 100,
                                          height: 22,
                                          marginTop: 10,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#466BA4",
                                          borderRadius: 5,
                                          display: "flex",
                                          color: "white",
                                        }}
                                      >
                                        {item?._source?.SOURCE
                                          ? getStateName(item?._source?.SOURCE)
                                          : "-"}
                                      </div>
                                      {<br />}
                                      <div>
                                        <div>
                                          {item?._source?.RISKLEVEL && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Risk Level:
                                              </h5>
                                              <p>
                                                {item?._source?.RISKLEVEL &&
                                                  item?._source?.RISKLEVEL}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}

                                          {item?._source?.COUNTY && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                County:
                                              </h5>
                                              <p>
                                                {item?._source?.COUNTY &&
                                                  item?._source?.COUNTY}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.HAIRCOLOR && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Hair Color:
                                              </h5>
                                              <p>
                                                {item?._source?.HAIRCOLOR &&
                                                  item?._source?.HAIRCOLOR}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.EYECOLOR && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Eye Color:
                                              </h5>
                                              <p>
                                                {item?._source?.EYECOLOR &&
                                                  item?._source?.EYECOLOR}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.HEIGHT && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Height:
                                              </h5>
                                              <p>
                                                {item?._source?.HEIGHT &&
                                                  convertInches(
                                                    item?._source?.HEIGHT
                                                  )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.WEIGHT && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Weight:
                                              </h5>
                                              <p>
                                                {item?._source?.WEIGHT}
                                                {/* {item?._source?.WEIGHT &&
                                            convertInches(
                                              item?._source?.WEIGHT
                                            )} */}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.RACE && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Race:
                                              </h5>
                                              <p>
                                                {item?._source?.RACE &&
                                                  toTitleCase(
                                                    item?._source?.RACE
                                                  )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.GENDER && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Gender:
                                              </h5>
                                              <p>
                                                {item?._source?.GENDER &&
                                                  toTitleCase(
                                                    item?._source?.GENDER
                                                  )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.CASENUMBER && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Case Number:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.CASENUMBER
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.OFFENSEDAT && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                File Date:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.OFFENSEDAT
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.OFFENSEDAT && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Case Type:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.OFFENDERCA
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.OFFENSEDES && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Statue:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.OFFENSEDES
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.OFFENSECOD && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Statue Code:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.OFFENSECOD
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.CONVICTION && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Conviction:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.CONVICTION
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}

                                          {item?._source?.CONV_PLACE && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Place of Conviction:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.CONV_PLACE
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                          {item?._source?.VICTIMSAGE && (
                                            <>
                                              <h5
                                                style={{
                                                  marginTop: 8,
                                                }}
                                              >
                                                Victim's Age:
                                              </h5>
                                              <p>
                                                {toTitleCase(
                                                  item?._source?.VICTIMSAGE
                                                )}
                                                {"\n"}
                                              </p>
                                            </>
                                          )}
                                        </div>

                                        {/* {item?._source?.HAIRCOLOR && (
                                    <>
                                      <h5
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Hair Color:
                                      </h5>
                                      <p>
                                        {item?._source?.HAIRCOLOR &&
                                          item?._source?.HAIRCOLOR}
                                        {"\n"}
                                      </p>
                                    </>
                                  )} */}
                                      </div>
                                      <div style={{ marginTop: 2 }}>
                                        {item?._source?.OffenseDate && (
                                          <>
                                            <p
                                            // style={{
                                            //   ...styles.fullname,
                                            //   marginTop: 8,
                                            // }}
                                            >
                                              Offense Date:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {item?._source?.OffenseDate &&
                                                moment(
                                                  item?._source?.OffenseDate
                                                ).format("DD/MM/YYYY")}{" "}
                                              {"\n"}
                                            </p>
                                          </>
                                        )}
                                        {item?._source?.Category && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Offense Category:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.Category
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}
                                        {item?._source?.OffenseDesc1 && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Offense Description:
                                            </p>
                                            <p
                                            // style={{ ...styles.detailText }}
                                            >
                                              {toTitleCase(
                                                item?._source?.OffenseDesc1
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}
                                        {item?._source?.Plea && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Plea:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(item?._source?.Plea)}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.Court && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Court:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.Court
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.sourceState && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              State:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {getStateName(
                                                item?._source?.sourceState
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.Disposition && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Disposition:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.Disposition
                                              )}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.DispositionDate && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Disposition Date:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {moment(
                                                item?._source?.DispositionDate,
                                                "YYYYMMDD"
                                              ).format("MM/DD/YYYY")}
                                              {"\n"}
                                            </p>
                                            <br />
                                          </>
                                        )}

                                        {item?._source?.sourceName && (
                                          <>
                                            <p style={{ fontSize: 25 }}>
                                              Source:
                                            </p>
                                            <p style={{ color: "black" }}>
                                              {toTitleCase(
                                                item?._source?.sourceName
                                              )}
                                              {"\n"}
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}

                              <br />
                            </div>
                          )}
                      </div>

                      {/* <div className="recbox2" id="cD">
                  <div className="record-box norecbox ">
                    <p>{cD ? true : "No Record Found"}</p> */}
                      {/* <span>Florida</span>
                                <p>File Date: 11/1/2022 <br/>Case Type: Misdemeanor <br/>Statute: Tresspassing</p>
                                <span className="disabled">New York</span>
                                <p>File Date: 10/10/2019 <br/>Case Type: Felony <br/>Statute: Drug Poslocal</p>
                                <span>Georgia</span>
                                <p>File Date: 02/12/2011 <br/>Case Type: Misdemeanor <br/>Statute: Driving Under the Influence <br/>of Alchohol (DUI)</p> */}
                      {/* </div>
                </div> */}

                      <div className="record-btn">
                        <a>
                          <span>
                            <img src={r6} alt="" /> Bankruptcies
                          </span>{" "}
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            onClick={() => hide("bD")}
                          />
                        </a>
                      </div>
                      <div className="recbox2" id="bD">
                        <div className="record-box norecbox">
                          <p>{bD ? true : "No Record Found"}</p>
                        </div>
                      </div>

                      <div className="record-btn">
                        <a>
                          <span>
                            {/* <img src={r6} alt="" />  */}
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              onClick={() => hide("bD")}
                              style={{ marginRight: 5 }}
                            />
                            Disclaimer
                          </span>{" "}
                        </a>
                      </div>

                      <div
                        className="recent-serchbox1"
                        style={{ textAlign: "left" }}
                      >
                        <div className="recent-srcnt">
                          Our service gathers public records, including criminal
                          records from government agencies, affiliates, and
                          third parties. However, as we are not the creators of
                          these databases, we cannot ensure the accuracy of the
                          information or attest to the person's character.
                          <br />
                          <br />
                          Court records, which are publicly accessible through
                          government agencies, may contain criminal details such
                          as felonies, misdemeanors, arrests, or infractions.
                          <br />
                          <br />
                          Nevertheless, these public records can be unreliable,
                          incomplete, or unrelated to the individual in
                          question. It is crucial to independently verify a
                          person's criminal history at the appropriate
                          courthouse and not solely depend on the information
                          provided here.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LoggedFooter />
          </div>
        </>
      )}
    </>
  );
}

export default RecordDisplay;
