import React, {useState, useEffect} from "react";
import Footer from "../../../Components/Footer";
import { ContactHeader } from "../../../Components/Header";
import DosAndDont from "../../../MobilePages/menuPages/DosAndDont";
import Loading from "../../../Components/Loading";

export default function DoNotSell() {
  const[isLoading, setIsLoading] = useState(false);
   
  /* mobile screen */
      
  const [mobileDosAndDont, setMobileDosAndDont] = useState(false);
    
  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileDosAndDont(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileDosAndDont(window.innerWidth <= 575);
  };

  return (
    <>
        {isLoading ? <Loading /> : false}
        {mobileDosAndDont ? (
          <DosAndDont />
        ) : (
    <body>
      <ContactHeader />
      <br />
      <br />




      <section class="terms-area">
            <div class="container">
                <div class="terms-main">
        <div className="headerName">
        Do’s & Don’ts

        </div>
                    <div class="dos-uprbtn text-center">
                        <a className="dos-BTN" href="#">Do’s</a>
                    </div>
                    <div class="dos-cnt">
                        <h4>Look yourself up</h4>
                        <p>You may think looking yourself up is laughable, but it is not; it is crucial. You should know what information is in your public record.</p>
                    </div>
                    <div class="dos-cnt">
                        <h4>Who is that person you just matched with? </h4>
                        <p>Dating now starts nearly 90% of the time online. Looking up that new match before meeting them in person would be best. Your safety should always be your first priority; it's even more important than Taco Tuesday. </p>
                    </div>
                    <div class="dos-cnt">
                        <h4>Find old friends and family</h4>
                        <p>Life takes us all in different directions; searching through our billions of public records is a great way to reconnect with people, whether it's friends from ages ago, distant relatives, or a long-lost significant other!</p>
                    </div>
                    <div class="dos-cnt">
                        <h4>Find out who's calling you</h4>
                        <p>Not sure who is behind that phone number that keeps calling you? Run a reverse phone search and find out.</p>
                    </div>
                    <div class="dos-cnt">
                        <h4>Find out who's emailing you</h4>
                        <p>Someone random keeps emailing you? Run a reverse email search and find out who they are.</p>
                    </div>
                    <div class="dos-cnt">
                        <h4>Verify a potential roommate</h4>
                        <p>Before you move in together and commit to a lease, find out who you'll be living with.</p>
                    </div>
                    <div class="dos-cnt">
                        <h4>Check out your new neighbors</h4>
                        <p>About to move, or If you've just moved, or maybe someone new just moved next door, find out more information about your new neighborhood.</p>
                    </div>
                    <div class="dos-cnt">
                        <h4>Verify Online Buyers and Sellers</h4>
                        <p>Before you make that purchase or place that bid, make sure that person on the online auction site is who they says they are.</p>
                    </div>

                    <div class="dont-btn">
                        <a href="#">Don’ts</a>
                    </div>

                    <div class="dos-bottom">
                        <p>The Fair Credit Reporting Act (FCRA) regulates how public databases are used. Friend Verifier, the public databases that power our app, can not be used in business, and the following use cases. </p>
                        <ul>
                            <li>• Employment screening</li>
                            <li>• Screening household workers</li>
                            <li>• Screening adoptive parent </li>
                            <li>• Screen potential pet-owners</li>
                            <li>• Screening educational qualifications</li>
                            <li>• Screening charities or non-profits</li>
                            <li>• Screening Tenants </li>
                            <li>• Screening Customers </li>
                            <li>• Or anything to do with Credit, Insurance, or Mortgages</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>












          




































      {/* <div class=" privacy-page-area dos-dont-area">
        <div class="container">
          <div style={{ paddingHorizontal: 20 }}>
            <p>Do's</p>
            <div style={{ marginTop: 40 }}>
              <p>Look yourself up</p>

              <p>
                You may think looking yourself up is{"\n"}laughable, but it is
                not; it is crucial.{"\n"}You should know what information is in
                {"\n"}your public record.
              </p>
            </div>
            <div style={{ marginTop: 40 }}>
              <p>Who is that person you just matched{"\n"}with?</p>

              <p>
                Dating now starts nearly 90% of the{"\n"}time online. Looking up
                that new{"\n"}match before meeting them in person{"\n"}would be
                best. Your safety should{"\n"}always be your first priority;
                it's even{"\n"}more important than Taco Tuesday.
              </p>
            </div>

            <div style={{ marginTop: 40 }}>
              <p>Find old friends and family</p>

              <p>
                Life takes us all in different directions;{"\n"}
                searching through our billions of{"\n"}public records is a great
                way to{"\n"}reconnect with people, whether it's{"\n"}friends
                from ages ago, distant{"\n"}relatives, or a long-lost
                significant{"\n"}
                other!
              </p>
            </div>

            <div style={{ marginTop: 40 }}>
              <p>Find out who's calling you</p>

              <p>
                Not sure who is behind that phone{"\n"}number that keeps calling
                you? Run a{"\n"}
                reverse phone search and find out.{"\n"}
                Find out who's emailing you
              </p>
            </div>
          </div>
          <div>
            <u>
              The Fair Credit Reporting Act (FCRA) regulates how public
              databases are used. Friend Verifier, the public databases that
              power our platform, can not be used in business, and the following
              use cases.
            </u>
            <ul>
              <li>
                <p>Employment screening</p>
              </li>
              <li>
                <p>Screening household workers</p>
              </li>
              <li>
                <p>Screening adoptive parent</p>
              </li>
              <li>
                <p>Screen potential pet-owners</p>
              </li>
              <li>
                <p>Screening educational qualifications</p>
              </li>
              <li>
                <p>Screening charities or non-profits</p>
              </li>
              <li>
                <p>Screening Tenants</p>
              </li>
              <li>
                <p>Screening Customers</p>
              </li>
              <li>
                <p>Or anything to do with Credit, Insurance, or Mortgages</p>
              </li>
            </ul>
          </div> */}
          {/* <div class="dos-dont-inner-area">
            <div class="do-img">
              <a href="#">
                <img src="img/Do's-Banner.png" class="img-fluid" alt="" />
              </a>
            </div>
            <div class="dos-text-item">
              <h2>Look yourself up</h2>
              <p>
                You may think looking yourself up is laughable, but it is not;
                it is crucial. You should know what information is in your
                public record.
              </p>
            </div>

            <div class="dos-text-item">
              <h2>Who is that person you just matched with?</h2>
              <p>
                Dating now starts nearly 90% of the time online. Looking up that
                new match before meeting them in person would be best. Your
                safety should always be your first priority; it's even more
                important than Taco Tuesday.
              </p>
            </div>

            <div class="dos-text-item">
              <h2>Find old friends and family</h2>
              <p>
                Life takes us all in different directions; searching through our
                billions of public records is a great way to reconnect with
                people, whether it's friends from ages ago, distant relatives,
                or a long-lost significant other!
              </p>
            </div>

            <div class="dos-text-item">
              <h2>Find out who's calling you</h2>
              <p>
                Not sure who is behind that phone number that keeps calling you?
                Run a reverse phone search and find out.
              </p>
            </div>

            <div class="dos-text-item">
              <h2>Find out who's emailing you</h2>
              <p>
                Someone random keeps emailing you? Run a reverse email search
                and find out who they are.
              </p>
            </div>

            <div class="dos-text-item">
              <h2>Verify a potential roommate</h2>
              <p>
                Before you move in together and commit to a lease, find out who
                you'll be living with.
              </p>
            </div>

            <div class="dos-text-item">
              <h2>Check out your new neighbors</h2>
              <p>
                About to move, or If you've just moved, or maybe someone new
                just moved next door, find out more information about your new
                neighborhood.
              </p>
            </div>
            <div class="dos-text-item">
              <h2>Verify Online Buyers and Sellers</h2>
              <p>
                Before you make that purchase or place that bid, make sure that
                person on the online auction site is who they says they are.
              </p>
            </div>

            <div class="do-img dont-img">
              <a href="#">
                <img src="img/Don'ts.png" class="img-fluid" alt="" />
              </a>
            </div>

            <div class="dos-text-item">
              <h1>
                The Fair Credit Reporting Act (FCRA) regulates how public
                databases are used. Friend Verifier, the public databases that
                power our app, can not be used in business, and the following
                use cases.{" "}
              </h1>

              <ul>
                <li>Employment screening</li>
                <li>Screening household workers</li>
                <li>Screening adoptive parent </li>
                <li>Screen potential pet-owners</li>
                <li>Screening educational qualifications</li>
                <li>Screening charities or non-profits</li>
                <li>Screening Tenants </li>
                <li>Screening Customers </li>
                <li>Or anything to do with Credit, Insurance, or Mortgages</li>
              </ul>
            </div>
          </div> */}
        {/* </div>
      </div> */}

      <br />
      <br />
      <Footer />
    </body>
      )}
    </>
  );
}
