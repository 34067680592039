import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../MobileComponents/Header";
import 'react-phone-number-input/style.css'
import Arrow from "../../assets/icons/left-Arrow.png";


function FAQ() {
   
      const faqData = [
        {
          question: 'What is Friend Verifier?',
          answerP1: 'With crime rates escalating and numerous reports of convicted criminals exploiting dating apps and social media to target victims, we are thrilled to reintroduce Friend Verifier. This new version equips you with the essential tool to conduct searches, enabling you to assess the background of individuals you are about to meet. Friend or Foe, You Need to Know!',
        },
        {
            question: 'How do you handle user data?',
            answerP1: 'At Friend Verifier, empowering you with information while safeguarding your privacy is our utmost priority. Rest assured, your user data remains strictly confidential and is never shared with any third party. The data you provide during the account creation process is solely used for that purpose and is not cross-referenced with any other data sources, nor is it accessible to anyone else.',
            answerP2: 'In the event that an account is not fully completed during signup or if you choose to delete your account, rest assured that all data, including your search history, is routinely purged from our system. Our commitment to data protection ensures that your information is handled responsibly and securely, providing you with peace of mind while using our services.',  
        },
        {
            question: 'Are people alerted if I run a search on them?',
            answerP1: 'Your privacy and safety are of the utmost importance to us. Our unwavering mission is to ensure that we take every possible measure to keep you secure.',
            answerP2: 'Whether you&apos;re using our services to keep track of important dates like your aunt&apos;s birthday or to assess the safety of someone you&apos;re meeting for a date, rest assured that your search history is entirely private, inaccessible even to us. Your personal information remains in your control, and our commitment to protecting it is steadfast.',
        },
        {
            question: 'Am I required to give Friend Verifier access to my phone&apos;s contact list?',
            answerP1: 'Certainly not. Friend Verifier utilizes two features that access your phone&apos;s contact list. The first feature allows you to invite friends who could benefit from using our app; it&apos;s a simple and convenient way to bring them on board. The second feature enables you to conduct a reverse search on a saved contact.',
            answerP2: 'This involves reading the selected contact&apos;s phone number to perform the search. It&apos;s important to note that this access is granted on a per-transaction basis, and at any point, you can easily revoke our access to your contacts within your phone&apos;s settings app. Rest assured, even if you choose not to grant access, the app will work flawlessly without any hiccups. Your control over your data and privacy is paramount to us.',
        },

        {
            question: 'Can I opt-out of your database?',
            answerP1: 'Absolutely! You have the right to remove your information from our database. While this requirement is mandated by law in only three states (California, Colorado, and Virginia), we go beyond and offer this option to every United States citizen, regardless of their state of residence. Your privacy and control over your information are of utmost importance to us.',
        },
        {
            question: 'What is the history of Friend Verifier?',
            answerP1: 'In 2012, Friend Verifier revolutionized the landscape by becoming the pioneering app that empowered Facebook users to scan their friends and friend requests for registered sex offenders. Despite initial skepticism, the app swiftly garnered attention from journalists across the United States, who subjected Friend Verifier to rigorous testing. The results were eye-opening, revealing that registered sex offenders were indeed present on Facebook, posing a significant threat to women and children.',
            answerP2: 'The investigative journalists made startling discoveries, revealing how sex offenders managed to befriend unsuspecting teenagers and young adults, putting them in perilous situations. This had severe consequences, as convicted rapists and child molesters gained access to sensitive information such as school details and hangout locations, posing a direct threat to innocent lives. The rapid surge in our app&apos;s popularity, reaching over 14 million users, triggered a wave of questions, questioning why these dangerous individuals were allowed on the platform.',
            answerP3: 'In response to the growing concern, Facebook took decisive action and altered developer access, removing APIs needed for Friend Verifier to work.'
        },
        {
            question: 'Can I pay for one search or must I get a subscription?',
            answerP1: 'Unlike many of our competitors, we offer a more flexible and customer-friendly approach. With us, you have the freedom to conduct a single search without being forced into a monthly subscription. Additionally, we don&apos;t surprise you with additional fees for accessing more information. Our goal is to provide a transparent and straightforward experience, ensuring that you only pay for what you need and use. Your satisfaction is our priority.',
        },
        {
            question: 'How do I cancel my subscription?',
            answerP1: 'Opting for one of our convenient paid monthly or yearly subscriptions is a breeze, and canceling is just as simple! To cancel, open your phone&apos;s settings, navigate to subscriptions, and choose to cancel your subscription hassle-free. We believe in providing you with an effortless experience, even when it comes to managing your subscription.',
        },
      
       
      ];

  const navigate = useNavigate();

  return (
    <div className="login_container faq_mt">
      <Header position={"fixed"} height={""} padding={""} leftMenu="back" leftSrc={Arrow}   title={" FAQs "}  leftButtonClick={() => navigate(-1)} />
      <div class=" privacy-page-area dos-dont-mainContainer">
        <div class="container">
        <div style={{ paddingHorizontal: 20 }}>

            {faqData?.map((item, index) => (
            <div className="dosText_container" >
              <p className="faqQuestion">{item.question}</p>
              <p className="faq_para">{item.answerP1}</p>
              <p className="faq_para">{item.answerP2}</p>
              <p className="faq_para">{item.answerP3}</p>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
} 

export default FAQ;


