import React, { useState } from "react";
import { Link } from "react-router-dom";
import appStore from "../images/app-store.png";
import googlePlay from "../images/google-play.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowRightFromBracket,
  faEnvelope,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Footer({ activeD ,status,setStatue}) {
  const navigate = useNavigate();
  const [activeIcon, setActiveIcon] = useState("user");

  const iconClick = (icon) => {
    setActiveIcon(icon);
  };

  const isIconActive = (icon) => {
    return status === icon;
  };

  function dashboardActive(icon) {
    setStatue(icon);
    activeD(icon);
  }

  // function mobileDashboardFunc(icon) {
  //   setActiveIcon(icon);
  //   navigate("/MobileDashboard");
  // }
  // function phoneSearchFunc(icon) {
  //   setActiveIcon(icon);
  //   navigate("/PhoneSearch");
  // }
  // function emailSearchFunc(icon) {
  //   setActiveIcon(icon);
  //   navigate("/EmailSearch");
  // }

  return (
    <footer className="home-footer-area">
      <div className="container foot_padding">
        <div className="mainFooter_icon">
          <ul>
            <li>
              <a
                onClick={() => {
                  dashboardActive("user");
                }}
              >
                <FontAwesomeIcon
                className="footer_icons"
                  icon={faUser}
                  style={{
                    color: isIconActive("user") ? "#415192" : "#8c8c8c",
                  }}
                />
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  dashboardActive("phone");
                }}
              >
                <FontAwesomeIcon
                className="footer_icons"
                  icon={faPhone}
                  style={{
                    color: isIconActive("phone") ? "#415192" : "#8c8c8c",
                  }}
                />
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  dashboardActive("mail");
                }}
              >
                <FontAwesomeIcon
                className="footer_icons"
                  icon={faEnvelope}
                  style={{
                    color: isIconActive("mail") ? "#415192" : "#8c8c8c",
                  }}
                />
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  dashboardActive("logout");
                }}
              >
                <FontAwesomeIcon
                className="footer_icons"
                  icon={faArrowRightFromBracket}
                  style={{
                    color: isIconActive("logout") ? "#415192" : "#8c8c8c",
                  }}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
