
import React from 'react'
import { LoggedFooter } from "../Components/Footer";
import LoggedHeader, { Header } from "../Components/Header";



export default function AlreadySubmittedStatus() {

  return (
    <div>
      <Header />

      <main className="login-wrapper">
        <div>
          <div
            style={{
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignContent: "center",
              paddingRight: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <svg
                width="100px"
                height="100px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M8.5 12.5L10.5 14.5L15.5 9.5"
                    stroke="#26a269"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                    stroke="#26a269"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ textAlign: "center", maxWidth: "60ch" }}>
                Your request to remove the report has already been submitted and
                is currently under admin review.
              </p>
            </div>
          </div>
        </div>
      </main>

      <LoggedFooter />
    </div>
  );
}

