/** @format */

import { useState, useEffect } from "react";
import logo from "../../../src/assets/icons/logo.png";
import EmailIcon from "../../assets/icons/email.png";
import Password from "../../assets/icons/password.png";
import Invisible from "../../assets/icons/invisible.png";
import Visible from "../../assets/icons/eye.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../MobileComponents/Header";
import Arrow from "../../assets/icons/left-Arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../utils/axiosRequest";
import swal from "sweetalert";

function ResetPassword() {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function signUpfunction() {
    navigate("/SignUp");
  }

  function myFunction(name, eyes) {
    var x = document.querySelector(`input[name=${name}]`);
    var y = document.querySelector(`#${eyes}`);
    if (
      x.type === "password" ||
      y.src ===
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
    ) {
      x.type = "text";
      y.src =
        "https://w7.pngwing.com/pngs/910/805/png-transparent-eye-key-look-password-security-see-view-minimalisticons-icon-thumbnail.png";
    } else {
      x.type = "password";
      y.src =
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png";
    }
  }

  // Eye Function
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };
  const [queryParameters] = useSearchParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function passwordChanger(e) {
    e.preventDefault();
    const url = "user/change-forgot-password";
    const obj = {
      device: "web",
      deviceInfo: {},
      password: password,
      identifier: queryParameters.get("identifier"),
      email: queryParameters.get("email"),
    };
    if (confirmPassword === password) {
      setIsLoading(true);
      axiosPost(url, obj, "", (callBack) => {
        if (callBack?.status === 200) {
          swal(callBack.data?.message);
          setIsLoading(false);
          navigate("/login-signup");
        } else {
          setIsLoading(false);
          swal(callBack.message);
        }
      });
    } else {
      setIsLoading(false);
      swal("Password Must Be Same !!");
    }
  }

  return (
    <div className="login_container">
      <Header
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        title={"Reset Password"}
        leftButtonClick={() => navigate(-1)}
      />
      <main className="resetPassword-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-left pe-lg-5 me-xl-5">
                <div>
                  <p className="updatePassword_text"> Update Your Password </p>
                </div>
                <form onSubmit={passwordChanger}>
                  <div className="login-content">
                    <div className="login-item">
                      <div className="password">
                        <div className="login_icon_position">
                          <img
                            src={Password}
                            alt="Email Icon"
                            className="login_input_icon password_icon img_mt"
                          />
                        </div>
                        <input
                          // minLength="8"
                          autoComplete="new-password"
                          id="newPassword"
                          name="newPassword"
                          type={isPasswordVisible ? "text" : "password"}
                          placeholder="New Password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="login_Eyeicon_position">
                          <FontAwesomeIcon
                            icon={isPasswordVisible ? faEye : faEyeSlash}
                            onClick={() => togglePasswordVisibility()}
                            className="login_Eye_icon eye_color"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="login-item">
                      <div className="password">
                        <div className="login_icon_position">
                          <img
                            src={Password}
                            alt="Email Icon"
                            className="login_input_icon password_icon img_mt"
                          />
                        </div>
                        <input
                          autoComplete="confirm-password"
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          type={isConfirmPasswordVisible ? "text" : "password"}
                          placeholder="Confirm New Password"
                          required
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="login_Eyeicon_position">
                          <FontAwesomeIcon
                            icon={isConfirmPasswordVisible ? faEye : faEyeSlash}
                            onClick={() => toggleConfirmPasswordVisibility()}
                            className="login_Eye_icon eye_color"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="login-item">
                      <button type="submit">Update Password</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ResetPassword;
