import { useState } from "react";
import logo from "../../../src/assets/icons/logo.png"
import EmailIcon from "../../assets/icons/email.png";
import Password from "../../assets/icons/password.png";
import Invisible from "../../assets/icons/invisible.png";
import Visible from "../../assets/icons/eye.png";
import { useNavigate } from "react-router-dom";
import Header from "../../MobileComponents/Header";

 function ForgotPassword({verify, setMail}){
    const navigate = useNavigate();

    // const requestPasswordFunc = () => {
    //     navigate('/updateforgetpassword')
    // }

    return(
        <div className="login_container">
            <Header height={"40%"} padding={""} titleSrc={logo}  />
            <main className="forgotPassword-wrapper" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="login-left pe-lg-5 me-xl-5">
                        <form onSubmit={verify}>
                                <div className="login-content">
                                    <div className="login-item">
                                     <div className="login_icon_position">
                                      <img src={EmailIcon} alt="Email Icon" className="login_input_icon img_mt" />
                                     </div> 
                                      <input type="email" autoComplete="username" pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+" id="loginEmail" 
                                        placeholder="Email Address" required onChange={(e)=> setMail(e.target.value)} />
                                    </div> 

                                    <div className="login-item" 
                                    //  onClick={ ()=> requestPasswordFunc()}
                                    >
                                        <button type="submit">Request New Password</button>
                                    </div>
                                    <div onClick={()=>{
                                        navigate(-1)
                                    }} className="forgetPswd_div"> 
                                        <p className="forgetHandler"> Login Instead</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        </div>
    )
 }

 export default ForgotPassword;