/** @format */

import React, { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import swal from "sweetalert";
import { SigninFooter } from "../../../Components/Footer";
import { SigninHeader } from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import { axiosPost } from "../../../utils/axiosRequest";
import ResetPassword from "../../../MobilePages/Auth/ResetPassword";

const UpdateForgetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [queryParameters] = useSearchParams();

  /* mobile screen */

  const [mobileResetpswd, setMobileResetpswd] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileResetpswd(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileResetpswd(window.innerWidth <= 575);
  };

  function passwordChanger(e) {
    const id = localStorage.id;
    e.preventDefault();
    const url = "user/change-forgot-password";
    const obj = {
      device: "web",
      deviceInfo: {},
      password: password,
      identifier: queryParameters.get("identifier")
        ? queryParameters.get("identifier")
        : state?.callBack?.data?.identifier,
      email: queryParameters.get("email")
        ? queryParameters.get("email")
        : state?.email,
    };
    if (confirmPassword === password) {
      setIsLoading(true);
      axiosPost(url, obj, "", (callBack) => {
        if (callBack?.status === 200) {
          swal(callBack.data?.message);
          setIsLoading(false);
          navigate("/login-signup");
        } else {
          setIsLoading(false);
          swal(callBack.message);
        }
      });
    } else {
      setIsLoading(false);
      swal("Password Must Be Same !!");
    }
  }

  function myFunction(name, eyes) {
    var x = document.querySelector(`input[name=${name}]`);
    var y = document.querySelector(`#${eyes}`);
    if (
      x.type === "password" ||
      y.src ===
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
    ) {
      x.type = "text";
      y.src =
        "https://w7.pngwing.com/pngs/910/805/png-transparent-eye-key-look-password-security-see-view-minimalisticons-icon-thumbnail.png";
    } else {
      x.type = "password";
      y.src =
        "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png";
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileResetpswd ? (
        <ResetPassword />
      ) : (
        <div>
          {isLoading ? <Loading /> : false}
          <SigninHeader />

          <main className="login-wrapper">
            <div className="container">
              <div className="login-left moible-wrap">
                <h2>Email Verification</h2>
                <p
                  style={{
                    width: "350px",
                    fontSize: "18px",
                    padding: "30px 0px",
                  }}
                >
                  Please check your email and enter the Security code below
                </p>
                <form onSubmit={passwordChanger}>
                  <div className="mobile-cntnt">
                    <p>Create new password</p>
                    <br />
                    <div className="login-item">
                      <label htmlFor="conNewPass">New Password</label>
                      <div className="password" id="conNewPass">
                        <input
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          minLength="8"
                          type="password"
                          id="newPass"
                          name="newPass"
                          placeholder="New Password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <img
                          src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                          alt=""
                          name="eye4"
                          id="eye4"
                          width="30px"
                          onClick={() => myFunction("newPass", "eye4")}
                        />
                      </div>
                    </div>

                    <div className="login-item">
                      <label htmlFor="lg1">Repeat New Password</label>
                      <div className="password" id="repeatNewPass">
                        <input
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          minLength="8"
                          type="password"
                          id="repeatNewPass"
                          name="repeatPass"
                          placeholder="Re-Enter Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <img
                          src="https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
                          alt=""
                          name="eye5"
                          id="eye5"
                          width="30px"
                          onClick={() => myFunction("repeatPass", "eye5")}
                        />
                      </div>
                    </div>

                    <div className="login-item mt-5">
                      <button type="submit"> UPDATE </button>
                    </div>

                    <div className="login-links">
                      <p>
                        Already have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Login here
                        </Link>
                      </p>
                      <p>
                        Don’t have an account?{" "}
                        <Link className="Link" to="/login-signup">
                          Signup here
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>

          <SigninFooter />
        </div>
      )}
    </>
  );
};

export default UpdateForgetPassword;
