/** @format */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { SigninFooter } from "../../../Components/Footer";
import { SigninHeader } from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import { axiosGet, axiosPost } from "../../../utils/axiosRequest";
import SignUpOTP from "../../../MobilePages/Auth/SignUpOTP";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
function PhoneVerificationOtp() {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(60);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  {
    /* mobile screen */
  }
  const [mobileNoVerifyOTP, setMobileNoVerifyOTP] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileNoVerifyOTP(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
    if (!state?.hasOwnProperty("data")) {
      navigate("/login-signup");
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileNoVerifyOTP(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const Id = localStorage.getItem("@loginDetails");
    if (Id === null) {
      navigate("/login-signup");
    }

    var timer = setTimeout(update, 1000);

    function update() {
      for (var i = count; i > 0; i--) {
        // document.getElementById("time").style.color = "red";
        setCount(count - 1);
        setTimeout(1000);
      }
    }

    if (count === 0) {
      setCount("");
      document.getElementById("time").style.color = "blue";
    }
  });
  const email = localStorage.email;

  const UpdatePhone = () => {
    setIsLoading(true);

    var url = "user/verify-change-phone";
    var obj = {
      code: otp,
      countryCode: location.state.countryCode,
      phone: location.state.phone,
    };
    console.log("obj ==>>", obj);

    axiosPost(url, obj, '', (callBack) => {
      setIsLoading(false);
      if (callBack?.status === 200) {
        swal("Mobile number updated successfully.");
        navigate(-2);
      } else {
      }
    });
  };

  const UpdateEmail = () => {
    setIsLoading(true);

    var url = "user/verify-change-email";
    var obj = {
      code: otp,
      email: location.state.email,
    };

    axiosPost(url, obj, '', (callBack) => {
      setIsLoading(false);
      if (callBack?.status === 200) {
        swal("Email updated successfully.");
        navigate("/dashboard");
      } else {
      }
    });
  };

  function verify(e) {
    e.preventDefault();

    if (location?.state?.screen == "updatePhone") {
      UpdatePhone();
      return;
    }
    if (location?.state?.screen == "updateEmail") {
      UpdateEmail();
      return;
    }
    setIsLoading(true);
    const url = `/user/verify-otp`;
    const obj = {
      device: "web",
      deviceInfo: {},
      code: otp,
      purpose: "registration",
    };
    var token = state?.data?.authToken;

    axiosPost(url, obj, token, (callBack) => {
      localStorage.setItem("signUpMobile", null);
      //console.log(callBack);
      if (callBack?.status === 200) {
        console.log(
          "🚀 ~ file: PhoneVerificationOtp.jsx:127 ~ axiosPost ~ callBack:",
          JSON.stringify(callBack)
        );
        setIsLoading(false);
        navigate("/termsandservice", {
          replace: true,
          state: {
            data: callBack?.data,
          },
        });
        // const url = `/user/verifyForgetPassword/${localStorage.id}`;
        // localStorage.setItem("url", url);
        // // swal(callBack.data.message);
      } else {
        setIsLoading(false);
        swal(callBack.message);
      }
    });
  }

  function resendOtp() {
    // console.log(
    //   "🚀 ~ file: PhoneVerificationOtp.jsx:144 ~ resendOtp ~ state:",
    //   JSON.stringify(state?.data?.user?.phone)
    // );
    // return;
    const url = `/user/resend-otp`;
    var obj = {
      device: "web",
      deviceInfo: {},
      mode: "phone",
      purpose: "registration",
      countryCode: "+1",
      phone: state?.data?.user?.phone,
    };
    var token = state?.data?.authToken;

    axiosPost(url, obj, token, (callBack) => {
      if (callBack?.status === 200) {
        swal(callBack.data);
        setCount(60);
      } else {
        swal(callBack.message);
      }
    });

    return;
    // var url;
    if (location?.state?.screen == "updateEmail") {
      url = `/user/resendCode?email=${location.state.email}&type=email&purpose=forgotPassword`;
    } else {
      url = `/user/resendCode?phone=${location.state.phone}&type=phone&purpose=phone&countryCode=+1`;
    }
    axiosGet(url, (callBack) => {
      //console.log(callBack);
      if (callBack?.status === 200) {
        swal(callBack.data.message);
        setCount(60);
      } else {
        swal(callBack.message);
      }
    });
  }

  function Sec() {
    if (count > 0) {
      return " seconds";
    } else {
      return (
        <span onClick={resendOtp} style={{ cursor: "pointer" }}>
          Resend
        </span>
      );
    }
  }

  return (
    <>
      {mobileNoVerifyOTP ? (
        <>
          <SignUpOTP
            count={count}
            resendOtp={resendOtp}
            verify={verify}
            setOtp={setOtp}
            otp={otp}
          />
        </>
      ) : (
        <>
          <div>
            {isLoading ? <Loading /> : false}
            <SigninHeader />

            <main className="login-wrapper">
              <div className="container">
                <div className="login-left moible-wrap">
                  <h2>Enter OTP codes</h2>
                  <p>We do this for security and preventing bots</p>
                  <form
                    onSubmit={verify}
                    // onSubmit={() =>

                    //     ? UpdatePhone()
                    //     : verify()
                    // }
                  >
                    <div className="login-content mobile-cntnt">
                      <div className="login-item">
                        <div
                          className="row"
                          // style={{ border: "red 1px solid" }}
                        >
                          {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(-1)}
                          >
                            <p>Edit number: </p>{" "}
                            <div>
                              <u
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                }}
                              >
                                {localStorage?.signUpMobile != null &&
                                  localStorage?.signUpMobile != "null" &&
                                  JSON?.parse(localStorage?.signUpMobile)}
                              </u>{" "}
                              <FontAwesomeIcon
                                icon={faPencil}
                                // onClick={() => hide("bD")}
                                style={{ marginRight: 5 }}
                              />
                            </div>
                          </div> */}
                        </div>
                        <label htmlFor="lg1">Enter SMS code below</label>
                        <input
                          type="number"
                          id="lg1"
                          placeholder="OTP"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                          name="otp"
                          onChange={(e) => setOtp(e.target.value)}
                          required
                        />
                        <p
                          style={{
                            fontSize: "15px",
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          Didn't receive the OTP ?<br />
                          You can request a new one in{" "}
                          <span id="time" style={{ color: "red" }}>
                            {count}
                            <Sec />
                          </span>
                        </p>
                      </div>

                      <div className="login-item">
                        <button type="submit">Verify</button>
                      </div>

                      {location?.state?.screen != "updateEmail" ||
                        (location?.state?.screen != "updatePhone" && (
                          <div className="login-links">
                            <p>
                              Already have an account?{" "}
                              <Link className="Link" to="/login-signup">
                                Login here
                              </Link>
                            </p>
                            <p>
                              Don’t have an account?{" "}
                              <Link className="Link" to="/login-signup">
                                Signup here
                              </Link>
                            </p>
                          </div>
                        ))}
                    </div>
                  </form>
                </div>
              </div>
            </main>

            <SigninFooter />
          </div>
        </>
      )}
    </>
  );
}

export default PhoneVerificationOtp;
