import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../MobileComponents/Header";
import "react-phone-number-input/style.css";
import Arrow from "../../../assets/icons/left-Arrow.png";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SupportTicketChat({data,messages,setMessages,send}) {
  const navigate = useNavigate();

  const [isCreateTicketClicked, setIsCreateTicketClicked] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleCreateTicketClick = () => {
    setIsCreateTicketClicked(false);
    setIsFormSubmitted(true);
  };

  const submitBtn = () => {
    setIsCreateTicketClicked(true);
    setIsFormSubmitted(false);
  };
  const Chat = () => {
    return (
      <>
        <div class="">
          {messages?.map((item, index) => (
            <>
              {item?.sent === false ? (
                <>
                  <div style={{ borderRadius: 5 }} class="item">
                    {item?.msg}
                  </div>
                  <p
                    style={{
                      fontSize: 13,
                      color: "#8E8E8E",
                      fontFamily: "Medium",
                      marginTop: 8,
                      marginHorizontal: 35,
                    }}
                  >
                    {item?.date_time}
                  </p>
                </>
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: 5,
                      color: "#fff",
                    }}
                    class="item AlignRight"
                  >
                    {item?.msg}
                  </div>
                  <p
                    style={{
                      fontSize: 13,
                      color: "#8E8E8E",
                      fontFamily: "Medium",
                      marginTop: 8,
                      marginHorizontal: 35,
                    }}
                    class="AlignRight2"
                  >
                    {item?.date_time}
                  </p>
                </>
              )}
            </>
          ))}
        </div>
        <div
          style={{
            height: 60,
            width: "100%",
            backgroundColor: "white",
            bottom: 10,
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {data?.status == "closed" ? (
              <div
                style={{
                  height: 80,
                  width: "105%",
                  borderRadius: 10,
                  backgroundColor: "#F4F1F2",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  Ticket is Closed, Please feel free to create new ticket for
                  further queries/requests.
                </p>
              </div>
            ) : (
              <div style={{width: "93%",}}>
                <input
                  style={{
                    height: 60,
                    width: "100%",
                    border: "0.5px solid gray",
                    borderRadius: 10,
                    // backgroundColor: "#F4F1F2",
                    paddingLeft: 10,
                    // marginRight:100
                  }}
                  id="sf"
                  type="text"
                  placeholder="Type here..."
                  required
                />
                <div
                  style={{
                    position: "absolute",
                    right:50,
                    bottom: 20,
                    // right: 250,
                    // height: 25,
                    // width: 25,
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    textAlign:"center"
                  }}
                  onClick={send}
                >
                <FontAwesomeIcon icon={faTelegram} size="xl" />

                  {/* <form onSubmit={send}>
                    <div className="search-item search-btn">
                      <div>
                        <button onClick={send} type="submit">
                          Send
                        </button>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="login_container technicalQuesChat_mt">
      <Header
        position={"fixed"}
        height={"12%"}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        leftButtonClick={() => navigate(-1)}
        title={data?.raw_subject}
      />
      <main className="support-wrapper">
        <div className="container login_container">
          <div className="row">
            <div className="col-lg-12">
            <Chat/>
              {/* {isCreateTicketClicked ? (
                <div className="login-left pe-lg-5 me-xl-5">
                  <div className="login-item" onClick={handleCreateTicketClick}>
                    <button className="createTicketBtn">Create Ticket</button>
                  </div>

                  <div>
                    <p> Support Tickets </p>
                  </div>

                  {JSON.stringify(data?.raw_subject)}

                </div>
              ) : (
               
              )} */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SupportTicketChat;
