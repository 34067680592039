import { LoggedHomeHeader } from "../../Components/Header";
import Footer from "../../Components/Footer";
import SearchArea from "../../Components/SearchArea";
import About from "../../Components/About";
import FreeSearch from "../../Components/FreeSearch";
import Pricing from "../../Components/Pricing";
import Contact from "../../Components/Contact";
import f1 from "../../images/f1.png";
import f2 from "../../images/f2.png";
import f3 from "../../images/f3.png";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function LoggedHome() {
  const myRef = useRef(null);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.state) {
      myRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div>
      <LoggedHomeHeader />

      <div className="friend-area" id="home">
        <div className="container">
          <div className="friend-upper">
            <h2>Friend or Foe, You Need to Know!</h2>
            <p>
              Privately and securely run a background check on anyone from
              anywhere.{" "}
            </p>
          </div>

          <SearchArea />

          <div className="source-box">
            <div className="row">
              <div className="col-md-4">
                <div className="source-item">
                  <img src={f1} alt="" className="img-fluid" />
                  <h4>
                    Your Trusted <br />
                    Source
                  </h4>
                  <p>
                    Nearly 14 million people and over 150 media outlets relied
                    on version one of Friend Verifier.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="source-item">
                  <img src={f2} alt="" className="img-fluid" />
                  <h4>
                    Billions of <br />
                    Records
                  </h4>
                  <p>
                    Friend Verifier users access billions of records to run
                    background checks on anyone from anywhere confidentially.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="source-item">
                  <img src={f3} alt="" className="img-fluid" />
                  <h4>
                    No Nonsense <br />
                    Subscriptions
                  </h4>
                  <p>
                    Other services charge high subscriptions and per-check
                    costs, our subscription fees are the most affordable in the
                    industry.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <About />
      <FreeSearch />
      <Pricing />
      <Contact refProp={myRef} />
      <Footer />
    </div>
  );
}

export default LoggedHome;
