/** @format */

import { Link } from "react-router-dom";
import appStore from "../images/app-store.png";
import googlePlay from "../images/google-play.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  function toggle(name) {
    const element = document.getElementById(name);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-main">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-item">
                <ul>
                  <li>
                    <Link
                      onClick={() => window.scrollTo(0, 0)}
                      className="active"
                    >
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => toggle("about")}>About</Link>
                  </li>
                  <li>
                    <Link onClick={() => toggle("freeSearch")}>
                      Free Search
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => toggle("pricing")}>Pricing</Link>
                  </li>
                  {/* <li>
                    <Link onClick={() => toggle("contact")}>Contact</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-item footer-middle">
                <ul>
                  <li>
                    <a href="/dos">Do’s &amp; Don’ts </a>
                  </li>
                  <li>
                    <a href="/termsandconditions">Terms of Use </a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy </a>
                  </li>
                  <li>
                    <a href="/do-not-sell">Do Not Sell My Info</a>
                  </li>
                  {!localStorage.tokken && (
                    <li>
                      <Link to="/login-signup">Login / Sign Up</Link>
                    </li>
                  )}
                  <li>
                    <a href="/delete">Delete Account</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-right">
                <ul>
                  <li>
                    <a
                      target="blank"
                      href="https://apps.apple.com/app/friend-verifier-search/id1669432431"
                    >
                      <img src={appStore} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US"
                    >
                      <img src={googlePlay} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="social-media">
            <ul>
              <li>
                <a target=" " href="https://twitter.com/friendverifier">
                  <FontAwesomeIcon icon={faTwitter} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.facebook.com/friendverifier">
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.youtube.com/@friendverifier">
                  <FontAwesomeIcon icon={faYoutube} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.instagram.com/friendverifier/">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.tiktok.com/@friendverifier">
                  <FontAwesomeIcon icon={faTiktok} size="lg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="copy-cnt">
            <p style={{ textAlign: "center" }}>
              &copy; 2012 - {new Date().getFullYear()} FRIEND VERIFIER, INC. ALL
              RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export function LoggedFooter() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-main">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-item">
                <ul>
                  <li>
                    <Link to="/loggedHome" className="active">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to="/loggedHome">About</Link>
                  </li>
                  <li>
                    <Link to="/loggedHome">Free Search</Link>
                  </li>
                  <li>
                    <Link to="/loggedHome">Pricing</Link>
                  </li>
                  <li>
                    <a href="mailto:hi@friendverifier.com">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-item footer-middle">
                <ul>
                  <li>
                    <a href="/dos">Do’s &amp; Don’ts </a>
                  </li>
                  <li>
                    <a href="/termsandconditions">Terms of Use </a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy </a>
                  </li>
                  <li>
                    <a href="/do-not-sell">Do Not Sell My Info</a>
                  </li>
                  {!localStorage.tokken && (
                    <li>
                      <Link to="/login-signup">LOGIN / SIGN UP</Link>
                    </li>
                  )}
                  <li>
                    <a href="/delete">Delete Account</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-right">
                <ul>
                  <li>
                    <a
                      target="blank"
                      href="https://apps.apple.com/app/friend-verifier-search/id1669432431"
                    >
                      <img src={appStore} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US"
                    >
                      <img src={googlePlay} alt="" />
                    </a>
                  </li>
                </ul>
                {/* <ul>
                  <li>
                    <a href="">
                      <img src={appStore} alt="" />
                    </a>
                  </li>
                  <li>
                    <img src={googlePlay} alt="" />
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className="social-media">
            <ul>
              <li>
                <a target=" " href="https://twitter.com/friendverifier">
                  <FontAwesomeIcon icon={faTwitter} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.facebook.com/friendverifier">
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.youtube.com/@friendverifier">
                  <FontAwesomeIcon icon={faYoutube} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.instagram.com/friendverifier/">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.tiktok.com/@friendverifier">
                  <FontAwesomeIcon icon={faTiktok} size="lg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="copy-cnt">
            <p style={{ textAlign: "center" }}>
              &copy; 2012 - {new Date().getFullYear()} FRIEND VERIFIER, INC. ALL
              RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export function SigninFooter() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-main">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-item">
                <ul>
                  <li>
                    <Link to="/" className="active">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to="/">About</Link>
                  </li>
                  <li>
                    <Link to="/">Free Search</Link>
                  </li>
                  <li>
                    <Link to="/">Pricing</Link>
                  </li>
                  {/* <li>
                    <Link to="/">Contact</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-item footer-middle">
                <ul>
                  <li>
                    <a href="/dos">Do’s &amp; Don’ts </a>
                  </li>
                  <li>
                    <a href="/termsandconditions">Terms of use </a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy </a>
                  </li>
                  <li>
                    <a href="/do-not-sell">Do Not sell my info</a>
                  </li>
                  {!localStorage.tokken && (
                    <li>
                      <Link onClick={window.scrollTo(0, 0)}>
                        LOGIN / SIGN UP
                      </Link>
                    </li>
                  )}
                  <li>
                    <a href="/delete">Delete Account</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-right">
                <ul>
                  {/* <li>
                    <a href="">
                      <img src={appStore} alt="" />
                    </a>
                  </li> */}
                  <li>
                    <img src={googlePlay} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="social-media">
            <ul>
              <li>
                <a target=" " href="https://twitter.com/friendverifier">
                  <FontAwesomeIcon icon={faTwitter} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.facebook.com/friendverifier">
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.youtube.com/@friendverifier">
                  <FontAwesomeIcon icon={faYoutube} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.instagram.com/friendverifier/">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.tiktok.com/@friendverifier">
                  <FontAwesomeIcon icon={faTiktok} size="lg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="copy-cnt">
            <p style={{ textAlign: "center" }}>
              &copy; 2012 - {new Date().getFullYear()} FRIEND VERIFIER, INC. ALL
              RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export function ContactFooter() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-main">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-item">
                <ul>
                  <li>
                    <Link to="/" className="active">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to="/">About</Link>
                  </li>
                  <li>
                    <Link to="/">Free Search</Link>
                  </li>
                  <li>
                    <Link to="/">Pricing</Link>
                  </li>
                  {/* <li>
                    <Link to="/">Contact</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-item footer-middle">
                <ul>
                  <li>
                    <Link to="/dos">Do’s &amp; Don’ts </Link>
                  </li>
                  <li>
                    <Link to="/termsandconditions">Terms of Use </Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy </Link>
                  </li>
                  <li>
                    <Link to="/do-not-sell">Do Not Sell My Info</Link>
                  </li>
                  {!localStorage.tokken && (
                    <li>
                      <Link to="/login-signup">LOGIN / SIGN UP</Link>
                    </li>
                  )}
                  <li>
                    <a href="/delete">Delete Account</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-right">
                <ul>
                  {/* <li>
                    <a to="">
                      <img src={appStore} alt="" />
                    </a>
                  </li> */}
                  <li>
                    <img src={googlePlay} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="social-media">
            <ul>
              <li>
                <a target=" " href="https://twitter.com/friendverifier">
                  <FontAwesomeIcon icon={faTwitter} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.facebook.com/friendverifier">
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.youtube.com/@friendverifier">
                  <FontAwesomeIcon icon={faYoutube} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.instagram.com/friendverifier/">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </li>
              <li>
                <a target=" " href="https://www.tiktok.com/@friendverifier">
                  <FontAwesomeIcon icon={faTiktok} size="lg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="copy-cnt">
            <p style={{ textAlign: "center" }}>
              &copy; 2012 - {new Date().getFullYear()} FRIEND VERIFIER, INC. ALL
              RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
