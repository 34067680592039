import { useState } from "react";
import logo from "../../../../src/assets/icons/logo.png"
import EmailIcon from "../../../assets/icons/email.png";
import Password from "../../../assets/icons/password.png";
import Invisible from "../../../assets/icons/invisible.png";
import Visible from "../../../assets/icons/eye.png";
import { useNavigate } from "react-router-dom";
import Header from "../../../MobileComponents/Header";
import Arrow from "../../../assets/icons/left-Arrow.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faChevronRight, faEnvelope, faEye, faEyeSlash, faLock, faPhone, faShareNodes, faToggleOff, faUser } from "@fortawesome/free-solid-svg-icons";

 function PasswordAccount({passwordChanger, setConfirmPassword, setPassword}){
    const navigate = useNavigate();
    const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    function myFunction(name, eyes) {
        var x = document.querySelector(`input[name=${name}]`);
        var y = document.querySelector(`#${eyes}`);
        if (
          x.type === "password" ||
          y.src ===
            "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png"
        ) {
          x.type = "text";
          y.src =
            "https://w7.pngwing.com/pngs/910/805/png-transparent-eye-key-look-password-security-see-view-minimalisticons-icon-thumbnail.png";
        } else {
          x.type = "password";
          y.src =
            "https://w7.pngwing.com/pngs/221/536/png-transparent-eye-password-see-view-minimalisticons-icon-thumbnail.png";
        }
      }
         // Eye Function
  const toggleOldPasswordVisibility = () => {
    setIsOldPasswordVisible(!isOldPasswordVisible);
    };
  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

    return(
        <div className="login_container">
            <Header height={""} padding={""} leftMenu="back" leftSrc={Arrow}   title={"Password"}  leftButtonClick={() => navigate(-1)} />
            <main className="accountPassword-wrapper" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="login-left pe-lg-5 me-xl-5">
                             <div> 
                               <p className="updatePassword_text"> Update Password </p>
                             </div>
                             <form onSubmit={passwordChanger}>
                                <div className="login-content">
                                     {/* <div className="login-item">
                                        <div className="password">
                                         <div className="mailAccount_icon_position">
                                          <img src={Password} alt="Email Icon" className="login_input_icon password_icon" />
                                        </div>
                                         <input  
                                         autoComplete="old-password"
                                         id="oldPassword" 
                                         name="oldPassword" 
                                         type={isOldPasswordVisible ? "text" : "password"}
                                         placeholder=" Old Password" required />
                                        </div>
                                        <div className="login_Eyeicon_position" >
                                        <FontAwesomeIcon
                                          icon={isOldPasswordVisible ? faEye : faEyeSlash}
                                          onClick={() => toggleOldPasswordVisibility()}
                                          className="login_Eye_icon eye_color"
                                        />
                                        </div>
                                    </div> */}
                                    <div className="login-item">
                                        <div className="password">
                                         <div className="mailAccount_icon_position">
                                          <img src={Password} alt="Email Icon" className="login_input_icon password_icon" />
                                        </div>
                                         <input  
                                         minLength="8" 
                                         autoComplete="new-password" 
                                         id="newPassword" 
                                         name="newPassword" 
                                         type={isNewPasswordVisible ? "text" : "password"}
                                         placeholder=" New Password" 
                                         required 
                                        onChange={(e) => setPassword(e.target.value)}
                                        />
                                        </div>
                                        <div className="login_Eyeicon_position" >
                                        <FontAwesomeIcon
                                          icon={isNewPasswordVisible ? faEye : faEyeSlash}
                                          onClick={() => toggleNewPasswordVisibility()}
                                          className="login_Eye_icon eye_color"
                                        />
                                        </div>
                                    </div>
                                    <div className="login-item">
                                        <div className="password">
                                         <div className="mailAccount_icon_position">
                                          <img src={Password} alt="Email Icon" className="login_input_icon password_icon" />
                                        </div>
                                         <input  
                                           autoComplete="new-password" 
                                           id="confirmNewPassword" 
                                           name="confirmNewPassword" 
                                           type={isConfirmPasswordVisible ? "text" : "password"} 
                                           placeholder=" Confirm New Password" 
                                          onChange={(e) => setConfirmPassword(e.target.value)}
                                           required />
                                        </div>
                                        <div className="login_Eyeicon_position" >
                                        <FontAwesomeIcon
                                          icon={isConfirmPasswordVisible ? faEye : faEyeSlash}
                                          onClick={() => toggleConfirmPasswordVisibility()}
                                          className="login_Eye_icon eye_color"
                                        />
                                        </div>
                                    </div>
                                   

                                    <div className="login-item">
                                        <button type="submit">Update New Password</button>
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        </div>
    )
 }

 export default PasswordAccount;