import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../MobileComponents/Header";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Arrow from "../../../assets/icons/left-Arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faChevronRight,
  faCircleQuestion,
  faLock,
  faTriangleExclamation,
  faUnlockKeyhole,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import SupportLegalImg from "../../../assets/icons/do.png";
import { axiosDelete } from "../../../utils/axiosRequest";
import { logOut } from "../../../redux/action";

import swal from "sweetalert";
import { useDispatch } from "react-redux";

function Support() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function faqFuction() {
    navigate("/FAQ");
  }
  function termConditionFuction() {
    navigate("/termsandconditions");
  }

  function privactPolicyFuction() {
    navigate("/privacy");
  }
  function supportTicketFunc() {
    navigate("/support-ticket");
  }
  const [view, setView] = useState(false);

  function Warning() {
    return (
      <div id="alerts">
        <div className="alert">
          <h4>Are you Sure?</h4>
          <p>
            Deleting your account you will losse all of your search history and
            settings
          </p>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView(false)}
            >
              <h5 style={{ margin: "0px" }}>Cancel</h5>
            </button>
            <button
              className="login-btn loginactive Link"
              onClick={DeleteAccount}
            >
              <h5 style={{ margin: "0px" }}>Delete</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }

  const DeleteAccount = () => {
    const data = JSON.parse(localStorage.loginDetails);
    axiosDelete(`/user/${data._id}`, (callBack) => {
      console.log(callBack);
      if (callBack?.status === 200) {
        swal(callBack.message);
        navigate("/");
        dispatch(logOut());
      } else {
        swal(callBack.message);
      }
    });
  };

  return (
    <div className="login_container">
      {view ? <Warning /> : false}

      <Header
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        leftButtonClick={() => navigate(-1)}
        title={"Support and Legal"}
      />
      <main className="support-wrapper">
        <div className="container login_container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-left pe-lg-5 me-xl-5">
                <div className="supportList_container pointer"  onClick={faqFuction}>
                  <div className="menuList_firstCol">
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                  <div className="menuList_secondCol">
                    <h4 className="supportMenu_List_Text"> FAQS </h4>
                  </div>
                  <div className="menuList_thirdCol">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                </div>
                <div
                  className="supportList_container pointer"
                  onClick={supportTicketFunc}
                >
                  <div className="menuList_firstCol">
                    <img
                      src={SupportLegalImg}
                      className="Support_TicketImg"
                      alt=""
                    />
                  </div>
                  <div className="menuList_secondCol">
                    <h4 className="supportMenu_List_Text"> Support Ticket </h4>
                  </div>
                  <div className="menuList_thirdCol">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                </div>
                <div className="supportList_container pointer" onClick={termConditionFuction} >
                  <div className="menuList_firstCol">
                    <FontAwesomeIcon
                      icon={faBookOpen}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                  <div className="menuList_secondCol">
                    <h4 className="supportMenu_List_Text"> Terms of Use </h4>
                  </div>
                  <div className="menuList_thirdCol">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                </div>
                <div
                  className="supportList_container pointer"
                  onClick={privactPolicyFuction}
                >
                  <div className="menuList_firstCol">
                    <FontAwesomeIcon
                      icon={faLock}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                  <div className="menuList_secondCol">
                    <h4 className="supportMenu_List_Text"> Privacy Policy </h4>
                  </div>
                  <div className="menuList_thirdCol">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                </div>
                {/* <div className="supportList_container pointer">
                  <div className="menuList_firstCol">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                  <div className="menuList_secondCol">
                    <h4 className="supportMenu_List_Text">
                      {" "}
                      Do Not Sell my Info{" "}
                    </h4>
                  </div>
                  <div className="menuList_thirdCol">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                </div> */}
                <div className="supportList_container pointer">
                  <div className="menuList_firstCol">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="fontAwesomeIcon_SupportImg redAlert"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                  <div
                    className="menuList_secondCol"
                    onClick={() => setView(true)}
                  >
                    <h4 className="supportMenu_List_Text red_color">
                      {" "}
                      Delete Account{" "}
                    </h4>
                  </div>
                  <div className="menuList_thirdCol">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fontAwesomeIcon_SupportImg"
                      style={{ color: "#c4c7d2" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Support;
