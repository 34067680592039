/** @format */

import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronRight,
  faShareNodes,
  faToggleOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Do from "../assets/icons/do.png";
import Support from "../assets/icons/support.png";
import { useNavigate } from "react-router-dom";
import { axiosGetCall, axiosGet } from "../utils/axiosRequest";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/action";

const Header = ({
  height,
  padding,
  position,
  leftMenu = null,
  leftSrc,
  titleSrc = null,
  buttonSrc = null,
  title = null,
  leftButtonClick,
}) => {
  console.log(height);
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [price, setPrice] = useState(0);

  // const history = useHistory();

  // Function to handle going back
  function goBack() {
    navigate(-1);
    // history.goBack();
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [free, setFree] = useState(0);
  const [searches, setSearches] = useState("0");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  function accountFuction() {
    navigate("/Account");
  }
  function supportAndLegalFuc() {
    navigate("/Support");
  }
  function subscriptionFunc() {
    navigate("/subscriptionPage");
  }
  function dosAndDontFuction() {
    navigate("/dos");
  }

  useEffect(() => {
    if (localStorage.tokken) {
      GetSearchFun();
    }
    if (
      localStorage?.loginDetails != null &&
      localStorage?.loginDetails != "null"
    ) {
      var superUser = JSON.parse(localStorage?.loginDetails);
      setIsSuperUser(superUser?.isSuperUser);
    }
    GetPlansList();
  }, []);
  const [planInfo, setPlanInfo] = useState({});

  const GetPlansList = () => {
    var url = "/subscriptionPlans/list?planType=OneTime";
    axiosGet(url, (callBack) => {
      if (callBack?.status == 200) {
        setPrice(
          callBack?.data?.data[0]?.discountedPrice
            ? callBack?.data?.data[0]?.discountedPrice
            : callBack?.data?.data[0]?.price
        );
      }
    });
  };

  const GetSearchFun = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    const get = "/getSearch";
    axiosGetCall(get, (callBack) => {
      if (callBack?.status === 200) {
        // const left = callBack.data.data.totalSearch;
        setFree(callBack.data.data.freeSearches);
        if (callBack.data.data.planId) {
          setPlanInfo(callBack?.data?.data);
          setPrice(callBack.data.data.planId.price);
          // setType(callBack.data.data.planId.planType);
          setSearches(callBack.data.data.planId.totalSearch);
        } else {
          console.log(
            "🚀 ~ file: DashboardWrapper.jsx:45 ~ axiosGetCall ~ aler:",
            callBack.data?.data
          );
        }
        // if (left > 500) {
        //   setLeft(left);
        // } else {
        //   setLeft(left);
        // }
      } else {
        alert(callBack.message);
      }
    });
  };

  function Warning() {
    return (
      <div id="alerts">
        <div className="alert">
          <h4>Do want to log out?</h4>
          <div className="buttons">
            <button
              className="login-btn loginactive Link"
              onClick={() => setView(false)}
            >
              <h5 style={{ margin: "0px" }}>No</h5>
            </button>
            <button className="login-btn loginactive Link" onClick={logingout}>
              <h5 style={{ margin: "0px" }}>Yes</h5>
            </button>
          </div>
        </div>
      </div>
    );
  }

  function logingout() {
    dispatch(logOut());
    navigate("/");
  }

  return (
    <header
      className=" header-area  fixed-header "
      // className={`header-area fixed-header ${isMenuOpen ? "menu-open" : ""}`}
      style={{ height: height, padding: padding, position: position }}
    >
      {view ? <Warning /> : false}

      <div className="container">
        <nav className="navbar navbar-expand-lg navbar1-mobile">
          {leftMenu === "back" ? (
            <div className="backArrow pointer" onClick={leftButtonClick}>
              <img
                style={{ width: "2rem", height: "2rem" }}
                src={leftSrc}
                alt=""
              />
            </div>
          ) : leftMenu === "menu" ? (
            <div className="mobileBurgerMenu" onClick={toggleMenu}>
              <img
                style={{ width: "2rem", height: "2rem" }}
                src={leftSrc}
                alt=""
              />
            </div>
          ) : (
            <div className="navbar-brand-menu"></div>
          )}
          {!title ? (
            <div className="navbar-brand titleSrc_size middleContent">
              <img src={titleSrc} alt="" />{" "}
            </div>
          ) : (
            <div className="navbar-brand-title middleContent">
              <h1 style={{ textAlign: "center" }}>{title}</h1>
            </div>
          )}
          {buttonSrc ? (
            <div
              className="navbar-brand-button pointer header_cancelBtn"
              onClick={leftButtonClick}
            >
              <p className="header-button">{buttonSrc}</p>
            </div>
          ) : (
            <div className="navbar-brand-btn"></div>
          )}
        </nav>
      </div>

      {isMenuOpen && (
        <div className="mobNav_container">
          <div className="freeSearch_container">
            <div className="remainimg_column">
              <h4 style={{ marginBottom: "-1px", fontSize: "17px" }}>
                {/* {
                  isSuperUser?
                  'Super User'
                  :
                  <>
                {searches} Remaining
                  </>
                } */}
                {planInfo?.hasOwnProperty("planId") ? (
                  <>{searches} Credits Left </>
                ) : (
                  <>{isSuperUser ? "Super User" : "Pay As You Go"}</>
                )}
              </h4>
              <p className="headerAdd_subscription" onClick={subscriptionFunc}>
                {planInfo?.hasOwnProperty("planId") ? (
                  <>
                    {planInfo?.planId?.totalSearch} {planInfo?.planId?.planName}
                    <br />
                    {/* {planInfo?.totalSearch} of{" "} */}
                    {/* {planInfo?.planId?.totalSearch} credits remain */}
                  </>
                ) : (
                  <>
                    {isSuperUser ? (
                      "Free Access"
                    ) : (
                      <>${price} per background check</>
                    )}
                  </>
                )}
                <p>Add Subscription</p>
              </p>
            </div>
            <div className="freeSearch_column">
              <p style={{ marginBottom: "-3px", fontSize: "16px" }}>{free}</p>
              <p style={{ fontSize: "13px" }}>Free</p>
            </div>
          </div>
          {/* <div className="Earn_free_search">
            <button type="submit"> Earn Free Searches</button>
          </div> */}

          {/* <div className="menuList_container">
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faShareNodes}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Invite Friend </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div> */}

          <div className="menuList_container pointer" onClick={accountFuction}>
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faUser}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Account </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>

          {/* <div className="menuList_container">
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faToggleOff}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Notification </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div> */}
          <div
            className="menuList_container pointer"
            onClick={dosAndDontFuction}
          >
            <div className="menuList_firstCol">
              <img src={Do} className="DoandDont_img" alt="" />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Do's & Don't </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
          <div
            className="menuList_container pointer"
            onClick={supportAndLegalFuc}
          >
            <div className="menuList_firstCol">
              <img src={Support} className="Support_img" alt="" />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Support & Legal </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
          <div className="menuList_container pointer">
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol" onClick={() => setView(true)}>
              <h4 className="burgerMenu_List_Text"> Log Out </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
