/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/css/responsive.css";
import "./style.css";
import "../src/css/bootstrap.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import * as Sentry from "@sentry/react";
import MetaPixel from "./metaPixel";

// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
Sentry.init({
  dsn: "https://4f3bb112263f5999afbfe691759d18a4@o4505800722677760.ingest.sentry.io/4505800725954560",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MetaPixel />
      <App />
    </Provider>
  </BrowserRouter>
);
// serviceWorkerRegistration.register();
