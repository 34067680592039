/** @format */

import { useEffect } from "react";

function Invite() {
  useEffect(() => {
    const OS = detectOS();
    if (OS == "Linux" || OS == "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US";
    } else if (OS == "iOS") {
      window.location.href =
        "https://apps.apple.com/app/friend-verifier-search/id1669432431";
    }
  });

  function detectOS() {
    const platform = navigator.platform;
    if (platform.indexOf("Win") !== -1) return "Windows";
    if (platform.indexOf("Mac") !== -1) return "Mac OS";
    if (platform.indexOf("Linux") !== -1) return "Linux";
    if (platform.indexOf("iPhone") !== -1) return "iOS";
    if (platform.indexOf("Android") !== -1) return "Android";
    if (platform.indexOf("iPad") !== -1) return "iPad";
    return "Unknown";
  }

  return <div>{"Redirecting..."}</div>;
}

export default Invite;
