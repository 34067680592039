/** @format */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoggedFooter } from "../Components/Footer";
import LoggedHeader from "../Components/Header";
import swal from "sweetalert";
import { axiosDelete, axiosGetCall, axiosPost } from "../utils/axiosRequest";
import { logOut } from "../redux/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import MobileSupportTicket from "../MobilePages/menuPages/supportAndLegal.jsx/MobileSupportTicket";
import Loading from "../Components/Loading";

function ContatUs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [comment, setComment] = useState("");
  const [isCreateTicketClicked, setIsCreateTicketClicked] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  /* mobile screen */

  const [mobileTicket, setMobileTicket] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileTicket(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileTicket(window.innerWidth <= 575);
  };

  useEffect(() => {
    getTickets();
  }, []);

  const getTickets = () => {
    var url = `ticket/list`;
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    axiosGetCall(url, (callBack) => {
      console.log(
        "🚀 ~ file: SupportTicket.jsx:28 ~ axiosGetCall ~ callBack:",
        JSON.stringify(callBack?.data?.data)
      );
      if (callBack?.status === 401) {
        swal({
          title: "Unauthorized Access Detected",
          text: callBack.message,
          showCancelButton: true,
          closeOnConfirm: false,
          animation: "slide-from-top",
          buttons: {
            confirm: {
              text: "Log out",
              className: "red-button",
            },
          },
          dangerMode: true,
        }).then(() => {
          dispatch(logOut());
          navigate("/login-signup");
        });
        return;
      }
      setList(callBack?.data?.data);
    });
  };

  const [createTicket, setCreateTicket] = useState(false);
  const [subject, setSubject] = useState("");

  const SubmitFun = () => {
    if (!subject) {
      // alert("Please enter subject", () => {});
      swal("Please select subject");
      return;
    }
    if (!comment) {
      swal("Please enter your comment", () => {});
      return;
    }
    setIsLoading(true);
    var url = `/ticket/createNew`;
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.tokken}`,
      },
    };
    // setLoadingSubmit(true);
    // axiosGetCall(url, header, (callBack) => {
    var obj = {
      subject: subject,
      comment: comment,
    };
    axiosPost(url, obj, "", (callBack) => {
      // setLoadingSubmit(false);
      setIsLoading(false);
      console.log(
        "🚀 ~ file: SupportTicket.jsx:61 ~ axiosGetCall ~ callBack?.data:",
        callBack
      );
      if (callBack?.status == 200) {
        getTickets();
        setCreateTicket(false);
        setIsCreateTicketClicked(true);
        // simpleAlertCall("Ticket generated successfully.", () => {
        //   //   getTickets();
        //   navigation.goBack();
        // });
        // setShowModal(false);
      }
    });
  };

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileTicket ? (
        <MobileSupportTicket
          list={list}
          setSubject={setSubject}
          setComment={setComment}
          SubmitFun={SubmitFun}
          isCreateTicketClicked={isCreateTicketClicked}
          setIsCreateTicketClicked={setIsCreateTicketClicked}
        />
      ) : (
        <div>
          <LoggedHeader />

          <div
            className="contact-area"
            id="contact"
            style={{
              padding: " 20px 0 45px",
              // height: "100%",
              minHeight: "70vh",
              background: "rgb(244 245 247)",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  {" "}
                  <div className="subscribe-btn" style={{ width: "100%" }}>
                    <button
                      onClick={() => {
                        setCreateTicket(!createTicket);
                      }}
                      className="active Link"
                    >
                      {createTicket ? "Close" : "Create Ticket"}
                    </button>
                  </div>{" "}
                </div>
                <div className="col-md-7">
                  {createTicket ? (
                    <div>
                      <h5>Topic</h5>
                      <div className="search-item">
                        <select
                          name="state"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        >
                          <option value="" defaultChecked>
                            Please Pick
                          </option>
                          <option value="Feature Request">
                            Feature Request
                          </option>
                          <option value="Technical Question">
                            Technical Question
                          </option>
                          <option value="System Bug">System Bug</option>
                          <option value="User Interface Bug">
                            User Interface Bug
                          </option>
                          <option value="Database / Background Check Questions">
                            Database / Background Check Questions
                          </option>
                          <option value="Feedback & Testimonials">
                            Feedback & Testimonials
                          </option>
                          <option value="General Support">
                            General Support
                          </option>
                          <option value="Billing Question">
                            Billing Question
                          </option>
                          <option value="Change or Update My Subscription">
                            Change or Update My Subscription
                          </option>
                          <option value="Partnership Request">
                            Partnership Request
                          </option>
                          <option value="Contact CEO">Contact CEO</option>
                          <option value="Privacy Question">
                            Privacy Question
                          </option>
                          <option value="Opt-Out">Opt-Out</option>
                          <option value="Account Reinstatement">
                            Account Reinstatement
                          </option>
                          <option value="Press">Press</option>
                        </select>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <textarea
                          name="comment"
                          // cols="76"
                          rows="8"
                          placeholder="Message"
                          style={{ width: "99%", borderRadius: 5, padding: 5 }}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                      </div>
                      <div
                        className="search-item search-btn"
                        style={{
                          width: "110%",
                          marginTop: 20,
                        }}
                      >
                        <div onClick={SubmitFun}>
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {list?.length > 0 &&
                        list?.map((item, index) => (
                          <div
                            // style={{ border: "1px solid red" }}
                            className="recent-serchbox1"
                            // style={{ textAlign: "left" }}
                            // key={item._id}
                            onClick={() => {
                              navigate("/support-ticket-chat", {
                                state: item,
                                // status: item?.status,
                              });
                            }}
                          >
                            {/* <div className="recent-srcnt"> */}
                            <div className="row">
                              <div className="col-md-9">
                                <div>
                                  <h4>
                                    {moment(item?.updated_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </h4>
                                  <p
                                    style={{
                                      color: "#858585",
                                    }}
                                  >
                                    {item?.raw_subject}
                                  </p>

                                  <div
                                    style={{
                                      width: 80,
                                      height: 30,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "#4DFA6D",
                                      marginTop: 10,
                                      backgroundColor:
                                        item.status === "new"
                                          ? "#4DFA6D"
                                          : "#CDCBCC",
                                      textAlign: "center",
                                    }}
                                  >
                                    <p style={{ marginTop: 10 }}>
                                      {item?.status}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div
                                  // onPress={() => {
                                  //   //   navigation.navigate(
                                  //   //     "SupportChatScreen",

                                  //   //     {
                                  //   //       ticket: item.subtitle,
                                  //   //     }
                                  //   //   )
                                  //   navigation.navigate(
                                  //     "SupportChatScreen",

                                  //     {
                                  //       ticket: item?.subject,
                                  //       ticketId: item?.id,
                                  //       status: item?.status,
                                  //     }
                                  //   );
                                  // }}
                                  activeOpacity={0.5}
                                  style={{
                                    width: 80,
                                    height: 30,
                                    justifyContent: "center",
                                    // alignItems: "center",
                                    display: "flex",
                                    // borderWidth: 1,
                                    // borderColor: "#7C7B7C",
                                    border: "1px solid #7C7B7C",
                                    marginTop: 10,
                                    marginRight: 30,
                                  }}
                                >
                                  <p style={{ textAlign: "center" }}>View</p>
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                            {/* <img src={search} alt="" /> */}
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
              {/**/}
            </div>
          </div>

          <LoggedFooter />
        </div>
      )}
    </>
  );
}

export default ContatUs;
