import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../MobileComponents/Header";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Arrow from "../../assets/icons/left-Arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faChevronRight,
  faCircleQuestion,
  faLock,
  faTriangleExclamation,
  faUnlockKeyhole,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import SupportLegalImg from "../../assets/icons/do.png";

function DosAndDont() {
  const navigate = useNavigate();

  return (
    <div className="login_container dos_mt ">
      <Header
        position={"fixed"}
        height={""}
        padding={""}
        leftMenu="back"
        leftSrc={Arrow}
        title={" Do's & Don'ts "}
        leftButtonClick={() => navigate(-1)}
      />
      <div class=" privacy-page-area dos-dont-mainContainer">
        <div class="container">
          <div style={{ paddingHorizontal: 20 }}>
            <p className="dos_title">Do's</p>
            <div className="dosText_container">
              <p className="dosHeading">Look yourself up</p>
              <p className="Dos_para">
                You may think looking yourself up is{"\n"}laughable, but it is
                not; it is crucial.{"\n"}You should know what information is in
                {"\n"}your public record.
              </p>
            </div>
            <div className="dosText_container">
              <p className="dosHeading">
                Who is that person you just matched{"\n"}with?
              </p>
              <p className="Dos_para">
                Dating now starts nearly 90% of the{"\n"}time online. Looking up
                that new{"\n"}match before meeting them in person{"\n"}would be
                best. Your safety should{"\n"}always be your first priority;
                it's even{"\n"}more important than Taco Tuesday.
              </p>
            </div>

            <div className="dosText_container">
              <p className="dosHeading">Find old friends and family</p>
              <p className="Dos_para">
                Life takes us all in different directions;{"\n"}
                searching through our billions of{"\n"}public records is a great
                way to{"\n"}reconnect with people, whether it's{"\n"}friends
                from ages ago, distant{"\n"}relatives, or a long-lost
                significant{"\n"}
                other!
              </p>
            </div>

            <div className="dosText_container">
              <p className="dosHeading">Find out who's calling you</p>
              <p className="Dos_para">
                Not sure who is behind that phone{"\n"}number that keeps calling
                you? Run a{"\n"}
                reverse phone search and find out.{"\n"}
                Find out who's emailing you
              </p>
            </div>
          </div>
          <div>
            <p className="dont_title">Don'ts</p>
            <u className="theFair_text">
              The Fair Credit Reporting Act (FCRA) regulates how public
              databases are used. Friend Verifier, the public databases that
              power our platform, can not be used in business, and the following
              use cases.
            </u>
            <ul className="dont_ul">
              <li>
                <p>Employment screening</p>
              </li>
              <li>
                <p>Screening household workers</p>
              </li>
              <li>
                <p>Screening adoptive parent</p>
              </li>
              <li>
                <p>Screen potential pet-owners</p>
              </li>
              <li>
                <p>Screening educational qualifications</p>
              </li>
              <li>
                <p>Screening charities or non-profits</p>
              </li>
              <li>
                <p>Screening Tenants</p>
              </li>
              <li>
                <p>Screening Customers</p>
              </li>
              <li>
                <p>Or anything to do with Credit, Insurance, or Mortgages</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DosAndDont;
