import React, { useRef, useEffect } from "react";
import swal from "sweetalert";

export default function Paypal({ PayPalPaymentRequest, planId, isChecked }) {
  console.log("🚀 ~ file: PayPal.js:4 ~ Paypal ~ planId:", planId);
  const paypal = useRef();
  const paypalNull = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "",
                amount: {
                  currency_code: "USD",
                  value: planId?.discountedPrice
                    ? planId?.discountedPrice
                    : planId?.price,
                },
              },
            ],
          });
        },
        onInit: function (data, actions) {
          actions.disable();
          document
            .querySelector("#payMode")
            .addEventListener("change", function (event) {
              var checkbox = document.getElementById("payMode");
              if (checkbox?.checked) {
                actions.enable();
              } else {
                actions.disable();
              }
            });
        },
        onClick: function () {
          var checkbox = document.getElementById("payMode");
          if (!checkbox?.checked) {
            swal("Please accept acknowledgement");
          }
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          var obj = {
            planId: planId?._id,
            paymentMode: "online",
            paymentVia: "PayPal",
            purchaseToken: order?.id,
          };
          PayPalPaymentRequest(obj);
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
