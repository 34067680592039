/** @format */

import { LoggedFooter } from "../../../Components/Footer";
import LoggedHeader from "../../../Components/Header";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { axiosPost, axiosPut } from "../../../utils/axiosRequest";
import swal from "sweetalert";
import Loading from "../../../Components/Loading";
import { useEffect } from "react";
import EmailAccount from "../../../MobilePages/menuPages/account/EmailAccount";
import { useDispatch } from "react-redux";
import { logOut } from "../../../redux/action";

function UpdateEmail() {
  const userData = JSON.parse(localStorage.getItem("@loginDetails")).user;
  const [email, setEmail] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const Id = localStorage.getItem("@loginDetails");
  const dispatch = useDispatch();

  /* mobile screen */

  const [mobileUpdateEmail, setMobileUpdateEmail] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileUpdateEmail(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileUpdateEmail(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Id === null) {
      navigate("/login-signup");
    }
  }, []);

  function EmailChanger(e) {
    e.preventDefault();
    const oldemail = userData?.email?.toLowerCase();

    if (oldemail === oldEmail.toLowerCase()) {
      if (oldEmail.toLowerCase() === email.toLowerCase()) {
        swal("Enter a fresh Email to update");
      } else {
        setIsLoading(true);
        const url = `/user/change-identifier`;
        let obj = {
          mode: "email",
          email: email.toLowerCase(),
        };
        axiosPost(url, obj, "", (callBack) => {
          setIsLoading(false);
          if (callBack?.status === 200) {
            swal(callBack.message);
            // navigate("/dashboard");
            navigate("/phoneVerificationOtp", {
              state: {
                data: callBack,
                obj: obj,
                email: email.toLowerCase(),
                screen: "updateEmail",
              },
            });
          } else if (callBack?.status === 401) {
            swal({
              title: "Unauthorized Access Detected",
              text: callBack.message,
              showCancelButton: true,
              closeOnConfirm: false,
              animation: "slide-from-top",
              buttons: {
                confirm: {
                  text: "Log out",
                  className: "red-button",
                },
              },
              dangerMode: true,
            }).then(() => {
              dispatch(logOut());
              navigate("/login-signup");
            });
          } else {
            swal(callBack.message);
          }
        });
      }
    } else {
      setIsLoading(false);
      swal("Invalid Old Email !!");
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : false}
      {mobileUpdateEmail ? (
        <EmailAccount
          EmailChanger={EmailChanger}
          setEmail={setEmail}
          setOldEmail={setOldEmail}
        />
      ) : (
        <div>
          <LoggedHeader />

          <main className="login-wrapper">
            <div className="container">
              <div className="login-left moible-wrap">
                <h2>Update Email Address</h2>
                <form onSubmit={EmailChanger}>
                  <div className="login-content mobile-cntnt">
                    <div className="login-item">
                      <label htmlFor="oldEmail">Old Email</label>
                      <input
                        className="email"
                        type="email"
                        pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                        id="oldEmail"
                        placeholder="Current Email"
                        onChange={(e) => setOldEmail(e.target.value)}
                        required
                      />
                    </div>

                    <div className="login-item">
                      <label htmlFor="newEmail">New Email</label>
                      <input
                        className="email"
                        pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                        type="email"
                        id="newEmail"
                        placeholder="New Email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>

                    <div className="update-btns">
                      <button type="submit"> Update New Email </button>
                      {/* <button
                    className="back"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    {" "}
                    BACK{" "}
                  </button> */}
                      <br />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>

          <LoggedFooter />
        </div>
      )}
    </>
  );
}

export default UpdateEmail;
