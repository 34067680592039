/** @format */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userImg from "../images/user.png";
import phoneImg from "../images/phone-2.png";
import emailImg from "../images/email-2.png";
import homeImg from "../images/home-2.png";
import swal from "sweetalert";
import { useState } from "react";
import { axiosPost } from "../utils/axiosRequest";
import { useDispatch } from "react-redux";
import { searchDetails, metaBody } from "../redux/action";
import Searching from "./Searching";
import optionImg from "../images/options.png";
// import { useSelector } from "react-redux";

function SearchArea() {
  // const data = (useSelector((state) => state.loginDetails));
  const data = localStorage["@loginDetails"];
  // console.log(data);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const object = JSON.parse(localStorage.obj)
  // console.log(object);

  // const fname = object.fields[firstName]
  // console.log(fname);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [age, setAge] = useState("");
  const [middleName, setMiddleName] = useState("");

  useEffect(() => {
    setCity("");
    setPhone("");
    setState("");
    setEmail("");
    setStreet("");
    setLastName("");
    setFirstName("");
  }, []);

  function toggleDisplay(hideClass, showClass, showColor, hideColor) {
    setCity("");
    setPhone("");
    setState("");
    setEmail("");
    setStreet("");
    setLastName("");
    setFirstName("");

    const hideElements = document.getElementsByClassName(hideClass);
    const showElements = document.getElementsByClassName(showClass);
    const hideColors = document.getElementsByClassName(hideColor);
    const showColors = document.getElementsByClassName(showColor);

    for (let i = 0; i < hideColors.length; i++) {
      hideColors[i].style.color = "gray";
      hideColors[i].style.filter = "grayscale(100%)";
      hideColors[i].style.opacity = ".7";
    }
    for (let i = 0; i < showColors.length; i++) {
      showColors[i].style.color = "#3b5997";
      showColors[i].style.filter = "grayscale(0%)";
      showColors[i].style.opacity = "2";
    }

    for (let i = 0; i < hideElements.length; i++) {
      hideElements[i].style.display = "none";
    }

    for (let i = 0; i < showElements.length; i++) {
      showElements[i].style.display = "block";
    }
  }

  function check(event, obj) {
    event.preventDefault();
    if (
      (firstName.toLowerCase().trim() == "joe" &&
        lastName.toLowerCase().trim() == "penora") ||
      (firstName.toLowerCase().trim() == "joseph" &&
        lastName.toLowerCase().trim() == "penora")
    ) {
      alert(
        "🐝 Alert 🐝 \n Puffy is good, but Wu-Tang is the best. Wu-Tang is for the children!",
        [{ text: "OK", onPress: () => {} }]
      );
      return;
    }

    if (!data) {
      swal({
        title: "Friend Verifier",
        text: "In order to perform a search you must be logged in. Please login or create an account!",
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
      }).then(() => {
        navigate("/login-signup");
      });
      return;
    }
    if (
      (obj === "fname" && lastName === "") ||
      (obj === "fname" && lastName === null)
    ) {
      swal("First and last name fields are required.");
      setIsLoading(false);
    } else if (
      (obj === "fname" && firstName === "") ||
      (obj === "fname" && firstName === null)
    ) {
      swal("First and last name fields are required.");
      setIsLoading(false);
    } else if (obj === "phone" && phone === "") {
      swal("Please enter a phone number.");
      setIsLoading(false);
    } else if (obj === "email" && email === "") {
      swal(
        "Please enter a correctly formatted email address (name@domain.com)."
      );
      setIsLoading(false);
    } else if (obj === "add" && state === "") {
      swal(
        "The street name, city and state are required fields. Please try again"
      );
      setIsLoading(false);
    } else if (obj === "add" && street === "") {
      swal(
        "The street name, city and state are required fields. Please try again"
      );
      setIsLoading(false);
    } else if (obj === "add" && city === "") {
      swal(
        "The street name, city and state are required fields. Please try again"
      );
      setIsLoading(false);
    } else {
      // setIsLoading(true);
      var fields = [];
      if (firstName) {
        fields.push({ firstName: firstName });
        // localStorage.setItem('firstName', firstName)
      }
      if (lastName) {
        fields.push({ lastName: lastName });
        // localStorage.setItem('lastName', lastName)
      }
      if (city) {
        fields.push({ city: city });
        // localStorage.setItem('city', city)
      }
      if (state) {
        fields.push({ state: state });
        // localStorage.setItem('state', state)
      }
      if (phone) {
        fields.push({ phone: phone });
        // localStorage.setItem('phone', phone)
      }
      if (email) {
        fields.push({ email: email });
        // localStorage.setItem('email', email)
      }
      if (middleName) {
        fields.push({ midName: middleName });
        // localStorage.setItem('email', email)
      }
      if (age) {
        fields.push({ age: age });
      }
      if (street) {
        fields.push({ street: street });
        // localStorage.setItem('street', street)
      }

      var obj = {
        fields: fields,
      };

      localStorage.setItem("metaBody", JSON.stringify(obj));
      setTimeout(() => {
        navigate("/result-page", {
          state: {
            //   data: callBack.data,
            //   inputState: state,
            //   inputName: name,
            metaBody: obj,
            //   research: true,
            //   firstName,
            //   lastName,
            //   state,
            //   email,
            //   phone,
            //   street,
            //   city,
          },
        });
      }, 100);

      return;

      localStorage.setItem("obj", JSON.stringify(obj));
      const url = `/search/openSearch?pageNo=1&pageSize=10`;

      axiosPost(url, obj, (callBack) => {
        console.log("Searching");
        const name = firstName + " " + lastName;
        console.log(name);

        if (callBack?.status === 200) {
          dispatch(searchDetails({ payload: callBack.data.response }));
          dispatch(metaBody({ payload: obj }));
          setIsLoading(false);

          localStorage.setItem("metaBody", JSON.stringify(obj));
          //   return;

          // navigate("/result-page");
          navigate("/result-page", {
            state: {
              //   data: callBack.data,
              //   inputState: state,
              //   inputName: name,
              metaBody: obj,
              //   research: true,
              //   firstName,
              //   lastName,
              //   state,
              //   email,
              //   phone,
              //   street,
              //   city,
            },
          });
        } else {
          setIsLoading(false);
          alert(callBack.message);
        }
      });
    }
  }

  function refine() {
    document.getElementById("focus").focus();
    const newComp = document.getElementById("newComp");
    const age = document.getElementById("age");
    const middle = document.getElementById("middle");
    if (newComp.style.display === "flex") {
      newComp.style.display = "none";
      // newComp.style.display = "none";
    } else {
      newComp.style.display = "flex";
      // newComp.style.display = "flex";
    }
    // if (age.style.display === "block") {
    //   age.style.display = "none";
    //   middle.style.display = "none";
    // }
    // else {
    //   age.style.display = "block";
    //   middle.style.display = "block";
    // }
  }

  return (
    <div style={{ marginBottom: 80 }}>
      {isLoading ? <Searching /> : false}

      <div className="friend-searchbar">
        <ul>
          <li className="li a">
            <a
              className="ns eas aas pas"
              onClick={() => toggleDisplay("sbn", "sn", "ns", "nas")}
              style={{ color: "#3b5997" }}
            >
              <img className="ns eas aas pas img" src={userImg} alt="" />
              Search by Name
            </a>
          </li>

          <li className="d-none d-lg-block"> | </li>

          <li className="li a">
            <a
              className="ps eas aas nas"
              onClick={() => toggleDisplay("sbp", "sp", "ps", "pas")}
              style={{ filter: "grayscale(100%)", opacity: "0.7" }}
            >
              <img className="ps eas aas nas img" src={phoneImg} alt="" />
              Search by Phone
            </a>
          </li>

          <li className="d-none d-lg-block"> | </li>

          <li className="li a">
            <a
              className="es pas aas nas"
              onClick={() => toggleDisplay("sbe", "se", "es", "eas")}
              style={{ filter: "grayscale(100%)", opacity: "0.7" }}
            >
              <img className="es pas aas nas img" src={emailImg} alt="" />
              Search by Email
            </a>
          </li>

          {/* <li className="d-none d-lg-block"> | </li> */}

          {/* <li className="li a">
            <a
              className="as pas eas nas"
              onClick={() => toggleDisplay("sba", "sa", "as", "aas")}
              style={{ filter: "grayscale(100%)", opacity: "0.7" }}
            >
              <img className="as pas aas nas img" src={homeImg} alt="" />
              Search by Address
            </a>
          </li> */}
        </ul>
      </div>

      <form
        className="sn sbp sbe sba"
        onSubmit={(event) => check(event, "fname")}
      >
        <div className="search-box" id="search-box">
          <div className="search-item">
            <input
              type="text"
              placeholder="First Name"
              name="fName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              id="focus"
            />
          </div>
          <div id="middle" style={{ display: "none" }} className="search-item">
            <input
              type="text"
              placeholder="Middle Name"
              name="lName"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </div>

          <div className="search-item">
            <input
              type="text"
              placeholder="Last Name"
              name="lName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              id="focus"
            />
          </div>

          <div id="age" style={{ display: "none" }} className="search-item">
            <input
              type="text"
              placeholder="Age"
              name="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              id="focus"
            />
          </div>

          <div className="search-item">
            <input
              type="text"
              placeholder="City"
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              id="focus"
            />
          </div>

          <div className="search-item">
            <select
              name="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="" defaultChecked>
                All States
              </option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>

          <div className="search-item search-btn">
            <box
              id="option"
              className="option"
              onClick={() => {
                document.getElementById("option").classList.toggle("option-b");
                refine();
              }}
            >
              <img src={optionImg} alt="option" width={"25px"} />
            </box>
            <div>
              <button type="submit">Search</button>
            </div>
          </div>
        </div>

        <div className="search-box2" id="newComp" style={{ display: "none" }}>
          <div id="middle" style={{ display: "flex" }} className="search-item2">
            <input
              type="text"
              placeholder="Middle Name"
              name="lName"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </div>

          <div id="age" style={{ display: "flex" }} className="search-item2">
            <input
              type="text"
              placeholder="Age"
              name="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              id="focus"
            />
          </div>
        </div>
      </form>

      <form
        className="sp sbn sba sbe"
        onSubmit={(event) => check(event, "phone")}
      >
        <div className="search-box" id="search-box">
          <div className="search-item ">
            <input
              type="number"
              placeholder="Phone Number"
              maxLength="10"
              name="phone"
              style={{ width: "100%", MozAppearance: "textfield" }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="search-item search-btn">
            <div>
              <button type="submit">Search</button>
            </div>
          </div>
        </div>
      </form>

      <form
        className="se sbp sbn sba"
        onSubmit={(event) => check(event, "email")}
      >
        <div className="search-box" id="search-box">
          <div className="search-item">
            <input
              type="email"
              placeholder="E-mail"
              pattern="[a-zA-Z0-9_\.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
              name="email"
              style={{ width: "100%" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="search-item search-btn">
            <div>
              <button type="submit">Search</button>
            </div>
          </div>
        </div>
      </form>

      <form
        className="sa sbp sbe sbn"
        onSubmit={(event) => check(event, "add")}
      >
        <div className="search-box" id="search-box">
          <div className="search-item">
            <input
              type="text"
              placeholder="Street Address"
              name="street"
              style={{ width: "100%" }}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </div>

          <div className="search-item">
            <input
              type="text"
              placeholder="City"
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className="search-item">
            <select
              name="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="" defaultChecked>
                All States
              </option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>

          <div className="search-item search-btn">
            <div>
              <button type="submit">Search</button>
            </div>
          </div>
        </div>
      </form>

      <div className="celebration">
        <div>
        🎉
        </div>
        <p>
          Install the Friend Verifier mobile application from either the <a href="https://apps.apple.com/app/friend-verifier-search/id1669432431" target="#">App Store</a> or <a href="https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US" target="#">Google Play</a>, open the app and enter <b>10Free</b> in the promo field on the menu screen to recieve 10 free credits.
        </p>
        <div>
        🎉
        </div>
      </div>
    </div>
  );
}

export default SearchArea;
