/** @format */

import React, { useState, useEffect } from "react";
import Footer from "../../../Components/Footer";
import { ContactHeader, SigninHeader } from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import TermsAndCondition from "../../../MobilePages/menuPages/TermsAndCondition";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { axiosPost } from "../../../utils/axiosRequest";
import { useDispatch } from "react-redux";
import { loginDetails } from "../../../redux/action";
import { SaveStorageItems } from "../../../utils/AsyncStorage";

export default function TermServiceNew() {
  const [isLoading, setIsLoading] = useState(false);

  /* mobile screen */

  const [mobileTerms, setMobileTerms] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileTerms(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
    if (!state?.hasOwnProperty("data")) {
      navigate("/login-signup");
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileTerms(window.innerWidth <= 575);
  };

  const logout = () => {
    // localStorage.removeItem("token");
    // history.replace("/login-signup");
    navigate("/login-signup", { replace: true });
  };

  const agreeFun = () => {
    const url = "/user/register-terms";
    // var Token = route?.params?.data?.authToken;
    var obj = {
      device: "web",
      deviceInfo: {},
      termsResponse: true,
    };
    var token = state?.data?.authToken;

    axiosPost(url, obj, token, (callBack) => {
      console.log(
        "🚀 ~ file: TermServiceNew.jsx:55 ~ axiosPost ~ callBack:",
        JSON.stringify(callBack)
      );
      if (callBack?.status == 200) {
        SaveStorageItems(
          "@loginDetails",
          JSON.stringify(callBack.data),
          0,
          () => {
            dispatch(loginDetails({ payload: callBack?.data }));
            navigate("/dashboard", { replace: true }); // Navigating to the next screen
          }
        );
        // navigation.replace("tabs");
        // SaveStorageItems(
        //   "@loginDetails",
        //   JSON.stringify(callBack.data),
        //   0,
        //   () => {
        //     dispatch(loginDetails({ payload: callBack?.data }));
        //     navigation.replace("tabs"); // Navigating to the next screen
        //   }
        // );
        // navigation.replace("SubscribeScreen", {
        //   welcome: 1,
        // });
      }
    });
  };

  return (
    <>
      {/* {isLoading ? <Loading /> : false} */}
      {/* {mobileTerms ? (
        <TermsAndCondition />
      ) : ( */}
      <body>
        {/* {window.location.pathname === "/signup/toc" ? (
            <SigninHeader />
          ) : (
            <ContactHeader />
          )} */}

        <br />
        <br />

        <section className="not-sell-hero terms-hero-bg">
          <div className="container">
            <div className="not-sell-title">
              <h2>Terms of Service</h2>
            </div>
          </div>
        </section>

        <div className=" privacy-page-areas">
          <div className="container">
            <br />
            <p>
              Welcome to FriendVerifier.com (aka "Friend Verifier").
              FriendVerifier.com is a website (“Site”) provided by Friend
              Verifier Inc. The following is the terms that all user of this
              site are subject to. These terms are what governs the users us of
              this site and can be updated without giving notice to you the
              user. By using or visiting the site you accept the terms. Please
              go over them. carefully.
              <br />
              <br />
              1. Registering with the Friend Verifier In order to take advantage
              of the features on this site, you will be required to register
              with Friend Verifier.com. For example, you may be required to
              register if you want to set up a Site account, where you can save
              your preferences, receive email alerts, discount offerings,
              utilize free and paid searches, or other information from the
              Site. You may also be required to register to receive email
              updates with updated information from Friend Verifier.com. The use
              of any personal information that you provide to Friend Verifier
              during any registration process is governed by our privacy policy.
              Users may receive email confirming their registration Friend
              Verifier.com, as well as promotional marketing of commercial
              products and services. By having an account with Friend Verifier,
              you agree to take full responsibility for maintaining the
              confidentiality of your account user name, password, and all
              related activity that occurs under your account user name.
              <br />
              <br />
              2. Your Registration Obligations In consideration of your use of
              the Site's services, you represent that you are of legal age to
              form a binding contract and are not a person barred from receiving
              our services under the laws of the United States or other
              applicable jurisdiction. You also agree to: (i) provide true,
              accurate, current and complete information about yourself as
              prompted by our Site, and (ii) maintain and promptly update your
              Site information to keep it true, accurate, current and complete.
              If you provide any information that is untrue, inaccurate, not
              current or incomplete, or we have reasonable grounds to suspect
              that such information is untrue, inaccurate, not current or
              incomplete, we have the right to suspend or terminate your account
              and refuse any and all current or future use of the Site.
              <br />
              <br />
              3. Site Results Most of the products on the Site come with a
              limited guarantee. We provide free searches which are clearly
              labeled as free, as well as paid searches. Certain searches are
              “no hit no fee searches” and those searches will be clearly
              labeled as well. Searches like background searches, criminal
              searches, liens and judgments and bankruptcies searches all have a
              fee regardless if there is a result or not. You acknowledge that
              the service is provided "as is." You are paying for us to conduct
              a search on your behalf, not to return any particular result.
              Information is often obtained by third parties and the accuracy of
              the information cannot be guaranteed. In the event of using this
              service for criminal or civil background checks, you should not
              assume that this data provides a complete or accurate history of
              any person's criminal or civil history. Our data is subject to the
              many thousands of sources that we compile every day. You should
              consult state and federal laws before using this information in
              making decisions on hiring or firing of employees. Friend Verifier
              cannot offer legal advice on how to use the information contained
              in criminal or civil background reports. Friend Verifier is not
              responsible for any action taken by the customer based on this
              information. Customers should use extreme caution when
              interpreting the results of a criminal or civil background search
              for any type of personal verification. Positive or false matches
              in criminal or civil searches may not provide confirmation of an
              individual's criminal or civil background. Proper use of these
              reports is the responsibility of you, the customer.
              <br />
              <br />
              4. Fees, Refunds & Fraud Fees for our services are listed on the
              site and by using this site you agree to pay those fees for said
              services. Unless we indicate otherwise, all fees and charges are
              final, with no refunds. There are no refunds for the purchase of
              credits nor are there refunds for the purchasing of services on
              this site. By purchasing our services, you agree to pay with a
              valid credit card or other means via PayPal and certify that you
              are an authorized user of the credit card/PayPal account. To
              protect our customers from credit card fraud, we may work with law
              enforcement agencies to address issues by sharing information such
              as credit card numbers, email addresses, IP addresses, etc. We
              reserve the right to suspend services for individuals suspected of
              fraudulent credit card activity. You are responsible, and must pay
              for, all purchases of the services made by individuals using your
              account. If you suspect that unauthorized use of your account is
              occurring, you must notify us immediately. You agree to pay all
              amounts due upon our demand. In the event we have to collect
              unpaid amounts you owe us, you will be liable for all collection
              costs, including attorneys' and collection agency fees.
              <br />
              <br />
              5. Unauthorized Use of the Site You agree that you are only
              authorized to visit, view, and retain a copy of pages of this Site
              for your own personal use; except with the Friend Verifier's
              written permission, you shall not duplicate, download, publish,
              modify, or otherwise distribute the material on this Site for any
              commercial use, or for any purpose other than as described in
              these Terms. You cannot automate, script, scrape, or otherwise
              take data from the Site in an automated fashion to re-use or
              display in any way. You acknowledge that we are not providing you
              with a consumer report, and you are certifying that you will not
              use information obtained from us for any purpose covered under the
              Fair Credit Reporting Act (15 U.S.C. §1681, et seq.). You
              acknowledge that the Friend Verifier owns and retains all
              proprietary materials contained on the Site, including trademarks,
              content, and other proprietary content. Illegal and/or
              unauthorized uses of the Site, including, but not limited to,
              unauthorized framing of or linking to the Site, unauthorized use
              of any robot, spider, or other automated device on the Site,
              automating, scripting, scraping or otherwise taking data from the
              Site in an automated fashion to re-use or display in any way using
              information obtained from the Site, including an email search
              results, to transmit any commercial, advertising or promotional
              materials, including without limitation, "spam", using information
              obtained from the Site to harass, offend, threaten, embarrass, or
              invade the privacy of any individual or entity, violating any
              applicable law, regulation or rule, providing false information on
              your registration form or impersonating another person at any
              point (i.e., unauthorized/fraudulent credit card information,
              false names, etc.), or using information obtained from the Site
              for any purpose covered under the Fair Credit Reporting Act (15
              U.S.C. §1681, et seq.), will be investigated and subject to
              appropriate action, including, without limitation, termination of
              your account and formal civil, criminal, and injunctive redress.
              <br />
              <br />
              6. Violation of the Terms You agree that monetary damages may not
              provide a sufficient remedy to the Friend Verifier for violations
              of these terms of use and you consent to injunctive or other
              equitable relief for such violations.
              <br />
              <br />
              7. Proprietary Rights You acknowledge and agree that the Friend
              Verifier owns all legal right, title, and interest in and to the
              Site, including any intellectual property rights which subsist in
              the Site (whether those rights happen to be registered or not, and
              wherever in the world those rights may exist). Unless you have
              agreed otherwise in writing with the Friend Verifier, nothing in
              the Terms gives you a right to use any of the Friend Verifier
              trade names, trademarks, service marks, logos, domain names, and
              other distinctive brand features.
              <br />
              <br />
              8. Disclaimers The information accessed on the Friend Verifier
              website are in-house databases which have been compiled from
              public records and other proprietary sources for the specific
              purposes of locating individuals, property and businesses, and/or
              providing general background information about individuals and
              businesses for verification purposes. Neither Friend Verifier nor
              any of our data suppliers represents or warrants that the
              Information is current, complete or accurate. Verius LLC on the
              behalf of Friend Verifier HEREBY DISCLAIMS ALL REPRESENTATIONS AND
              WARRANTIES REGARDING THE PERFORMANCE OF THE SERVICE AND THE
              ACCURACY, CURRENCY, OR COMPLETENESS OF THE INFORMATION, INCLUDING
              (WITHOUT LIMITATION) ALL WARRANTIES OF MERCHANTABILITY OR FITNESS
              FOR A PARTICULAR PURPOSE. ADDITIONALLY, UNDER NO CIRCUMSTANCES
              SHALL WE BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING
              (WITHOUT LIMITATION) ANY DIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES, LOST PROFITS, OR ANY OTHER CLAIMS OF YOURS
              OR THIRD PARTIES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGES. You assume all risks associated with the use of
              our databases and information.
              <br />
              <br />
              9. Communications When you visit the Site or send an emails to us,
              you are communicating with us electronically. You consent to
              receive communications from us electronically. We will communicate
              with you by an email or by posting notices on the Site. You agree
              that all agreements, notices, disclosures and other communications
              that we provide to you electronically satisfy any legal
              requirement that such communications be in writing.
              <br />
              <br />
              10. LIMITATION ON LIABILITY EXCEPT IN JURISDICTIONS WHERE SUCH
              PROVISIONS ARE RESTRICTED, IN NO EVENT WILL WE BE LIABLE TO YOU
              FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
              OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, EVEN IF WE HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              <br />
              <br />
              11. Disputes If there is any dispute about or involving the Site,
              you, by using the Site, agree that the dispute will be governed by
              the laws of the State of California without regard to its
              conflict-of-law provisions. You agree to personal jurisdiction by,
              and venue in the state and federal courts of the State of Florida,
              City of Fort Lauderdale.
              <br />
              <br />
              12. Indemnity You agree to indemnify and hold the Friend Verifier,
              its subsidiaries, affiliates, officers, agents, and other partners
              and employees harmless from any loss, liability, claim, or demand,
              including reasonable attorneys' fees, arising out of the use of
              the Site by user or user's account.
            </p>
          </div>
          <div className="fixedFooter">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                // backgroundColor: "red",
                alignItems: "center",
                paddingLeft: 40,
                paddingRight: 40,
                justifyContent: "space-between",
              }}
            >
              <p onClick={logout} className="pointer" style={{ marginTop: 10 }}>
                Disagree
              </p>
              <p
                className="pointer"
                onClick={agreeFun}
                style={{ marginTop: 10 }}
              >
                Agree
              </p>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
