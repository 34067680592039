/** @format */

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import GetStartedIcon from "../assets/icons/GetStarted.svg";
import appStore from "../images/app-store.png";
import googlePlay from "../images/google-play.png";
function GetStarted() {
  const navigate = useNavigate();

  const mobileLoginFunc = () => {
    navigate("/login-signup");
  };

  return (
    <div className="login_container">
      <div className="getStartedContainer">
        <div className="getScreenTop">
          <img src={GetStartedIcon} alt=" Icon" className="getstarted_icon" />
        </div>
        <div className="getScreenBottom">
          <div>
            <p className="getScreenTitle">Friend or Foe</p>
            <p className="getScreenTitle">You Need to Know</p>
          </div>
          <p className="getstarted_para">
            Friend Verifier empowers you to recognize potential individuals who
            pose a risk to you and those you love.
          </p>
          <div>
            {/* <div
            className="createAccountContainer"
          >
            <p style={{  color: "white" }}>
              Get Started!
            </p>
          </div> */}
            <div className="getStarted_btn" onClick={mobileLoginFunc}>
              <button type="submit">Get Started</button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              OR
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {/* <ul>
                <li> */}
              <a
                target="blank"
                href="https://apps.apple.com/app/friend-verifier-search/id1669432431"
              >
                <img src={appStore} alt="" width={160} height={50} />
              </a>
              {/* </li> */}
              {/* <li> */}
              <a
                target="blank"
                href="https://play.google.com/store/apps/details?id=com.devusama.FriendVerifier&hl=en_US&gl=US"
              >
                <img src={googlePlay} alt="" width={160} height={50} />
              </a>
              {/* </li>
              </ul> */}
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
